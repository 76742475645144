import api from "./api";
import { getArgs } from "../utils";

const route = "/notes";

export default {
  getNotes: (filters) => api.get(`${route}?${getArgs(filters)}`),
  postNote: (note) => api.post(route, { ...note }),
  putNote: (note) => api.put(route, { ...note }),
  deleteNote: (note_id) => api.delete(`${route}/${note_id}`),
};
