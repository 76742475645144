import React from "react";
import { ReactComponent as PurpleCheck } from "../../assets/icons/check-purple.svg";

const SingleCourseFeature = ({ feature }) => {
  return (
    <div className="col-12 col-md-6 col-xl-3 my-3" style={{ minHeight: "100px" }}>
      <div className="card shadow">
        <div className="row align-items-center">
          <div className="col-3 pe-0">
            <PurpleCheck className="w-100 m-auto" />
          </div>
          <div className="col-9 ps-0">
            <div className="ps-4 text-start">{feature}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CoursesFeatures = ({ features }) => {
  const renderFeatures = () => {
    if (Array.isArray(features)) {
      return features.map((feature, index) => (
        <SingleCourseFeature key={index} feature={feature} />
      ));
    }
  };
  return (
    <div className="hide-mobile">
      <div className="row ps-lg-2 px-xl-5">{renderFeatures()}</div>
    </div>
  );
};

export default CoursesFeatures;
