export const shortFAQ = [
  {
    title: "¿Cuáles son los requisitos?",
    htmlContent:
      "La certificación es multi nivel por lo que no necesitas tener conocimientos específicos previos, lo que sí les recomendamos para que puedan aprovecharla más es que realicen actividad física regularmente y estén familiarizadas con el ejercicio.",
  },
  {
    title:
      "¿Cual es la diferencia entre las certificaciones ON DEMAND y las certificaciones EN VIVO?",
    htmlContent: `
                  <div>
                    <b>ON DEMAND</b>
                    <p>Nuestras certificaciones pre grabadas, todo lo que tienes que saber sobre nuestras disciplinas, a tu propio ritmo y en los horarios que mejor se te acomoden. </p>
                    <p>Puedes elegir únicamente la certificación de Sculpt and Burn o el paquete de Sculpt and Burn + Barre.</p>
                    </p>
                    <b>EN VIVO</b>
                    <p>Un fin de semana intensivo (sábado y domingo) de nuestras 3 disciplinas pilares del método Sculpt and Burn, Barre y Jump en las fechas publicadas. Se puede tomar en modalidad presencial (directamente en nuestro estudio en Mty) o en modalidad online (vía zoom)  Además incluyen 30 días de acceso a la plataforma de certificaciones On demand.</p>
                  </div>`,
  },
  {
    title: "¿Qué equipo necesito para tomar la certificación?",
    htmlContent: `
                  <div>
                  <b>On Demand (Barre y Sculpt and Burn) necesitas: </b>
                  <ul>
                    <li>Un par de mancuernas ligeras (3 lbs) </li>
                    <li>Un par de mancuernas pesadas (8lbs) </li>
                    <li>Una mini ball de pilates sin peso (de 25cm de diametro aproximadamente) </li>
                    <li>Una banda de resistencia circular de tela </li>
                    <li>Tapete</li>
                  </ul>
                  <b>En Vivo vía ZOOM (Barre, Sculpt and Burn & Jump) necesitas: </b>
                  <ul>
                    <li>Un par de mancuernas ligeras (3 lbs) </li>
                    <li>Un par de mancuernas pesadas (8lbs) </li>
                    <li>Una mini ball de pilates sin peso (de 25cm de diametro aproximadamente) </li>
                    <li>Una banda de resistencia circular de tela </li>
                    <li>Tapete</li>
                    <li>Trampolín</li>
                  </ul>
                  <b>En Vivo Presencial Mty, Mx (Barre, Sculpt and Burn & Jump) necesitas: </b>
                  <ul>
                    <li>Una banda de resistencia circular de tela </li>
                    <li>Tapete</li>
                  </ul>
                  </div>
                `,
  },
  {
    title: "¿En donde puedo tomar las certificaciones On Demand?",
    htmlContent:
      "Desde cualquier dispositivo electrónico que cuente con acceso a WIFI como: Android, iphone, laptop, ipad y smart TV.",
  },
];
export const extendedFAQ = [
  {
    title: "¿Cuáles son los requisitos?",
    htmlContent:
      "La certificación es multi nivel por lo que no necesitas tener conocimientos específicos previos, lo que sí les recomendamos para que puedan aprovecharla más es que realicen actividad física regularmente y estén familiarizadas con el ejercicio.",
  },
  {
    title:
      "¿Cual es la diferencia entre las certificaciones ON DEMAND y las certificaciones EN VIVO?",
    htmlContent: `
                  <div>
                    <b>ON DEMAND</b>
                    <p>Nuestras certificaciones pre grabadas, todo lo que tienes que saber sobre nuestras disciplinas, a tu propio ritmo y en los horarios que mejor se te acomoden. </p>
                    <p>Puedes elegir únicamente la certificación de Sculpt and Burn o el paquete de Sculpt and Burn + Barre.</p>
                    </p>
                    <b>EN VIVO</b>
                    <p>Un fin de semana intensivo (sábado y domingo) de nuestras 3 disciplinas pilares del método Sculpt and Burn, Barre y Jump en las fechas publicadas. Se puede tomar en modalidad presencial (directamente en nuestro estudio en Mty) o en modalidad online (vía zoom)  Además incluyen 30 días de acceso a la plataforma de certificaciones On demand.</p>
                  </div>`,
  },
  {
    title: "¿Para quién es esta certificación?",
    htmlContent: `
        <div>
            <li>Alumn@s que quieran mejorar su técnica </li>
            <li>Personas que quieran convertirse en coaches y generar ingresos haciendo lo que aman</li>
            <li>Coaches que busquen ampliar sus conocimientos </li>
            <li>Emprendedor@s que busquen abrir su propio negocio</li>
            <li>Fisioterapeutas </li>
            <li>Psicolog@s</li>
            <li>Nutriolog@s </li>
            <li>Personas que busquen cambiar hábitos y mejorar su relación con el ejercicio</li>
        </div>
    `,
  },
  {
    title: "¿Como y cuando recibo acceso a la certificación?",
    htmlContent: `
        <div>
        <b>Certificaciones ON DEMAND</b>
        <p>En el momento de realizar tu inscripción/pago en automático vas a tener acceso a la certificación. Para una certificación individual tienes acceso durante un total de 30 días. 
y para un paquete de certificación que incluya 2 o más disciplinas tienes un acceso de 60 días.</p>
        <b>Certificaciones EN VIVO</b>
        <p>Después de realizar tu inscripción/pago prepárate para un fin de semana intensivo en las fechas establecidas. Posterior a la certificación en vivo, activaremos tu acceso a la plataforma de certificaciones on demand durante 30 días. </p>
      `,
  },
  {
    title: "¿Qué equipo necesito para tomar la certificación?",
    htmlContent: `
                  <div>
                  <b>On Demand (Barre y Sculpt and Burn) necesitas: </b>
                  <ul>
                    <li>Un par de mancuernas ligeras (3 lbs) </li>
                    <li>Un par de mancuernas pesadas (8lbs) </li>
                    <li>Una mini ball de pilates sin peso (de 25cm de diametro aproximadamente) </li>
                    <li>Una banda de resistencia circular de tela </li>
                    <li>Tapete</li>
                  </ul>
                  <b>En Vivo vía ZOOM (Barre, Sculpt and Burn & Jump) necesitas: </b>
                  <ul>
                    <li>Un par de mancuernas ligeras (3 lbs) </li>
                    <li>Un par de mancuernas pesadas (8lbs) </li>
                    <li>Una mini ball de pilates sin peso (de 25cm de diametro aproximadamente) </li>
                    <li>Una banda de resistencia circular de tela </li>
                    <li>Tapete</li>
                    <li>Trampolín</li>
                  </ul>
                  <b>En Vivo Presencial Mty, Mx (Barre, Sculpt and Burn & Jump) necesitas: </b>
                  <ul>
                    <li>Una banda de resistencia circular de tela </li>
                    <li>Tapete</li>
                  </ul>
                  </div>
                `,
  },
  {
    title: "¿En donde puedo tomar las certificaciones On Demand?",
    htmlContent:
      "Desde cualquier dispositivo electrónico que cuente con acceso a WIFI como: Android, iphone, laptop, ipad y smart TV.",
  },
  {
    title: "¿Cómo son las evaluaciones?",
    htmlContent:
      "En ambas modalidades On demand y Online aplican las mismas evaluaciones. Teórica, que consiste en presentar un quiz acerca del contenido de cada módulo directamente en nuestra página web y práctica en la cual tienen que realizar y grabar una rutina de la disciplina que les sea asignada tomando en cuenta los factores que se les indique y subirla a la plataforma de certificaciones.",
  },
  {
    title: "¿El tomar la certificación me garantiza certificarme?",
    htmlContent:
      "No, para aprobar la certificación es necesario concluir con éxito todos los quizzes teóricos de cada módulo y además entregar el examen final (evaluación práctica) el cual será revisado por tu master coach, en el cual recibirás una retroalimentación y la calificación debe de ser 7 o mayor.",
  },
  {
    title: "¿Qué pasa si repruebo el examen final?",
    htmlContent:
      "Si tu calificación no es aprobatoria en el primer intento, ¡no te preocupes! Tienes una segunda oportunidad, en cuanto se te entregue la retroalimentación por parte de tu master coach, activaremos nuevamente 30 días extras para que puedas estudiar y repasar y nuevamente entregues tu examen final. En caso de que no apruebes el segundo intento, te enviaremos un reconocimiento por haber participado en TBM Certifications.",
  },
  {
    title: "Si no apruebo, ¿recibo un reembolso?",
    htmlContent:
      "Tenemos una política de NO reembolsos. El costo de la certificación incluye el material de apoyo, acceso a la plataforma y asesoría de nuestras master coaches.",
  },
  {
    title:
      "¿Qué pasa si me inscribo a una certificación   En Vivo y no puedo tomarla en esas fechas?",
    htmlContent:
      "Tendrás un pase de una oportunidad únicamente para mover la fecha de certificación. Es necesario que nos contactes vía whatsapp para realizar este cambio. ",
  },
  {
    title: "¿Qué pasa si se expira mi acceso a las certificaciones ON DEMAND y aun no he terminado los módulos o cargado mis exámenes finales?",
    htmlContent: "Una vez pasada la fecha limite de acceso a tu certificación podrás aplicar una única vez a adquirir una extensión de plazo por 3 semanas con valor de $500 pesos mexicanos. Este plazo solo puede ser extendido una sola vez."
  },
  {
    title:
      "Al aprobar el examen teórico y práctico ¿Cómo recibo mi certificado?",
    htmlContent:
      "Enviaremos tu certificado digital directamente a tu e-mail y de manera física a tu domicilio, junto con la blusa oficial de TBM CERTIFIED de manera gratuita (siempre y cuando habites dentro de México). En caso de residir fuera del país, el envío del certificado y blusa tendrán un costo de envío, este es variable dependiendo del lugar donde radiques",
  },
  {
    title:
      "¿Puedo utilizar el nombre de TBM, The Body Method o TBM Certifications?",
    htmlContent:
      "El uso de la marca no está permitido.Puedes usar el nombre de la marca de manera informativa únicamente, indicando que has cursado y aprobado la certificación de TBM.",
  },
];
