import React from "react";

const LineContact = () => {
  return (
    <div id="course-help" className="d-flex align-items-center w-100">
      <div className="container-fluid">
        <div className="row bg-blue align-items-center p-5 mb-5 text-white">
          <div className="col-12 col-lg-8">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <h4 className="italic h5 text-lg-start text-center">
                  ¿Necesitas ponerte en contacto con nosotros?
                </h4>
                <p className="mb-1 text-lg-start text-center medium">
                  ¡Estamos aquí para escucharte y responder todas tus preguntas!
                </p>
                <p className="text-lg-start text-center medium">
                  Tu master Coach te responderá en menos de 36 horas
                </p>
              </div>
              <div className="col-12 col-lg-4 text-center">
                <a
                  href="mailto:thebodymethodmty@gmail.com"
                  target="_blank"
                  className="btn btn-dark"
                >
                  CONTÁCTANOS
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-2 py-3 py-xl-5">
            <div className="position-relative h-100 w-100">
              <img
                src="https://tbmcerts.s3.us-west-1.amazonaws.com/line-contact.png"
                className="line-contact-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineContact;
