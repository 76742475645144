import React, { useContext, useEffect } from "react";
import "../../css/SingleSection.css";
import Chapter from "../section/Chapter";
import TestSection from "../section/TestSection";
import MediaSection from "../section/MediaSection";
import SectionHeader from "../section/SectionHeader";
import { LessonsContext } from "../../context/LessonsContext";
import { SectionsContext } from "../../context/SectionsContext";

const SingleSection = ({ course_id, section_id }) => {
  const { test, section, setSectionTest, getSingleSection } =
    useContext(SectionsContext);
  const { lesson, clearLeccion } = useContext(LessonsContext);

  useEffect(() => {
    getSingleSection(section_id);
  }, [section_id]);

  useEffect(() => {
    if (lesson !== null) {
      setSectionTest(null);
    }
  }, [lesson]);

  useEffect(() => {
    if (test !== null) {
      clearLeccion();
    }
  }, [test]);

  return (
    <div className="pt-0">
      <SectionHeader course_id={course_id} />
      <div className="row">
        {section !== null && <Chapter section_id={section_id} />}
        {lesson !== null && <MediaSection />}
        {test !== null && lesson === null && <TestSection />}
      </div>
    </div>
  );
};

export default SingleSection;
