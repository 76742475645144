import React, { useContext, useEffect } from "react";
import ChapterCard from "./ChapterCard";
import { LessonsContext } from "../../context/LessonsContext";
import { SectionsContext } from "../../context/SectionsContext";
import TestCard from "./TestCard";
import { AttempsContext } from "../../context/AttemptsContext";

const Chapters = ({ section_id }) => {
  const { attempts } = useContext(AttempsContext);
  const { test, section, setSectionTest } = useContext(SectionsContext);
  const { lesson, lessons, selectLesson, getSectionLessons } =
    useContext(LessonsContext);

  useEffect(() => {
    getSectionLessons(section_id);
  }, [section_id]);

  useEffect(() => {
    handleNextLesson();
  }, [lessons]);

  const handleSectionTests = () => {
    if (section.tests.length > 0) {
      //find next unattempted test or open attempt
      let nextTest = section.tests.find((test) => {
        const { attempts } = test;
        if (attempts.length === 0) return test;
        let nextAttempt = attempts.find(
          (attempt) => attempt.finishedAt === null
        );
        if (!nextAttempt && attempts.length > 0) {
          nextAttempt = attempts[attempts.length - 1];
        }
        if (nextAttempt) return test;
      });
      if (nextTest) {
        setSectionTest(nextTest);
      }
    }
  };

  const handleNextLesson = () => {
    if (Array.isArray(lessons)) {
      let nextIncompleteLesson = lessons.find(
        (lesson) => lesson.completed.length === 0
      );
      if (nextIncompleteLesson) {
        selectLesson(nextIncompleteLesson);
      } else {
        handleSectionTests();
      }
    }
  };

  const renderTests = () => {
    if (section && section !== null) {
      const { tests } = section;
      if (Array.isArray(tests) && Array.isArray(lessons)) {
        return tests.map((current) => {
          let disabled = lessons[lessons.length - 1].completed.length === 0;
          return (
            <TestCard
              key={current.lesson_id}
              attempts={attempts}
              disabled={disabled}
              selected={test}
              test={current}
            />
          );
        });
      }
    }
  };

  const renderLessons = () => {
    if (Array.isArray(lessons)) {
      return lessons.map((current, index) => {
        let disabled = false;
        if (index > 0) {
          disabled = lessons[index - 1].completed.length === 0;
        }
        return (
          <ChapterCard
            key={current.lesson_id}
            disabled={disabled}
            selected={lesson}
            lesson={current}
          />
        );
      });
    }
  };

  return (
    <div className="col-md-4 col-12">
      {renderLessons()}
      {renderTests()}
    </div>
  );
};

export default Chapters;
