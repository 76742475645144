const paypal_dev_key =
  "AX-1lDAl3DdCeZ1wa_PTH8jBry2OmlUs6lrbSp2T42FRjvuLRPRAahCZHz1_V665R-U5WINWXVVdALxd";

const paypal_live_key =
  "AU94SbddKFHy_FYksRQCQ2XZGHLOW3DVCcKrew5-egkqAWmJKfEpJSycBH5Ntydtxxj40PHJHjV2pQF-";

const paypal_client_id =
  process.env.NODE_ENV === "development" ? paypal_live_key : paypal_live_key;

const PAYPAL_URL = `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&vault=true&intent=subscription`;

export const importPayPalCheckout = () => {
  const script = document.createElement("script");
  script.src = "https://www.paypalobjects.com/api/checkout.js";
  script.id = "paypal-checkout";
  document.body.appendChild(script);
  return script;
};

export const importPayPalSubscriptions = () => {
  const script = document.createElement("script");
  script.src = PAYPAL_URL;
  script.id = "paypal-subscription";
  document.body.appendChild(script);
  return script;
};

export const clearPayPalElement = (elementId) => {
  const paypalButton = document.getElementById(elementId);
  if (paypalButton.innerHTML !== "") {
    paypalButton.innerHTML = "";
  }
};
export const removePayPalElement = (elementId) => {
  const paypalCheckout = document.getElementById(elementId);
  if (paypalCheckout !== null) {
    paypalCheckout.remove();
  }
};
