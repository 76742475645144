import api from "./api";

const route = "/sections";

export default {
  getSection: (section_id) => api.get(`${route}/${section_id}`),
  getSectionsCourse: (course_id) => api.get(`${route}/course/${course_id}`),
  postSection: (modulo) => api.post(route, { ...modulo }),
  putSection: (modulo) => api.put(route, { ...modulo }),
  updateOrdenSections: (modulos) => api.put(`${route}/orden`, { modulos }),
  deleteSection: (section_id) => api.delete(`${route}/${section_id}`),
};
