import React, { useContext } from "react";
import ConfirmDiploma from "./ConfirmDiploma";
import { ModalContext } from "../../context/ModalContext";
import { AttempsContext } from "../../context/AttemptsContext";
import { navigate } from "@reach/router";

const FinalExamFailed = ({ attempt }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { saveAttempt } = useContext(AttempsContext);

  const handleDiploma = () => {
    saveAttempt({ ...attempt, requested_diploma: true });
    clearModal();
  };

  const handleAttempt = () => {
    saveAttempt(
      { ...attempt, requested_diploma: false },
      "Te deseamos la mejor suerte ¡Tú puedes!"
    );
    navigate(`/mytbm`);
    clearModal();
  };

  const confirmAttempt = () => {
    modalComponent(
      "Reintentar Certificación",
      <div>
        <p>
          Esto reactivará el acceso a todos los módulos de esta certificación y
          sus prerequisitos (si es el caso).
        </p>
        <p>
          En caso de que no pases el examen de nuevo, recibirás automáticamente
          un diploma de participación.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button onClick={handleAttempt} className="btn w-100 btn-primary">
              Reintentar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const confirmDiploma = () => {
    modalComponent(
      "¿Solicitar Diploma?",
      <ConfirmDiploma handleCancel={clearModal} handleDiploma={handleDiploma} />
    );
  };

  return (
    <div className="container text-center">
      <p>
        Aún puedes volver a intentar tu examen final. Recibirás 40 días más de
        acceso a los videos para volver a prepararte.
      </p>
      <p>
        También, puedes solicitar un diploma de participación en caso de que no
        quieras volver a intentar tu examen final.
      </p>
      <div className="row">
        <div className="col-12 col-md-6 text-end">
          <button className="btn btn-primary" onClick={confirmAttempt}>
            Volver a Intentar mi Examen
          </button>
        </div>
        <div className="col-12 col-md-6 text-left">
          <button className="btn btn-accent" onClick={confirmDiploma}>
            Solicitar Diploma de Participación
          </button>
        </div>
      </div>
    </div>
  );
};
export default FinalExamFailed;
