import React, { useContext, useEffect } from "react";
import { LessonsContext } from "../../context/LessonsContext";
import parse from "html-react-parser";
import LessonMedia from "./LessonMedia";
import LessonFiles from "./LessonFiles";
import LessonActions from "./LessonActions";
import LessonNotes from "../notes/LessonNotes";
import LessonResources from "./LessonResources";
import moment from "moment";

const SingleLesson = ({ lesson_id, course_id, section_id, topic_id }) => {
  const {
    lesson,
    getLeccion,
    completarLeccion,
    getSiguienteLeccion,
    terminarReproduccion,
  } = useContext(LessonsContext);

  useEffect(() => {
    return () => {
      terminarReproduccion(lesson_id);
    };
  }, []);

  useEffect(() => {
    //clearLeccion();
    getLeccion(lesson_id);
    /*if (curso === null && course_id) {
      getCourse(course_id);
    }
    if (modulo === null && section_id) {
      getSection(section_id);
    }
    if (tema === null && topic_id) {
      getTema(topic_id);
    }*/
  }, [lesson_id, course_id, section_id, topic_id]);

  const renderContenido = () => {
    if (moment(lesson.available_at).isAfter(moment())) {
      return (
        <div className="mb-4">
          <p>Esta lección estará disponible el</p>
          <h4>
            <i className="fa fa-calendar-alt text-accent me-2"></i>
            {moment(lesson.available_at).format("DD MMM YYYY")}
          </h4>
        </div>
      );
    }
    if (String(lesson.content).includes("<")) {
      return <div data-aos="fade-up">{parse(lesson.content)}</div>;
    }
    return <p data-aos="fade-up">{lesson.content}</p>;
  };

  const renderLeccion = () => {
    if (lesson && lesson !== null) {
      return (
        <div className="mb-4 container-flud px-0">
          {renderContenido()}
          <LessonMedia lesson={lesson} />
          <LessonFiles lesson={lesson} />
          <LessonResources lesson={lesson} />
          <LessonNotes lesson={lesson} />
        </div>
      );
    }
    return (
      <div className="p-5">
        <div className="spinner-border"></div>
      </div>
    );
  };

  const renderCompletar = () => {
    if (lesson && lesson !== null) {
      return (
        <button
          className="btn btn-accent shadow-sm me-3 my-2"
          onClick={() => completarLeccion(lesson_id)}
        >
          Completar Lección <i className="fa fa-check"></i>
        </button>
      );
    }
  };

  return (
    <div className="card container pt-3">
      <h2>{lesson && lesson !== null ? lesson.name : "Lección"}</h2>
      <LessonActions />
      {renderLeccion()}
      <div className="container-fluid mb-4 px-0">
        {renderCompletar()}
        <button
          className="btn btn-primary my-2"
          onClick={() => getSiguienteLeccion(lesson_id)}
        >
          <i className="fa fa-chevron-right me-1"></i> Siguiente
        </button>
      </div>
    </div>
  );
};

export default SingleLesson;
