import React, { useContext, useState } from "react";
import { S3_ENDPOINT, formatMonto, getValue } from "../../utils";
import { ModalContext } from "../../context/ModalContext";
import InscriptionWeb from "../modal/web/InscriptionWeb";
import ProductContent from "./ProductContent";
import parse from "html-react-parser";
import { Link } from "@reach/router";
import moment from "moment";
import { getLocationName } from "../../utils/location";
import { getPrice, hasSalePrice } from "../../utils/products";

const ProductCard = ({
  mode,
  course,
  product,
  location,
  course_accesses,
  completed_courses,
}) => {
  const { modalComponent } = useContext(ModalContext);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleContent = () => {
    modalComponent(
      "Temario",
      <ProductContent product_id={product.product_id} />
    );
  };

  const getSrc = () => {
    return product.file && product.file !== null
      ? `${product.file.name}.${product.file.type}`
      : "";
  };

  const getAccess = () => {
    if (Array.isArray(course_accesses)) {
      let currentAccesses = course_accesses.map(({ course_id }) => course_id);
      let { course_products } = product;
      let hasAccess = false;
      course_products.forEach(({ course_id }) => {
        if (currentAccesses.includes(course_id)) {
          hasAccess = true;
        }
      });
      return hasAccess;
    }
  };

  const getPrecondition = () => {
    if (product.product_id !== 6) return true;
    if (Array.isArray(completed_courses)) {
      const currentCourse = completed_courses.find(
        ({ course_id }) => course_id === 1
      );
      return currentCourse !== undefined;
    }
  };

  const src = getSrc();

  const renderModal = () => {
    if (showModal) {
      return (
        <InscriptionWeb
          mode={mode}
          show={showModal}
          location={location}
          handleClose={handleClose}
          product_id={product.product_id}
        />
      );
    }
  };

  const renderDates = () => {
    let { start_date, end_date } = product;
    if (start_date !== null && end_date !== null) {
      const diff = moment(end_date).utc().diff(moment(start_date).utc(), "days");
      if (diff > 1) {
        return (
          <p className="mb-4 ps-2">
            <span className="badge badge-pill bg-danger">
              <i className="fa fa-calendar me-2"></i>
              {new Array(diff + 1).fill(1).map((one, index) => (
                <span>
                  {moment(start_date).utc().add(index, "days").format("D")}
                  {index === diff - 1 ? " y " : index < diff ? ", " : ""}
                </span>
              ))}{" "}
              de {moment(end_date).utc().format("MMM YYYY")}
            </span>
          </p>
        );
      }
      return (
        <p className="mb-4 ps-2">
          <span className="badge badge-pill bg-danger">
            <i className="fa fa-calendar me-2"></i>
            {moment(start_date).utc().format("DD MMM YYYY")} -{" "}
            {moment(end_date).utc().format("DD MMM YYYY")}
          </span>
        </p>
      );
    }
  };

  const renderCapacity = () => {
    const { capacity } = product;
    if (capacity > 0 && capacity !== null && product.mode === "live") {
      const current_capacity = capacity - product.purchases.length;
      if (current_capacity === 0) {
        return (
          <p>
            <span className="badge badge-pill bg-danger">Sold Out</span>
          </p>
        );
      }
      return (
        <p className="mb-4 text-secondary small">
          Cupo Limitado: {current_capacity} espacios restantes
        </p>
      );
    }
  };

  const renderPrice = () => {
    const product_price = getPrice(product);
    const onSale = hasSalePrice(product);
    const access = getAccess();
    if (product_price && !access) {
      return (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "20px 0",
          }}
          className="d-none bold bg-dark d-lg-block text-center text-white"
        >
          {onSale && (
            <del className="h5 text-danger d-block">
              ${formatMonto(product.price)} MXN
            </del>
          )}
          <span className="h3 bold">${formatMonto(product_price)} MXN</span>
        </div>
      );
    }
  };

  const renderButton = () => {
    const access = getAccess();
    const { capacity } = product;
    if (access) {
      const preconditionMet = getPrecondition();
      if (!preconditionMet) {
        return (
          <button
            className="btn btn-dark mb-2 w-100"
            onClick={handleShow}
            disabled={true}
          >
            COMPLETA EL CURSO ANTERIOR
          </button>
        );
      }
      if (moment(access.expiration_date).isBefore(moment())) {
        return (
          <button onClick={handleShow} className="btn btn-dark mb-2 w-100">
            ACCESO EXPIRADO
          </button>
        );
      }
      if (access.start_date !== null) {
        if (moment(access.start_date).utc().isAfter(moment().utc())) {
          return (
            <button className="btn btn-dark mb-2 w-100" disabled={true}>
              ACCESO A PARTIR DE{" "}
              {moment(access.start_date).utc().format("DD MMM YYYY")}
            </button>
          );
        }
      }
      return (
        <Link
          to={`/mytbm/course/${product.product_id}`}
          className="btn bg-blue mb-2 text-white w-100"
        >
          CONTINUAR
        </Link>
      );
    }
    if (window.location.pathname.includes("mytbm") && !product.upcoming) {
      return (
        <Link to="/courses" className="btn mb-2 btn-primary w-100">
          INSCRIBETE
        </Link>
      );
    }

    const upcoming = getValue(course, "upcoming", true);

    return (
      <button
        className="btn btn-primary mb-2 w-100"
        disabled={capacity === 0 || upcoming}
        onClick={handleShow}
      >
        {upcoming ? "COMING SOON" : "INSCRÍBETE"}
      </button>
    );
  };

  const renderLocation = () => {
    let { location } = product;
    if (location !== null) {
      location = getLocationName(location);
      return (
        <span className="ms-3 large text-secondary">
          <i className="fa text-danger fa-map-pin me-2"></i>
          <span className="bg-blue badge badge-pill">{location}</span>
        </span>
      );
    }
  };

  const renderDescription = () => {
    if (!window.location.pathname.includes("mytbm")) {
      return (
        <div>
          {parse(product.short_description)}
          {parse(product.description)}
        </div>
      );
    }
  };

  const renderPrecondition = () => {
    if (product.product_id === 6) {
      return (
        <div>
          <span className="badge badge-pill bg-danger">
            Requisito:
          </span>
          <span className="d-block small bold">
            Completar Primero
          </span>
          <span className="d-block small bold">
            "Sculpt N Burn"
          </span>
          <span className="d-block mb-3" style={{ fontSize: 12 }}>
            Una vez finalizada la certificación de Sculpt N Burn, puedes
            continuar con Barre (no es necesario esperar tus resultados de SNB).
          </span>
        </div>
      );
    }
  };

  return (
    <div
      className="d-flex align-items-stretch home-course flex-row-reverse flex-lg-row h-100 border-0 shadow"
      style={{ border: "1px solid #27272b", cursor: "pointer" }}
    >
      <div
        className="image-section hide-mobile"
        style={{ position: "relative", width: "50%", height: "100%" }}
      >
        <div
          style={{
            backgroundImage: `url(${S3_ENDPOINT}/${src})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100%",
          }}
          className="col-12"
        ></div>
        {renderPrice()}
      </div>

      <div
        className="ps-4 col-12 col-lg-8 mb-5 mb-lg-0 d-flex align-items-center text-section p-3"
        style={{ flexGrow: 1 }}
      >
        <div className="container-fluid px-0">
          <img
            alt="product"
            src={`${S3_ENDPOINT}/${src}`}
            className="show-mobile w-100 mw-100 mb-4"
          />
          <h3 className="h2 francois">{product.name}</h3>
          <span className="d-block mb-3">
            <span
              className={`badge text-dark badge-pill bg-${
                mode === "on-demand" ? "primary" : "accent"
              } text-uppercase`}
            >
              {mode === "live" ? "en vivo" : mode}
            </span>
            {renderLocation()}
          </span>
          {renderDescription()}
          {renderPrecondition()}
          <div className="d-flex">{renderDates()}</div>
          {renderCapacity()}
          <div className="row my-3" style={{ minHeight: "auto" }}>
            <div className="col-12 col-md-6">
              <button
                onClick={handleContent}
                className="btn btn-outline-secondary mb-2 w-100"
              >
                <i className="fa fa-info-circle"></i> Temario
              </button>
            </div>
            <div className="col-12 col-md-6">{renderButton()}</div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: -30,
          left: 0,
          right: 0,
          padding: "20px 0",
        }}
        className="d-block bg-dark text-white d-lg-none text-center"
      >
        <span className="small fw-normal d-block">desde</span>
        <span className="h3 bold">$ {formatMonto(getPrice(product))} MXN</span>
      </div>
      {renderModal()}
    </div>
  );
};

export default ProductCard;
