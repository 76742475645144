import React, { useContext, useEffect } from "react";
import CourseGrid from "../components/home/CourseGrid";
import { CourseAccessesContext } from "../context/CourseAccessesContext";
import { AuthContext } from "../context/AuthContext";
import { ModalContext } from "../context/ModalContext";
import { navigate } from "@reach/router";

const PanelHome = () => {
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { course_accesses, getCourseAccesses } = useContext(
    CourseAccessesContext
  );

  useEffect(() => {
    getCourseAccesses();
    handleUserInfo();
  }, []);

  const handleInfo = () => {
    navigate("/mytbm/informacion");
    clearModal();
  };

  const handleUserInfo = () => {
    const completedAddress = user.address.street !== null;
    const completeInfo =
      user.birthdate !== null && user.last_name !== null && user.phone !== null;
    if (!completedAddress || !completeInfo) {
      modalComponent(
        "Completa tu Información",
        <div>
          <p>
            Debes completar tu información personal antes de acceder a tus
            certificaciones.
          </p>
          <button onClick={handleInfo} className="btn btn-primary w-100">
            Completar Información
          </button>
        </div>
      );
    }
  };

  return (
    <div id="panel-home" className="pt-5">
      <div className="container-fluid">
        <CourseGrid isPanel course_accesses={course_accesses} />
      </div>
    </div>
  );
};

export default PanelHome;
