import React from "react";
import { S3_ENDPOINT } from "../../utils";

const VideoAbout = ({ home_video }) => {
  return (
    <div id="about-video" className="container-fluid position-relative py-md-5">
      <div className="row flex-sm-row-reverse">
        <div className="col-lg-6 col-md-12 position-relative">
          {/* desktop */}
          <div className="card p-5 shadow">
            <div className="row align-items-center h-100">
              <div className="card-body">
                <h3 className="h1 display-5 text-uppercase">
                  ¿Qué es <br />
                  The Body Method?
                </h3>
                <p className="mb-0">
                  Lleva tus conocimientos a otro nivel con nuestras
                  certificaciones. Aprende los secretos detrás de nuestro método
                  ultra efectivo y prepárate para conocer nuestra fórmula única.
                </p>
                <p>
                  Todo lo que tienes que saber para ser una experta en Barre,
                  Sculpt and Burn y Jump. ¡Aprende de manera fácil y divertida!
                  Enriquece tu práctica, empoderate y gana dinero haciendo lo
                  que te apasiona.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 px-md-5">
          <video
            poster={`${S3_ENDPOINT}/poster-explicacion.JPG`}
            className="position-relative mw-100 w-100 shadow"
            data-object-fit="cover"
            data-autoplay
            controls
          >
            <source src={home_video} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoAbout;
