import React, { useContext } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { AuthContext } from "../../context/AuthContext";

const CheckoutDiscount = ({ product_id, payment_plan }) => {
  const { user } = useContext(AuthContext);

  const { discountCode, setDiscountCode, spinnerDescuento, validarDescuento } =
    useContext(CheckoutContext);

  const handleSubmitDescuento = (e) => {
    e.preventDefault();
    const payload = {
      product_id,
    };
    if (payment_plan && payment_plan !== null) {
      payload.payment_plan_id = payment_plan.payment_plan_id;
    }
    validarDescuento(discountCode, payload);
  };

  const renderDescuento = () => {
    if (user !== null) {
      return (
        <form onSubmit={handleSubmitDescuento}>
          <label className="bold mb-2">Código de Descuento</label>
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 mt-2">
              <input
                type="text"
                value={discountCode}
                className="form-control"
                onChange={(e) => setDiscountCode(e.target.value)}
              />
            </div>
            <div className="col-12 col-lg-6 mt-2">
              <button
                type="submit"
                className="btn btn-sm py-2 w-100 btn-outline-dark"
                disabled={spinnerDescuento}
              >
                {spinnerDescuento ? (
                  <div className="spinner-border"></div>
                ) : (
                  "Aplicar"
                )}
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="card p-3 shadow">{renderDescuento()}</div>;
};

export default CheckoutDiscount;
