import React from "react";
import { shortFAQ, extendedFAQ } from "../../utils/faq";
import Accordion from "../home/Accordion";
import { Link } from "@reach/router";
import Navbar from "./Navbar";

const PreguntasFrecuentes = ({ short }) => {
  return (
    <div>
      {!short && <Navbar />}
      <div id="faq" className="container-fluid py-5 bg-light">
        <div className="container my-4 text-center">
          <h2 className="text-capitalize">PREGUNTAS FRECUENTES</h2>
          <Accordion id="faq" items={short ? shortFAQ : extendedFAQ} />
          {short && (
            <Link to="/preguntas-frecuentes" className="btn btn-accent my-3">
              Ver Más Preguntas Frecuentes
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
