import React, { createContext, useReducer, useContext } from "react";
import NotesService from "../services/NotesService";
import NotesReducer from "../reducers/NotesReducer";
import {
  SET_NOTE,
  DELETE_NOTE,
  CREATE_NOTE,
  RECEIVED_NOTES,
  SET_PROPERTY_NOTE,
} from "../types/Notes";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  notes: null,
  note: null,
};

export const NotesContext = createContext(initialState);

export const NotesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NotesReducer, initialState);

  const { clearModal } = useContext(ModalContext);

  const getNotes = (filters) => {
    NotesService.getNotes(filters).then((res) => {
      const { notes } = res.data;
      dispatch({ type: RECEIVED_NOTES, payload: notes });
    });
  };

  const setNote = (note) => {
    dispatch({ type: SET_NOTE, payload: note });
  };

  const createNote = () => {
    dispatch({ type: CREATE_NOTE });
  };

  const setPropertyNote = (key, value) => {
    dispatch({ type: SET_PROPERTY_NOTE, payload: { key, value } });
  };

  const saveNote = (note, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = NotesService.putNote;
    if (!note.note_id) {
      service = NotesService.postNote;
    }
    service(note)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        if (typeof callback === "function") {
          callback();
        }
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteNote = (section_id, note_id) => {
    NotesService.deleteNote(section_id, note_id).then(() => {
      dispatch({ type: DELETE_NOTE, payload: note_id });
    });
  };

  return (
    <NotesContext.Provider
      value={{
        ...state,
        getNotes,
        setNote,
        saveNote,
        createNote,
        deleteNote,
        setPropertyNote,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};
