import React from "react";
import { BASE_URL } from "../../utils";

const LessonResources = ({ lesson }) => {
  const renderResources = () => {
    if (Array.isArray(lesson.resources)) {
      return (
        <div>
          <h4 className="mt-5 mb-3 border-bottom pb-3">Descargables</h4>
          {lesson.resources.map((multimedia, index) => (
            <div
              key={index}
              className="col-12 col-md-2 descargable-cont"
              data-aos="fade-up"
            >
              <a
                href={`${BASE_URL}/files/${multimedia.file_id}`}
                target="_blank"
              >
                <div className="descargable">
                  <i className="fa fa-file"></i>
                  <p className="mt-2 text-dark no-decoration">
                    {multimedia.name}
                  </p>
                </div>
              </a>
            </div>
          ))}
        </div>
      );
    }
  };
  return <div>{renderResources()}</div>;
};

export default LessonResources;
