import React, { useState } from "react";
import { S3_ENDPOINT } from "../../utils";

const testimonials = [
  {
    src: "Ale-Villarreal",
  },
  {
    src: "Lizzy-Dillon",
  },
  {
    src: "Nerea",
  },
  {
    src: "Perla-Martinez",
  },
  {
    src: "Rut-Salazar",
  },
  {
    src: "Salma-Yamileth",
  },
];

const VideoTestimonials = () => {
  const [selected, setSelected] = useState(0);

  const renderTestimonials = () => {
    if (Array.isArray(testimonials)) {
      return testimonials.map(({ src }, index) => (
        <video
          key={index}
          className={`video-testimonial bg-white p-4 card shadow ${
            selected === index ? "d-block m-auto" : "d-none"
          }`}
          poster={`${S3_ENDPOINT}/${src}.png`}
          playsInline
          controls
        >
          <source src={`${S3_ENDPOINT}/${src}.mp4`} type="video/mp4" />
        </video>
      ));
    }
  };

  const renderName = () => {
    let currentVideo = testimonials[selected];
    if (currentVideo) {
      return String(currentVideo.src).replace("-", " ");
    }
  };

  return (
    <div className="row bg-light py-5 align-items-center">
      <div className="col-12 col-md-8">
        <div className="py-5">{renderTestimonials()}</div>
      </div>
      <div className="col-12 col-md-4">
        <div className="h1 w-25 mt-2 d-lg-flex d-none">
          <h3 className="h1 display-5 text-uppercase">
            NUESTRAS TBM BABES CERTIFICADAS
          </h3>
        </div>
        <button
          className="btn btn-secondary btn-arrow btn-round shadow-sm me-2"
          onClick={() => {
            if (selected === 0) {
              setSelected(testimonials.length - 1);
            } else {
              setSelected(selected - 1);
            }
          }}
        >
          <i className="fa fa-chevron-left"></i>
        </button>
        <button
          className="btn btn-secondary btn-arrow btn-round shadow-sm"
          onClick={() => {
            if (selected === testimonials.length - 1) {
              setSelected(0);
            } else {
              setSelected(selected + 1);
            }
          }}
        >
          <i className="fa fa-chevron-right"></i>
        </button>
        <p className="mt-4 h3">{renderName()}</p>
      </div>
    </div>
  );
};

export default VideoTestimonials;
