import React, { useContext } from "react";
import FinalExamTarget from "./FinalExamTarget";
import { ModalContext } from "../../context/ModalContext";
import { AttempsContext } from "../../context/AttemptsContext";

const AttemptFinalExam = ({ course }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { createAttempt } = useContext(AttempsContext);

  const handleFinalExam = () => {
    const courseTest = course.tests[0];
    if (courseTest) {
      modalComponent(
        "Presentar Quiz",
        <div>
          <p>Confirma que quieres comenzar tu examen final.</p>
          <div className="row">
            <div className="col-6">
              <button onClick={clearModal} className="btn text-muted w-100">
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                onClick={() => createAttempt(courseTest.test_id)}
                className="btn btn-primary w-100"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="bg-accent text-white p-4">
      <FinalExamTarget
        course_id={course.course_id}
        buttonTitle="Comenzar Examen"
        handleClick={handleFinalExam}
      >
        <h3>¡Has Completado Todos Los Módulos!</h3>
        <p>
          Es hora de comenzar tu examen final. Deberás subir los videos
          correspondientes.
        </p>
      </FinalExamTarget>
    </div>
  );
};

export default AttemptFinalExam;
