import React, { useState, useEffect } from "react";
import UserAnswerFeedbackData from "./UserAnswerFeedbackData";

const AttemptQuestion = ({ question, answers }) => {
  const [questionAnswer, setQuestionAnswer] = useState(null);

  useEffect(() => {
    const currentAnswer = findQuestionAnswer(question);
    setQuestionAnswer(currentAnswer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, answers]);

  const findQuestionAnswer = (question) => {
    if (Array.isArray(answers)) {
      return answers.find(
        (answer) =>
          parseInt(answer.question_id) === parseInt(question.question_id)
      );
    }
  };

  const renderIcon = (questionAnswer) => {
    if (questionAnswer.score !== null) {
      if (questionAnswer.score > 0) {
        return <i className="fa fa-check text-success me-2"></i>;
      }
      return <i className="fa fa-times text-danger me-2"></i>;
    }
  };

  const renderContent = (questionAnswer) => {
    if (String(questionAnswer.content).includes("http")) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <video
              className="w-100"
              style={{ maxWidth: 500 }}
              playsInline
              controls
            >
              <source src={questionAnswer.content} type="video/mp4" />
            </video>
          </div>
          <div className="col-12 col-md-6">
            <UserAnswerFeedbackData questionAnswer={questionAnswer} />
          </div>
        </div>
      );
    }
    return (
      <p className="px-3">
        {renderIcon(questionAnswer)} {questionAnswer.content}
      </p>
    );
  };

  const renderAnswer = () => {
    if (questionAnswer && questionAnswer !== null) {
      return <div className="my-3">{renderContent(questionAnswer)}</div>;
    }
  };

  return (
    <div className="w-100 mb-4">
      <p className="p-3 bold mb-1 bg-light">
        #{question.order} - {question.content}
      </p>
      {renderAnswer()}
    </div>
  );
};

export default AttemptQuestion;
