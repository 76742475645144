import moment from "moment";

export const getCourseLastAttempt = (course) => {
  if (course && course !== null) {
    const { tests } = course;
    if (Array.isArray(tests)) {
      const finalExam = tests[0];
      if (finalExam) {
        const { attempts } = finalExam;
        if (Array.isArray(attempts)) {
          let sortedAttempts = attempts.sort((a, b) =>
            moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1
          );
          const lastAttempt = sortedAttempts[0];
          if (lastAttempt) {
            return lastAttempt;
          }
        }
      }
    }
  }
};

export const getAccess = (course) => {
  const { course_accesses } = course;
  if (Array.isArray(course_accesses)) {
    const currentAccess = course_accesses[0];
    return currentAccess;
  }
};

export const getCapacity = (course, location) => {
  let capacity = 0;
  const { course_products } = course;
  if (Array.isArray(course_products)) {
    course_products.forEach(({ product }) => {
      if (
        (location && product.location === location) ||
        location !== "studio"
      ) {
        capacity += parseInt(product.capacity);
      }
    });
  }
  return capacity;
};

export const isCompleted = (course, completed_courses) => {
  if (Array.isArray(completed_courses)) {
    return (
      completed_courses.find(
        ({ course_id }) => parseInt(course_id) === parseInt(course.course_id)
      ) !== undefined
    );
  }
};

export const getPrecondition = (course, completed_courses) => {
  if (course.course_id === 1) return true;
  if (Array.isArray(completed_courses)) {
    const currentCourse = completed_courses.find(
      ({ course_id }) => course_id === course.course_id - 1
    );
    return currentCourse !== undefined;
  }
};

export const getPrice = (course) => {
  let minPrice;
  const { course_products } = course;
  if (Array.isArray(course_products)) {
    course_products
      .filter(({ course_id }) => course_id === course.course_id)
      .forEach(({ product }) => {
        if (!minPrice) {
          minPrice = product.price;
        } else if (product.price < minPrice) {
          minPrice = product.price;
        }
      });
  }
  return minPrice;
};

export const getStartEndDate = (course, location) => {
  const { course_products } = course;
  if (Array.isArray(course_products)) {
    let minDate;
    let endDate;
    course_products.forEach(({ product }) => {
      if ((location && product.location === location) || !location) {
        if (product.start_date !== null) {
          if (!minDate) {
            minDate = product.start_date;
            endDate = product.end_date;
          } else if (moment(product.start_date).isBefore(minDate)) {
            minDate = product.start_date;
            endDate = product.end_date;
          }
        }
      }
    });
    return { startDate: minDate, endDate };
  }
  return { startDate: null, endDate: null };
};

export const isAccessExpired = (course_access) => {
  return moment(course_access.expiration_date)
    .utc()
    .endOf("day")
    .isBefore(moment());
};

export const isExamAccessExpired = (course_access) => {
  return moment(course_access.expiration_date)
    .utc()
    .add(7, "days")
    .endOf("day")
    .isBefore(moment());
};
