import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";
import React from "react";

const VideoBanner = ({ home_video }) => {
  return (
    <div className="mb-5">
      <div className="video-container">
        <video
          className="mw-100 w-100 shadow video"
          autoPlay
          data-autoplay
          playsInline
          loop
          muted
          data-object-fit="cover"
          poster={`${S3_ENDPOINT}/landing-poster.JPG`}
        >
          <source src={home_video} type="video/mp4" />
        </video>
      </div>
      <div className="video-overlay d-block position-relative">
        <div className="container h-100">
          <div className="row py-5 h-100 align-items-center flex-md-row-reverse">
            <div className="col-12 pt-5 col-md-6">
              <img
                src="https://tbmcerts.s3.us-west-1.amazonaws.com/established.png"
                className="established mb-5"
              />
            </div>
            <div className="text-white col-12 col-md-6">
              <h1 className="h2 text-uppercase">
                ¡Certificate como entrenadora del método más efectivo y aprende
                de las master coaches más calificadas!
              </h1>
              <h2 className="h4 mb-3">
                Emprende tu propio negocio y vive el trabajo de tus sueños
              </h2>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Link
                    id="main-cta"
                    to="/live"
                    className="btn btn-accent w-100 mb-3"
                  >
                    <span className="h6">EN VIVO</span>
                  </Link>
                </div>
                <div className="col-12 col-md-6">
                  <Link to="/ondemand" className="btn btn-dark w-100 mb-3">
                    <span className="h6">ON DEMAND</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
