import React, { useState } from "react";
import { S3_ENDPOINT, formatMonto, getValue } from "../../utils";
import InscriptionWeb from "../modal/web/InscriptionWeb";
import success from "../../assets/images/exito.png";
import parse from "html-react-parser";
import { Link } from "@reach/router";
import moment from "moment";
import {
  getAccess,
  getCapacity,
  getCourseLastAttempt,
  getPrice,
  getStartEndDate,
  isCompleted,
  isExamAccessExpired,
} from "../../utils/course";

const SingleCourseCard = ({ mode, course, location, completed_courses }) => {
  const [showModal, setShowModal] = useState(false);

  const access = getAccess(course);
  const minPrice = getPrice(course);
  const capacity = getCapacity(course, location);
  const completed = isCompleted(course, completed_courses);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const getSrc = () => {
    return course.file && course.file !== null
      ? `${course.file.name}.${course.file.type}`
      : "";
  };

  const src = getSrc();

  const renderModal = () => {
    if (showModal) {
      return (
        <InscriptionWeb
          mode={mode}
          show={showModal}
          course={course}
          location={location}
          handleClose={handleClose}
        />
      );
    }
  };

  const renderDates = () => {
    if (access) {
      return renderDateString(
        access.start_date !== null ? access.start_date : access.createdAt,
        access.expiration_date
      );
    }
    const { startDate, endDate } = getStartEndDate(course, location);
    if (startDate !== null && endDate !== null) {
      return renderDateString(startDate, endDate);
    }
  };

  const renderDateString = (startDate, endDate) => {
    const diff = moment(endDate).utc().diff(moment(startDate).utc(), "days");
    if (diff > 1 && !access) {
      return (
        <p className="mb-4 ps-2">
          <i className="fa fa-calendar me-2"></i>
          {new Array(diff).fill(1).map((one, index) => (
            <span>
              {moment(startDate).utc().add(index, "days").format("D")}
              {index === diff - 2 ? " y " : index < diff - 1 ? ", " : ""}
            </span>
          ))}{" "}
          de
          {moment(endDate).utc().format("MMM YYYY")}
        </p>
      );
    }
    return (
      <p className="mb-4 ps-2">
        <i className="fa fa-calendar me-2"></i>
        {moment(startDate).utc().format("DD MMM YYYY")} -{" "}
        {moment(endDate).utc().format("DD MMM YYYY")}
      </p>
    );
  };

  const renderPrice = () => {
    if (minPrice && !access) {
      return (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "20px 0",
          }}
          className="d-none bold bg-dark d-lg-block text-center text-white"
        >
          <span className="small fw-normal d-block">desde</span>
          <span className="h3 bold">${formatMonto(minPrice)} MXN</span>
        </div>
      );
    }
  };

  const renderButton = () => {
    if (access) {
      const expiredAccess = isExamAccessExpired(access);
      if (expiredAccess) {
        const lastAttempt = getCourseLastAttempt(course);
        if (lastAttempt && lastAttempt !== null) {
          if (lastAttempt.gradedAt !== null) {
            return (
              <Link
                to={`/mytbm/attempt/${lastAttempt.attempt_id}`}
                className="btn btn-accent w-100 my-3"
              >
                VER RESULTADO DE EXAMEN FINAL
              </Link>
            );
          }
          if (lastAttempt.finishedAt !== null) {
            return (
              <Link
                to={`/mytbm/attempt/${lastAttempt.attempt_id}`}
                className="btn bg-blue text-white w-100 my-3"
              >
                VER EXAMEN FINAL
              </Link>
            );
          }
          if (
            lastAttempt.finishedAt === null &&
            lastAttempt.requested_changes
          ) {
            return (
              <Link
                to={`/mytbm/attempt/${lastAttempt.attempt_id}`}
                className="btn bg-dark text-prfimary text-white w-100 my-3"
              >
                CORREGIR EXAMEN FINAL
              </Link>
            );
          }
        }
        return (
          <button onClick={handleShow} className="btn btn-dark w-100 my-3">
            ACCESO EXPIRADO
          </button>
        );
      }
      if (access.start_date !== null) {
        if (moment(access.start_date).utc().isAfter(moment().utc())) {
          return (
            <button className="btn btn-dark w-100 my-3" disabled={true}>
              ACCESO A PARTIR DE{" "}
              {moment(access.start_date).utc().format("DD MMM YYYY")}
            </button>
          );
        }
      }
      return (
        <Link
          to={`/mytbm/course/${course.course_id}`}
          className="btn bg-blue text-white w-100 my-3"
        >
          {course.completed_lessons.length === 0 ? "EMPEZAR" : "CONTINUAR"}
        </Link>
      );
    }
    if (window.location.pathname.includes("mytbm") && !course.upcoming) {
      return (
        <Link to="/courses" className="btn btn-primary w-100 my-3">
          INSCRIBETE
        </Link>
      );
    }
    const upcoming = getValue(course, "upcoming", true);
    return (
      <button
        className="btn btn-primary w-100 my-3"
        disabled={capacity === 0 || upcoming}
        onClick={handleShow}
      >
        {upcoming ? "COMING SOON" : "INSCRÍBETE"}
      </button>
    );
  };

  const renderDescription = () => {
    if (!window.location.pathname.includes("mytbm")) {
      return (
        <div>
          {" "}
          <p className="my-4 small">{parse(course.short_description)}</p>
          <p className="my-4 small">{parse(course.description)}</p>
        </div>
      );
    }
  };

  const renderPriceBottom = () => {
    if (!access) {
      return (
        <div
          style={{
            position: "absolute",
            bottom: -30,
            left: 0,
            right: 0,
            padding: "20px 0",
          }}
          className="d-block bg-dark text-white d-lg-none text-center"
        >
          <span className="h3 bold">$ {formatMonto(minPrice)} MXN</span>
          <span className="small fw-normal d-block">
            planes de pago disponibles
          </span>
        </div>
      );
    }
  };

  const renderPrecondition = () => {
    if (course.course_id === 2) {
      return (
        <div>
          <span className="badge badge-pill bg-black">
            Requisito:
          </span>
          <span className="d-block small bold">
            Completar Primero
          </span>
          <span className="d-block small bold">
            "Sculpt N Burn"
          </span>
          <span className="d-block mb-3" style={{ fontSize: 12 }}>
            Una vez finalizada la certificación de Sculpt N Burn, puedes
            continuar con Barre (no es necesario esperar tus resultados de SNB).
          </span>
        </div>
      );
    }
  };

  return (
    <div
      className="d-flex align-items-stretch home-course flex-row-reverse flex-lg-row h-100 border-0 shadow position-relative"
      style={{ border: "1px solid #27272b", cursor: "pointer" }}
    >
      {completed && (
        <img
          alt="checkmark"
          src={success}
          style={{
            position: "absolute",
            right: "20px",
            maxWidth: 50,
            top: "20px",
          }}
        />
      )}
      <div
        className="image-section"
        style={{ position: "relative", width: "50%", height: "100%" }}
      >
        <div
          style={{
            backgroundImage: `url(${S3_ENDPOINT}/${src})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100%",
          }}
          className="col-12"
        ></div>
        {renderPrice()}
      </div>
      <div
        className="ps-4 col-12 col-lg-8 mb-5 mb-lg-0 d-flex align-items-center text-section p-3"
        style={{ flexGrow: 1 }}
      >
        <div className="container-fluid">
          <h3 className="h2 francois">{course.name}</h3>
          <span
            className={`badge text-dark badge-pill bg-${
              mode === "on-demand" ? "primary" : "accent"
            } text-uppercase`}
          >
            {mode === "live" ? "en vivo" : mode}
          </span>

          {renderDescription()}
          {renderPrecondition()}
          <div className="d-flex">{renderDates()}</div>
          {renderButton()}
        </div>
      </div>
      {renderPriceBottom()}
      {renderModal()}
    </div>
  );
};

export default SingleCourseCard;
