import React, { useState } from "react";
import CoursesFeatures from "../components/courses/CoursesFeatures";
import CustomSwitch from "../components/customers/CustomSwitch";
import GridCourses from "../components/home/GridCourses";
import { Grid } from "@material-ui/core";
import Navbar from "../components/global/Navbar";
import Footer from "../components/global/Footer";
import "../css/SingleCurso.css";
import CoursesHeader from "../components/courses/CoursesHeader";

const features = [
  "Incluye 3 disciplinas: Barre, Sculpt N Burn y Jump.",
  "Recibe retroalimentación, correcciones y resuelve dudas en tiempo real.",
  "Vive un fin de semana intensivo con nuestras master coaches.",
  "Incluye también 1 mes de acceso a nuestra plataforma ON DEMAND",
];

const LiveCourses = () => {
  const [location, setLocation] = useState("studio");

  return (
    <div>
      <Navbar />
      <CoursesHeader />
      <div className="h-100">
        <Grid className="justify-content-center align-items-center">
          <div className="d-flex flex-column-reverse flex-lg-column w-100">
            <Grid item xs={12} align="center" className="mt-4 mb-4">
              <CustomSwitch
                cursos={true}
                value={location}
                onChange={setLocation}
              />
            </Grid>
            <h1 className="text-uppercase h3 text-center">
              CERTIFICACIONES{" "}
              {location === "studio" ? "PRESENCIALES (MTY)" : "REMOTO"}{" "}
            </h1>
            <div className="separator my-3"></div>
          </div>
          <p className="text-center large w-100">
            {location === "studio"
              ? "Presencial: Esta certificación se imparte en nuestro estudio presencial en Monterrey, Mexico."
              : "Esta certificación se imparte mediante zoom transmitiendo en vivo."}
          </p>
          <CoursesFeatures features={features} />
          <Grid
            item
            xs={12}
            className="gridCertificaciones mb-5 justify-content-center align-items-center"
          >
            <Grid item xs={12} className="px-3 px-xl-5 mb-5">
              <GridCourses mode="live" location={location} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default LiveCourses;
