import React from "react";
import Accordion from "../home/Accordion";

const AboutAccordions = () => {
  return (
    <div id="about" className="container-fluid bg-accent py-5">
      <div className="container-fluid my-4 text-center py-5">
        <h2 className="text-white">LO QUE APRENDERÁS</h2>
        <span
          style={{
            width: "50px",
            borderTop: "2px solid white",
            height: "5px",
            color: "transparent",
          }}
        >
          ..................................
        </span>

        <div className="row mx-0">
          <div className="col-md-6">
            <Accordion
              id="learn-left"
              icon="check-blue"
              items={[
                {
                  title: "En qué consiste el método TBM",
                  htmlContent:
                    "Nuestro método está sustentado por bases científicas, desde la combinación de entrenamientos hasta la estructura de una rutina, logrando así que estos sean workouts inteligentes y den resultado.",
                },
                {
                  title: "Conceptos aplicados al ejercicio y anatomía",
                  htmlContent:
                    "Tipos de entrenamientos y sus clasificaciones, tipos de contracciones musculares, mecanismos de hipertrofia, etc. ",
                },
                {
                  title: "Mecanismo y técnica correcta de los ejercicios",
                  htmlContent:
                    "Aprende la manera correcta de realizar los ejercicios, las variaciones, progresiones, los músculos primarios y secundarios en los que están enfocados y muchas otras cosas más. ",
                },
              ]}
            />
          </div>
          <div className="col-md-6 d-lg-block">
            <Accordion
              id="learn-right"
              icon="check-blue"
              items={[
                {
                  title: "Creación de rutina",
                  htmlContent:
                    "Diseña y estructura tus propias rutinas de acuerdo a tus objetivos. ",
                },
                {
                  title: "Cueing",
                  htmlContent:
                    "Pautas y señalizaciones que te ayudarán a impartir una clase como una experta, a crear una comunidad e ir más allá de ser solo un workout  ¡Aprende a enseñar!",
                },
                {
                  title: "Playlist",
                  htmlContent:
                    "Conoce qué géneros musicales y beats per minute funcionan mejor para cada circuito y crea una experiencia única.",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAccordions;
