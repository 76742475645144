import api from "./api";

const route = "/tests";

export default {
  getTest: (test_id) => api.get(`${route}/${test_id}`),
  getIntentoTest: (idIntentoTest) =>
    api.get(`${route}/intento/${idIntentoTest}`),
  getTestsCourse: (course_id) => api.get(`${route}/course/${course_id}`),
  getTestsSection: (section_id) => api.get(`${route}/modulo/${section_id}`),
  getTestsTema: (idTema) => api.get(`${route}/tema/${idTema}`),
  getTestsLeccion: (idLeccion) => api.get(`${route}/leccion/${idLeccion}`),
  postTestCourse: (test) => api.post(`${route}/curso`, { ...test }),
  postTestSection: (test) => api.post(`${route}/modulo`, { ...test }),
  postTestTema: (test) => api.post(`${route}/tema`, { ...test }),
  postTestLeccion: (test) => api.post(`${route}/leccion`, { ...test }),
  putTest: (test) => api.put(route, { ...test }),
  deleteTest: (test_id) => api.delete(`${route}/${test_id}`),
};
