import React, { useContext } from "react";
import LessonNotes from "./LessonNotes";
import { S3_ENDPOINT } from "../../utils";
import { ModalContext } from "../../context/ModalContext";

const notes = [
  { content: "Esta es una nota" },
  { content: "Esta es una nota" },
  { content: "Esta es una nota" },
];

const CourseNotes = () => {
  const { modalComponent } = useContext(ModalContext);
  const toggleNotes = () => {
    modalComponent("Bloc de Notas", <LessonNotes lesson={{ notes }} />);
  };
  return (
    <>
      <button className="btn btn-notes bg-blue" onClick={toggleNotes}>
        <img src={`${S3_ENDPOINT}/notes.png`} className="icon" />
      </button>
    </>
  );
};

export default CourseNotes;
