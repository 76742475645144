import { COURSE_ACCESSES_RECEIVED } from "../types/course_accesses";

const CourseAccessesReducer = (state, { type, payload }) => {
  switch (type) {
    case COURSE_ACCESSES_RECEIVED:
      return { ...state, course_accesses: payload };
    default:
      return { ...state };
  }
};
export default CourseAccessesReducer;
