import React, { useContext, useState, useEffect } from "react";
import { SectionsContext } from "../../context/SectionsContext";
import { CoursesContext } from "../../context/CoursesContext";
import { isAccessExpired } from "../../utils/course";
import SectionCard from "./SectionCard";
import moment from "moment";

const Sections = ({ course_id }) => {
  const [hasAccess, setHasAccess] = useState(true);
  const { sections, getSectionsCourse } = useContext(SectionsContext);
  const { course } = useContext(CoursesContext);

  useEffect(() => {
    getSectionsCourse(course_id);
  }, []);

  useEffect(() => {
    if (course && course !== null) {
      if (isAccessExpired(course)) {
        setHasAccess(false);
      }
    }
  }, [course]);

  const renderSections = () => {
    if (Array.isArray(sections)) {
      if (!hasAccess) {
        return (
          <div className="container">
            <div className="card text-center bg-light shadow">
              <i className="fa fa-lock fa-3x"></i>
              <h2 className="my-3">Ups! Ya no tienes acceso a los videos</h2>
              <p>
                Tienes hasta el{" "}
                {moment(course.final_exam_expiration_date).format(
                  "DD MMM YYYY"
                )}{" "}
                para subir tu examen final
              </p>
            </div>
          </div>
        );
      }
      return sections.map((section) => (
        <SectionCard
          key={section.section_id}
          section={section}
          course_id={course_id}
        />
      ));
    }
  };

  return (
    <div className="container-fluid px-0 py-0">
      <div className="row g-4 ps-lg-0 px-0 text-dark col-12 mx-0 px-lg-0">
        {renderSections()}
      </div>
    </div>
  );
};

export default Sections;
