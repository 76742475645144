import { SHOW_SUCCESS, SHOW_ALERT, CLEAR_ALERT, CLEAR_SUCCESS } from "../types";
import moment from "moment";
import axios from "axios";

moment.locale("es", {
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
  weekdaysShort: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
});

const stripe_dev_key =
  "pk_test_51HmStSKPpBTzo0mTCB7a2Q2Z4J9Yf3BGd5bchAesEpIRJxCMpx4v0odIPAkFb3FL8Rag1LUrhzd5LdDVnMQJRyMl00jVajqjeq";

const stripe_live_key =
  "pk_live_51HmStSKPpBTzo0mTpATTVNNwELwTTGVUaGhmtDzjRT0B4jYGJjcEPbolxOcgdMXeJDT0Ifo5jilJIM0fa9dnKJv900JzvGv6LA";

export const S3_ENDPOINT = "https://tbmcerts.s3.us-west-1.amazonaws.com";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_dev_key : stripe_live_key;

export const HOME_URL = "https://thebodymethod.mx";

export const displaySuccess = (dispatch, message) => {
  dispatch({ type: SHOW_SUCCESS, payload: message });
  setTimeout(() => {
    dispatch({ type: CLEAR_SUCCESS });
  });
};

export const displayError = (dispatch, error) => {
  if (typeof error === "object") {
    error = error.toString();
  }
  dispatch({ type: SHOW_ALERT, payload: error });
  setTimeout(() => dispatch({ type: CLEAR_ALERT }), 3000);
};

export const BASE_URL =
  (process.env.NODE_ENV === "development" ? "http://localhost:4000" : "") +
  "/api";

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const menuitems = [
  {
    name: "My Certifications",
    handle: "/mytbm",
  },
  {
    name: "Live Certifications",
    handle: "/live",
  },
  {
    name: "On-Demand Certifications",
    handle: "/ondemand",
  },
];

export const cuenta = [
  {
    name: "Mi Información",
    handle: "/mytbm/informacion",
  },
  {
    name: "Mis Compras",
    handle: "/mytbm/membresias",
  },
  {
    name: "Mis Pagos",
    handle: "/mytbm/pagos",
  },
];

export const adminitems = [
  {
    name: "Clases",
    handle: "mytbm/admin/asistentes",
  },
  {
    name: "Clientes",
    handle: "mytbm/admin/clientes",
  },
];

export const Instructoritems = [
  {
    name: "Clases",
    handle: "mytbm/admin/asistentes",
  },
];

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const categories = {
  dance: [11, 12, 13, 15],
  workout: [14, 15],
  presenciales: [11742, 11743, 11744, 11745],
};

export const getCompradasPresenciales = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter((class_category) =>
    package_ids.includes(class_category.package_class_id)
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getPresencialesVigentes = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter(
    (class_category) =>
      package_ids.includes(class_category.package_class_id) &&
      moment(class_category.created_at)
        .add(class_category.package_days, "days")
        .isAfter(moment())
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getVigencia = (handle, user) => {
  if (user.vigencias) {
    const paquete = user.vigencias.find(
      (class_category) => class_category.handle === handle
    );
    if (paquete) {
      return paquete.expiration;
    }
  }
};

export const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const mapas = {
  1: [4, 6],
  2: [2, 4, 4],
};

export const iconosMapas = {
  1: "fas fa-bicycle",
  2: "fas fa-praying-hands",
};

export const durations = [
  "10 a 19 minutos",
  "20 a 29 minutos",
  "30 a 39 minutos",
  "40 a 49 minutos",
  "50 a 59 minutos",
  "1 hora o mas",
];

export const CANCEL_TIMEFRAME = 4;

export const canCancel = (single_class) => {
  const currentHour = moment();
  const classHour = moment(single_class.class_date);
  const diff = classHour.diff(currentHour, "hours") + 5;
  return diff >= CANCEL_TIMEFRAME;
};

export const getStatusReservacion = (reservacion) => {
  if (reservacion.deletedAt !== null) {
    return <span className="text-danger">Cancelada</span>;
  }
  if (reservacion.is_cash) {
    if (!reservacion.is_paid) {
      return <span className="text-danger">Pago en Efectivo Incompleto</span>;
    }
  }
  if (
    !reservacion.attend &&
    moment().isAfter(moment(reservacion.single_class.class_date))
  ) {
    return <span className="text-warning">No Asistió</span>;
  }
  if (reservacion.attend) {
    return <span className="text-success">Exitosa</span>;
  }
  return <span className="text-secondary">Próxima</span>;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (input_str) => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  return re.test(input_str);
};

export const renderSubscriptionPeriod = (product) => {
  let message = "";
  const { subscription_interval, subscription_period } = product;
  if (subscription_interval > 1) {
    message = `cada ${subscription_interval}`;
    switch (subscription_period) {
      case "year":
        message = `${message} años`;
        break;
      case "day":
        message = `${message} días`;
        break;
      default:
        message = `${message} meses`;
    }
  } else if (subscription_interval !== null) {
    message = `cada`;
    switch (subscription_period) {
      case "year":
        message = `${message} año`;
        break;
      case "day":
        message = `${message} día`;
        break;
      default:
        message = `${message} mes`;
    }
  }
  return message;
};

export const renderFirstInvoicePeriod = (product) => {
  let message = "";
  const { subscription_interval, subscription_period } = product;
  if (subscription_interval > 1) {
    message = `los primeros ${subscription_interval}`;
    switch (subscription_period) {
      case "year":
        message = `${message} años`;
        break;
      case "day":
        message = `${message} days`;
        break;
      default:
        message = `${message} meses`;
    }
  } else {
    message = `el primer`;
    switch (subscription_period) {
      case "year":
        message = `${message} año`;
        break;
      case "day":
        message = `${message} día`;
        break;
      default:
        message = `${message} mes`;
    }
  }
  return message;
};

export const getValue = (object, key, boolean) => {
  if (object && object !== null) {
    const value = object[key];
    if (value && value !== null) {
      if (boolean) return value === true || value === 1;
      return value;
    }
  }
  if (boolean) return false;
  return "";
};

export const saveContentToWord = (element) => {
  var preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  var postHtml = "</body></html>";
  var html = preHtml + document.getElementById(element).innerHTML + postHtml;

  var blob = new Blob(["\ufeff", html], {
    type: "application/msword",
  });

  // Specify link url
  var url =
    "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  let filename = "Notas_TBM_Certifications.doc";

  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();
  }

  document.body.removeChild(downloadLink);
};

export const downloadFile = async (url, fileName) => {
  axios({
    url,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const href = window.URL.createObjectURL(response.data);

    const anchorElement = document.createElement("a");

    anchorElement.href = href;
    anchorElement.download = fileName;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  });
};
