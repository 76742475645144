import React, { useContext } from "react";
import parse from "html-react-parser";
import { AttempsContext } from "../../context/AttemptsContext";

const criteria = [
  "tecnica",
  "coordinacion",
  "estructura",
  "creatividad",
  "cueing",
];

const UserAnswerFeedbackData = ({ canEdit, questionAnswer }) => {
  const { setUserAnswer } = useContext(AttempsContext);

  const handleFeedback = () => {
    setUserAnswer(questionAnswer);
  };

  return (
    <div className="container-fluid">
      {criteria.map((criteria) => (
        <div key={criteria}>
          <h5 className="text-uppercase">{criteria}</h5>
          <div className="mb-3">
            <p>
              <span className="bold">Calificación: </span>
              {questionAnswer[`score_${criteria}`]}
            </p>
            {questionAnswer[`feedback_${criteria}`] === null ? (
              <p>No has agregado feedback</p>
            ) : (
              parse(
                String(questionAnswer[`feedback_${criteria}`]).replaceAll(
                  "\n",
                  "<br/>"
                )
              )
            )}
          </div>
        </div>
      ))}

      {canEdit && (
        <button
          onClick={() => handleFeedback(questionAnswer)}
          className="btn btn-primary d-block"
        >
          <i className="fa fa-edit me-2"></i> Feedback
        </button>
      )}
    </div>
  );
};

export default UserAnswerFeedbackData;
