import React, { useContext, useEffect } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import ProductCard from "../products/ProductCard";

const GridCourses = ({ mode, location }) => {
  const { products, getProducts } = useContext(ProductsContext);

  useEffect(() => {
    getProducts({
      mode,
      location,
    });
  }, [mode, location]);

  const renderPrograms = () => {
    if (Array.isArray(products)) {
      let productsRender = [...products];
      return productsRender.map((product) => (
        <div key={product.product_id} className="col-12 col-xl-6 my-5">
          <ProductCard mode={mode} product={product} location={location} />
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="row justify-content-center">{renderPrograms()}</div>;
};

export default GridCourses;
