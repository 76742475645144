import React from "react";
import { formatMonto, S3_ENDPOINT } from "../../utils";
import StatusBadge from "../global/StatusBadge";
import moment from "moment";

const PurchaseCard = ({ purchase, cancelSubscription }) => {
  const renderActions = () => {
    return (
      <td className="">
        {renderFile()}
        {renderLink()}
        {renderCancel()}
      </td>
    );
  };

  const renderFile = () => {
    if (purchase.course && purchase.course !== null) {
      if (
        purchase.course.downloadable &&
        purchase.course.downloadable !== null
      ) {
        return (
          <a
            href={`${S3_ENDPOINT}/${purchase.course.downloadable.name}.${purchase.course.downloadable.type}`}
            className="btn btn-sm btn-outline-secondary"
          >
            <i className="fa fa-file"></i>
          </a>
        );
      }
    }
  };

  const renderLink = () => {
    if (purchase.course && purchase.course !== null) {
      if (purchase.course.file_url && purchase.course.file_url !== null) {
        return (
          <a
            target="_blank"
            href={purchase.course.file_url}
            className="btn btn-sm btn-outline-secondary"
          >
            <i className="fa fa-link"></i>
          </a>
        );
      }
    }
  };

  const renderCancel = () => {
    if (purchase.subscription_id !== null) {
      if (purchase.status !== "cancelled" && purchase.status !== "revoked") {
        return (
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => cancelSubscription(purchase)}
          >
            <i className="fa fa-times"></i>
          </button>
        );
      }
    }
  };

  const renderCourse = () => {
    if (purchase.product && purchase.product !== null) {
      return purchase.product.name;
    }
  };

  return (
    <tr>
      <td className="">#{purchase.purchase_id}</td>
      <td className="">{renderCourse()}</td>
      <td className="">
        {"$"}
        {formatMonto(purchase.amount)} {"MXN"}
      </td>
      <td className="">
        {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
      </td>
      <td className="">
        <StatusBadge status={purchase.status} />
      </td>
      {renderActions()}
    </tr>
  );
};

export default PurchaseCard;
