import React from "react";

const ScaleAnswer = ({ question, setAnswer }) => {
  return (
    <div className="row">
      {question.answers.map((answer) => (
        <div key={answer.answer_id} className="col-2 text-center">
          <label>{answer.content}</label>
          <input
            type="radio"
            checked={question.answer === answer.content}
            onChange={(e) => {
              if (e.target.checked) {
                setAnswer(question.question_id, answer.answer_id);
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default ScaleAnswer;
