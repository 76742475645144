import {
  CREATE_TEST,
  TESTS_RECEIVED,
  SET_PROPERTY_TEST,
  SINGLE_TEST_RECEIVED,
} from "../types/tests";

const schema = {
  test_id: "nuevo",
  nombre: "",
  descripcion: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case TESTS_RECEIVED:
      return { ...state, tests: payload };
    case SINGLE_TEST_RECEIVED:
      return { ...state, test: payload };
    case SET_PROPERTY_TEST:
      const { key, value } = payload;
      const test = { ...state.test };
      test[key] = value;
      return { ...state, test };
    case CREATE_TEST:
      return { ...state, test: schema };
    default:
      return { ...state };
  }
};
