import { Link, navigate } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const Loading = () => {
  const { user, signOut } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null) {
      navigate("/mytbm");
    }
  }, [user]);

  return (
    <div className="container-fluid vh-100">
      <div id="loading"></div>
      <div className="row">
        <div className="overlay vh-100"></div>
      </div>
      <div className="row align-items-center pb-5 vh-100 text-white btn-container">
        <div className="container-fluid loading-text text-center">
          <img
            src="https://tbmcerts.s3.us-west-1.amazonaws.com/established.png"
            className="loading-logo mb-5"
          />
          <h4 className="text-white">Accediendo</h4>
          <div className="spinner-border text-white mb-3"></div>
          <p className="mb-0 text-white">
            Si la página no carga en más de 30 segundos
          </p>
          <Link to="/entrar" className="text-white" onClick={signOut}>
            Haz click aquí
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Loading;
