import React, { useContext } from "react";
import { Router } from "@reach/router";
import { AuthContext } from "../context/AuthContext";
import MiInformacion from "./MiInformacion";
import MisMetodos from "./MisMetodos";
import MobileMenu from "../components/common/MobileMenu";
import PanelNavbar from "../components/global/PanelNavbar";
import Footer from "../components/global/Footer";
import Purchases from "./Purchases";
import Invoices from "./Invoices";
import SingleCourse from "./SingleCourse";
import PanelHome from "./PanelHome";
import CourseAccesses from "./CourseAccesses";
import SingleAttempt from "./SingleAttempt";

const Panel = () => {
  const { user, signOut } = useContext(AuthContext);

  return (
    <div className="container-fluid px-0">
      <MobileMenu signOut={signOut} user={user} />
      <PanelNavbar user={user} signOut={signOut} />
      <div className="content overflow-x-hidden">
        <Router>
          <SingleAttempt path="/attempt/:attempt_id/*" />
          <SingleCourse path="/course/:course_id/*" />
          <MiInformacion path="/informacion" />
          <CourseAccesses path="/accesos" />
          <Purchases path="/membresias" />
          <MisMetodos path="/metodos" />
          <Invoices path="/pagos" />
          <PanelHome path="/" />
        </Router>
      </div>
      <Footer />
    </div>
  );
};

export default Panel;
