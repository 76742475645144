import api from "./api";
import { getArgs } from "../utils";

const route = "/products";

const ProductsService = {
  getProducts: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleProduct: (product_id) => api.get(`${route}/${product_id}`),
};
export default ProductsService;
