import React from "react";
import "./progressbar.css";

const ProgressBar = ({ value }) => {
  return (
    <div
      className="container-fluid px-0"
      style={{ backgroundColor: "white", borderRadius: "20px" }}
    >
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar bg-primary"
          role="progressbar"
          style={{ width: `${value}%`, height: "10px" }}
          aria-valuenow={value}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
