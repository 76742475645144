import React from "react";
import { Link } from "@reach/router";

const CheckoutHeader = () => {
  return (
    <div className="row bg-black text-white border-bottom align-items-center pb-3 pt-3">
      <div className="container-fluid">
        <div className="container px-2">
          <div className="row align-items-center">
            <div className="col-8 col-md-10">
              <h1 className="mb-0">Checkout</h1>
            </div>
            <div className="col-4 col-md-2 text-end">
              <Link to="/">
                <img
                  src="https://tbmcerts.s3.us-west-1.amazonaws.com/established.png"
                  className="logo-navbar"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
