import React from "react";

const BulletList = ({ items, item_name }) => {
  const renderItems = () => {
    if (items && items !== null) {
      return items.map((item, index) => (
        <li key={index}>
          {item_name && (
            <span className="bold">
              {item_name} #{index + 1}:
            </span>
          )}{" "}
          {item}
        </li>
      ));
    }
  };

  return (
    <div className="container-fluid" data-aos="fade-up">
      <div className="position-relative">
        <ul>{renderItems()}</ul>
      </div>
    </div>
  );
};

export default BulletList;
