import {
  CREATE_MODULO,
  MODULOS_RECEIVED,
  SET_MODULO,
  SET_PROPERTY_MODULO,
  SET_SECTION_TEST,
} from "../types/modules";

const schema = {
  section_id: "nuevo",
  name: "",
  description: "",
};

export default (state, { type, payload }) => {
  switch (type) {
    case SET_SECTION_TEST:
      return { ...state, test: payload };
    case CREATE_MODULO:
      let sections = state.sections;
      if (sections === null) sections = [];
      const current = { ...schema, section_id: `nuevo-${sections.length}` };
      return { ...state, sections: [...sections, current], section: current };
    case MODULOS_RECEIVED:
      return { ...state, sections: payload };
    case SET_MODULO:
      return { ...state, section: payload };
    case SET_PROPERTY_MODULO:
      const section = { ...state.section };
      const { key, value } = payload;
      section[key] = value;
      return { ...state, section };
    default:
      return { ...state };
  }
};
