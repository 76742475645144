import React from "react";
import CoursesFeatures from "../components/courses/CoursesFeatures";
import GridCourses from "../components/home/GridCourses";
import { Grid } from "@material-ui/core";
import Navbar from "../components/global/Navbar";
import Footer from "../components/global/Footer";
import "../css/SingleCurso.css";
import CoursesHeader from "../components/courses/CoursesHeader";

const features = [
  "Aprende a tu propio ritmo y en los horarios que prefieras",
  "Certifícate desde cualquier parte del mundo.",
  "Soporte en línea con nuestras master coaches.",
];

const OnDemandCourses = () => {
  return (
    <div>
      <Navbar />
      <CoursesHeader />
      <div className="h-100 py-4">
        <Grid className="justify-content-center align-items-center">
          <div className="d-flex flex-column-reverse flex-lg-column w-100">
            <h1 className="text-uppercase h2 text-center">
              CERTIFICACIONES ON DEMAND
            </h1>
            <div className="separator my-3"></div>
          </div>
          <p className="text-center large w-100">
            Estas clases están pre-grabadas en la plataforma para ver donde
            quieras, cuando quieras y cuántas veces quieras.
          </p>
          <CoursesFeatures features={features} />
          <Grid
            item
            xs={12}
            className="gridCertificaciones mb-5 justify-content-center align-items-center"
          >
            <Grid item xs={12} className="px-3 px-xl-5 mb-5">
              <GridCourses mode="on-demand" />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default OnDemandCourses;
