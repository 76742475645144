import React from "react";
import { S3_ENDPOINT, downloadFile } from "../utils";

const QuizCertificate = ({ test, type, attempt }) => {
  const renderCertificate = () => {
    const { certificate } = attempt;
    if (certificate && certificate !== null && test && test !== null) {
      const src = `${S3_ENDPOINT}/${certificate.name}.${certificate.type}`;
      const fileName = `Certificado-${test.course.name}.pdf`;
      return (
        <button
          onClick={() => downloadFile(src, fileName)}
          className="btn btn-primary shadow mt-4"
        >
          <i className="fa fa-download me-2"></i> Descargar{" "}
          {type && type !== null ? type : "Certificado"}
        </button>
      );
    }
    return (
      <p className="mt-4">
        Tu {type && type !== null ? type : "Certificado"} digital esta próximo a
        subirse.
      </p>
    );
  };

  const renderTracking = () => {
    const { tracking_number } = attempt;
    if (tracking_number && tracking_number !== null) {
      return (
        <a href={tracking_number} target="_blank" className="btn mt-4 btn-link">
          <i className="fa fa-truck me-2"></i> Rastrear Envío de{" "}
          {type && type !== null ? type : "Certificado"} Físico
        </a>
      );
    }
    if (test.course_id !== null) {
      return (
        <p className="mt-4">
          Tu {type && type !== null ? type : "Certificado"} físico esta próximo
          a enviarse.
        </p>
      );
    }
  };
  return (
    <div className="row">
      <div className="col-12 col-md-6 text-right">{renderCertificate()}</div>
      <div className="col-12 col-md-6 text-left">{renderTracking()}</div>
    </div>
  );
};

export default QuizCertificate;
