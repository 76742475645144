import React from "react";

const MultipleChoiceAnswer = ({
  question,
  answer,
  setAnswer,
  user_answers,
}) => {
  return (
    <div
      key={answer.answer_id}
      className="row my-4"
      onClick={() => {
        setAnswer(question.question_id, answer.content);
      }}
    >
      <div className="col-1">
        <input
          type="radio"
          checked={
            user_answers.find(({ content }) => content === answer.content) !==
            undefined
          }
          onChange={(e) => {
            setAnswer(question.question_id, answer.content);
          }}
        />
      </div>
      <div className="col-11">
        <label>{answer.content}</label>
      </div>
    </div>
  );
};

export default MultipleChoiceAnswer;
