import React, { useContext, useEffect } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import Accordion from "../home/Accordion";
import { ModalContext } from "../../context/ModalContext";

const ProductContent = ({ product_id }) => {
  const { product, clearProduct, getSingleProduct } =
    useContext(ProductsContext);
  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    getSingleProduct(product_id);
    return handleClear;
  }, [product_id]);

  const handleClear = () => {
    clearModal();
    clearProduct();
  };

  const getItems = (sections) => {
    const items = sections.map(({ name, lessons }) => ({
      title: name,
      htmlContent: `<ol>${lessons
        .map((lesson) => `<li>${lesson.name}</li>`)
        .join("")}</ol>`,
    }));
    return items;
  };

  const renderContent = () => {
    if (product && product !== null) {
      const { course_products } = product;
      if (Array.isArray(course_products)) {
        return course_products
          .filter(({ course_id }) => course_id !== 4)
          .map(({ course }) => {
            return (
              <div className="mb-3" key={course.course_id}>
                <h3>{course.name}</h3>
                <Accordion items={getItems(course.sections)} />
              </div>
            );
          });
      }
    }
  };

  return (
    <div>
      {renderContent()}
      <div className="row mt-3">
        <div className="col-6" />
        <div className="col-6">
          <button onClick={handleClear} className="btn btn-primary w-100">
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductContent;
