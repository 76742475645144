import React, { useState, useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import { NotesContext } from "../../context/NotesContext";
import { SectionsContext } from "../../context/SectionsContext";
import { LessonsContext } from "../../context/LessonsContext";
import { CoursesContext } from "../../context/CoursesContext";
import moment from "moment";
import { saveContentToWord } from "../../utils";

const LessonNotes = () => {
  const { notes, getNotes, saveNote } = useContext(NotesContext);
  const { clearModal } = useContext(ModalContext);
  const { section } = useContext(SectionsContext);
  const { lesson } = useContext(LessonsContext);
  const { course } = useContext(CoursesContext);
  const [content, setContent] = useState("");

  useEffect(() => {
    fetchNotes();
  }, []);

  const handleSave = () => {
    saveContentToWord("notes");
  };

  const fetchNotes = () => {
    if (lesson && lesson !== null) {
      getNotes({ lesson_id: lesson.lesson_id });
    } else if (section && section !== null) {
      getNotes({ section_id: section.section_id });
    } else {
      getNotes({ course_id: course.course_id });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentNote = { content };
    if (lesson && lesson !== null) {
      currentNote.lesson_id = lesson.lesson_id;
    } else if (section && section !== null) {
      currentNote.section_id = section.section_id;
    } else {
      currentNote.course_id = course.course_id;
    }
    saveNote(currentNote, fetchNotes);
  };

  const renderNotes = () => {
    if (Array.isArray(notes)) {
      if (notes.length === 0) {
        return <p>No has agregado ninguna nota.</p>;
      }
      return notes.map(({ createdAt, content }) => (
        <div className="bg-light py-2 px-3">
          <p className="mb-1">{content}</p>
          <p className="mb-0 text-muted small">
            {moment(createdAt).format("DD MMM YYYY HH:mm")}
          </p>
        </div>
      ));
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="row align-items-center mb-3">
        <div className="col-12 col-md-8">
          <textarea
            rows="2"
            value={content}
            className="form-control mb-2"
            placeholder="Escribe tus notas"
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-4">
          <div className="row">
            <div className="col-12 col-md-6">
              <button
                type="button"
                onClick={clearModal}
                className="btn px-3 btn-sm btn-outline-dark w-100"
              >
                Cancelar
              </button>
            </div>
            <div className="col-12 col-md-6">
              <button className="btn px-3 btn-sm btn-primary w-100">
                Agregar
              </button>
            </div>
          </div>
        </div>
      </form>
      <div id="notes">{renderNotes()}</div>
      <button
        type="button"
        onClick={handleSave}
        className="btn px-3 mt-3 btn-sm btn-outline-accent"
      >
        <i className="fa fa-download me-2"></i>Descargar
      </button>
    </div>
  );
};

export default LessonNotes;
