import React, { useContext } from "react";
import { getValue } from "../../utils";
import { AddressContext } from "../../context/AddressContext";

const AddressForm = ({
  title,
  saveAction,
  hideButtons,
  handleCancel,
  handleCallback,
}) => {
  const { address, postAddress, setPropertyAddress } =
    useContext(AddressContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (typeof saveAction === "function") {
      return saveAction(address);
    }
    postAddress(address, handleCallback);
  };

  const renderButtons = () => {
    if (!hideButtons) {
      return (
        <div className="row mt-3">
          <div className="col">
            <button type="submit" className="btn btn-primary w-100">
              Guardar
            </button>
          </div>
          {handleCancel && (
            <div className="col">
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-link text-muted w-100 px-0"
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <form className="row" onSubmit={handleSubmit}>
      {title && <h2>{title}</h2>}
      <div className="col-md-6 mb-2">
        <label htmlFor="street" className="form-label">
          Calle
        </label>
        <input
          type="text"
          id="text"
          name="street"
          className="form-control"
          value={getValue(address, "street")}
          onChange={(event) => setPropertyAddress("street", event.target.value)}
          required
        />
      </div>
      <div className="col-md-3 col-6 mb-2">
        <label htmlFor="number" className="form-label">
          Número
        </label>
        <input
          type="text"
          id="number"
          name="number"
          className="form-control"
          value={getValue(address, "number")}
          onChange={(event) => setPropertyAddress("number", event.target.value)}
          required
        />
      </div>
      <div className="col-md-3 col-6 mb-2">
        <label htmlFor="interiorNumber" className="form-label">
          Interior
        </label>
        <input
          type="text"
          id="interiorNumber"
          name="interiorNumber"
          className="form-control"
          value={getValue(address, "interior_number")}
          onChange={(event) =>
            setPropertyAddress("interior_number", event.target.value)
          }
        />
      </div>

      <div className="col-12 mb-2">
        <label htmlFor="neighborhood" className="form-label">
          Colonia
        </label>
        <input
          type="text"
          id="neighborhood"
          name="neighborhood"
          className="form-control"
          value={getValue(address, "neighborhood")}
          onChange={(event) =>
            setPropertyAddress("neighborhood", event.target.value)
          }
          required
        />
      </div>
      <div className="col-12 mb-2">
        <label htmlFor="city" className="form-label">
          Ciudad
        </label>
        <input
          type="text"
          id="city"
          name="city"
          className="form-control"
          value={getValue(address, "city")}
          onChange={(event) => setPropertyAddress("city", event.target.value)}
          required
        />
      </div>
      <div className="col-md-4 col-6 mb-2">
        <label htmlFor="zip_code" className="form-label">
          Código Postal
        </label>
        <input
          type="text"
          id="zip_code"
          name="zip_code"
          className="form-control"
          value={getValue(address, "zip_code")}
          onChange={(event) =>
            setPropertyAddress("zip_code", event.target.value)
          }
          required
        />
      </div>
      <div className="col-md-4 col-6 mb-2">
        <label htmlFor="state" className="form-label">
          Estado
        </label>
        <input
          type="text"
          id="state"
          name="state"
          className="form-control"
          value={getValue(address, "state")}
          onChange={(event) => setPropertyAddress("state", event.target.value)}
          required
        />
      </div>
      <div className="col-md-4 col-12 mb-2">
        <label htmlFor="country" className="form-label">
          País
        </label>
        <input
          type="text"
          id="country"
          name="country"
          className="form-control"
          value={getValue(address, "country")}
          onChange={(event) =>
            setPropertyAddress("country", event.target.value)
          }
          required
        />
      </div>
      <div className="col-12">{renderButtons()}</div>
    </form>
  );
};

export default AddressForm;
