import api from "./api";

const route = "/checkout";

const CheckoutService = {
  postCheckout: (payload) => api.post(route, { ...payload }),
  postPayPal: (payload) => api.post(`${route}/paypal`, { ...payload }),
  capturePayPal: (data) => api.post(`${route}/capture`, { ...data }),
};

export default CheckoutService;
