import React, { useContext, useEffect } from "react";
import FlipCard from "../home/FlipCard";
import { ProductsContext } from "../../context/ProductsContext";

const CoursesLanding = ({ hideTitle }) => {
  const { getProducts } = useContext(ProductsContext);

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div id="courses" className="bg-white text-center">
      {!hideTitle && (
        <h2 className="bg-dark text-primary py-5">
          CONVIÉRTETE EN INSTRUCTORA
        </h2>
      )}
      {/*<CourseGrid mode={mode} landing={true} />*/}
      <div className="row">
        <div className="col-12 col-md-6">
          <FlipCard
            title="ON-DEMAND"
            link="/ondemand"
            src="ondemand.JPG"
            mode="on-demand"
            description="Vive la experiencia a tu propio ritmo y ten la facilidad de elegir tus propios horarios de estudio."
            short_description="Aprende en línea de una forma práctica y divertida."
          />
        </div>
        <div className="col-12 col-md-6">
          <FlipCard
            title="LIVE"
            link="/live"
            src="live.JPG"
            mode="live"
            description="Recibe retroalimentación personalizada, resuelve tus dudas de manera directa y disfruta de una experiencia increíble."
            short_description="Toma un fin de semana intensivo con nuestras master coaches."
          />
        </div>
      </div>
    </div>
  );
};

export default CoursesLanding;
