import React, { useContext, useEffect } from "react";
import { AttempsContext } from "../context/AttemptsContext";
import { CoursesContext } from "../context/CoursesContext";
import QuizQuestion from "./QuizQuestion";
import QuizResult from "./QuizResult";

const SingleAttempt = ({ attempt_id }) => {
  const {
    update,
    spinner,
    attempt,
    answers,
    question,
    questions,
    setAnswer,
    setUpdate,
    setQuestion,
    clearAttempt,
    saveUserAnswer,
    getSingleAttempt,
  } = useContext(AttempsContext);
  const { getCourse } = useContext(CoursesContext);

  useEffect(() => {
    clearAttempt();
    getSingleAttempt(attempt_id);
    return clearAttempt;
  }, [attempt_id]);

  useEffect(() => {
    if (update) {
      getSingleAttempt(attempt_id);
      setUpdate(false);
    }
  }, [update]);

  useEffect(() => {
    if (question === null) {
      handleQuestion();
    }
  }, [questions, answers]);

  useEffect(() => {
    if (attempt !== null) {
      if (attempt.test && attempt.test !== null) {
        const { section, course } = attempt.test;
        if (section && section !== null) {
          getCourse(section.course_id);
        } else if (course && course !== null) {
          getCourse(course.course_id);
        }
      }
    }
  }, [attempt]);

  const handleCallback = () => getSingleAttempt(attempt_id);

  const handleQuestion = () => {
    if (Array.isArray(questions) && Array.isArray(answers)) {
      let nextQuestion = questions.find(
        (question) =>
          !answers.find(
            ({ question_id }) => question_id === question.question_id
          )
      );
      if (!nextQuestion && questions.length > 0) {
        nextQuestion = questions[questions.length - 1];
      }
      if (nextQuestion) setQuestion(nextQuestion);
    }
  };

  const handleQuestionOrder = (order) => {
    let nextQuestion = questions.find(
      (question) => parseInt(question.order) === parseInt(order)
    );
    if (nextQuestion) setQuestion(nextQuestion);
  };

  const renderQuestion = () => {
    if (attempt && attempt !== null) {
      if (attempt.finishedAt !== null) {
        return <QuizResult />;
      }
    }
    if (question && question !== null && Array.isArray(questions)) {
      return (
        <QuizQuestion
          spinner={spinner}
          question={question}
          questions={questions}
          setAnswer={setAnswer}
          user_answers={answers}
          attempt_id={attempt_id}
          handleCallback={handleCallback}
          saveUserAnswer={saveUserAnswer}
          handleQuestionOrder={handleQuestionOrder}
        />
      );
    }
  };

  return <div>{renderQuestion()}</div>;
};

export default SingleAttempt;
