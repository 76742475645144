import React from "react";
import { navigate } from "@reach/router";
import FinalExamTarget from "./FinalExamTarget";

const ContinueFinalExam = ({ course_id, attempt_id }) => {
  return (
    <div className="bg-accent text-white p-4">
      <FinalExamTarget
        course_id={course_id}
        buttonTitle="Continuar Examen"
        handleClick={() => navigate(`/mytbm/attempt/${attempt_id}`)}
      >
        <h3>Completa tu Examen Final</h3>
        <p>Aún no has cargado todos tus videos.</p>
      </FinalExamTarget>
    </div>
  );
};

export default ContinueFinalExam;
