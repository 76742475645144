import React from "react";

const CoursesHeader = () => {
  return (
    <div className="row bg-dark mt-5 py-4">
      <div className="text-center text-primary">
        <h2 className="font-style-italic">
          <span className="h3 bold">EMPRENDE TU NEGOCIO</span>{" "}
          <span className="h1 bold">HACIENDO LO QUE AMAS</span>
        </h2>
      </div>
    </div>
  );
};
export default CoursesHeader;
