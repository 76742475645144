import React from "react";

const CheckboxAnswer = ({ answer, question, setAnswer, user_answers }) => {
  return (
    <div className="d-flex justify-content-start align-items-center  mt-3">
      <input
        className="check"
        type="radio"
        checked={
          Array.isArray(user_answers)
            ? user_answers.find(({ content }) => answer.content === content)
            : false
        }
        onChange={(e) => {
          if (e.target.checked) {
            let current;
            if (Array.isArray(user_answers)) {
              current = [...user_answers, answer.content];
            } else {
              current = [answer.answer_id];
            }
            setAnswer(question.question_id, current);
          } else {
            if (Array.isArray(user_answers)) {
              let current = [
                ...user_answers.filter((option) => option !== answer.answer_id),
              ];
              setAnswer(question.question_id, current);
            }
          }
        }}
      ></input>
      <span className="ps-3">{answer.content}</span>
    </div>
  );
};

export default CheckboxAnswer;
