import { INVOICES_RECEIVED, ORDENES_RECEIVED, SET_ORDEN } from "../types";

const OrdenesReducer = (state, { type, payload }) => {
  switch (type) {
    case ORDENES_RECEIVED:
      return { ...state, purchases: payload };
    case SET_ORDEN:
      return { ...state, purchase: payload };
    case INVOICES_RECEIVED:
      return { ...state, invoices: payload };
    default:
      return { ...state };
  }
};

export default OrdenesReducer;
