import React, { useState, useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { S3_ENDPOINT } from "../../utils";

const LessonFiles = ({ lesson }) => {
  const [files, setFiles] = useState("");

  useEffect(() => {
    if (lesson !== null && files === null && Array.isArray(lesson.media)) {
      setupFiles();
    }
  }, [lesson]);

  const setupFiles = () => {
    const archivos = lesson.media.filter(
      (mult) => mult.file !== null && mult.file
    );
    const filesToRender = [];
    archivos.forEach((mult) => {
      filesToRender.push(
        <DocViewer
          key={mult.idMultimedia}
          documents={[
            {
              uri: `${S3_ENDPOINT}/${mult.file.name}.${mult.file.type}`,
              fileType: "pdf",
            },
          ]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableFileName: true,
            },
          }}
        />
      );
    });
    setFiles(filesToRender);
  };
  return <div>{files}</div>;
};

export default LessonFiles;
