import React from "react";

const ThankYou = ({ product_title }) => {
  return (
    <div
      className="container text-center p-5 my-5 shadow-sm card border-success shadow-success"
      data-aos="fade-up"
    >
      <h2 className="bold text-success mb-3">Resumen</h2>
      <p>Recibirás un correo electrónico con instrucciones para acceder a</p>
      <h4 className="bold mb-0">{product_title}</h4>
    </div>
  );
};

export default ThankYou;
