import React from "react";

const WhatsApp = () => {
  return (
    <a
      href="https://wa.me/528125159100"
      target="_blank"
      className="btn btn-success p-2 btn-whatsapp"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
};
export default WhatsApp;
