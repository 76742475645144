import React from "react";

const LessonActions = () => {
  return (
    <div className="container px-0">
      <button className="btn btn-light me-2">
        <i className="fas fa-star"></i> Calificar
      </button>
      <button className="btn btn-light me-2">
        <i className="fas fa-heart"></i> Favorito
      </button>
    </div>
  );
};

export default LessonActions;
