import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { AddressContext } from "../context/AddressContext";
import AddressForm from "../components/address/AddressForm";
import AddressData from "../components/address/AddressData";

const UserAddress = () => {
  const [editMode, setEditMode] = useState(false);
  const { setAddress } = useContext(AddressContext);
  const { user, getUsuario, updateUsuario } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    updateUsuario(user);
  };

  const handleCancel = () => {
    setEditMode(false);
    getUsuario();
  };

  const handleEdit = () => {
    setEditMode(true);
    setAddress(user.address);
  };

  const renderInformacion = () => {
    if (user && user !== null) {
      if (editMode) {
        return (
          <AddressForm
            user={user}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleCallback={handleCancel}
          />
        );
      }
      return <AddressData address={user.address} handleEdit={handleEdit} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="card my-4 p-4 shadow">
      <h4 className="border-bottom pb-2 mb-2">Dirección</h4>
      {renderInformacion()}
    </div>
  );
};

export default UserAddress;
