import React, { useContext } from "react";
import { LessonsContext } from "../../context/LessonsContext";

const ChapterCard = ({ selected, disabled, lesson }) => {
  const { selectLesson } = useContext(LessonsContext);
  return (
    <div
      onClick={() => {
        if (!disabled) selectLesson(lesson);
      }}
      style={{ opacity: disabled ? 0.3 : 1 }}
      className={`${
        lesson.completed.length > 0
          ? "bg-primary text-white"
          : selected !== null && selected.lesson_id === lesson.lesson_id
          ? "bg-dark text-white"
          : "bg-white text-dark"
      } ${
        !disabled ? "hover-blue" : ""
      } shadow d-flex text-uppercase justify-content-between p-3 align-items-center mb-3 me-2`}
    >
      <i className="fa fa-check"></i>
      <div className="ps-2 ps-lg-0 d-flex">
        <span className="d-lg-flex d-md-flex">{lesson.name}</span>{" "}
        <span className="ps-lg-2 ps-4">{lesson.number}</span>{" "}
      </div>
      <i className="fa fa-chevron-right d-lg-flex"></i>
    </div>
  );
};

export default ChapterCard;
