import { navigate } from "@reach/router";
import React, { useContext, useEffect } from "react";
import SignUpForm from "../components/auth/SignUpForm";
import { AuthContext } from "../context/AuthContext";

const SignUp = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null && !window.location.href.includes("mytbm")) {
      navigate("mytbm");
    }
  }, [user]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 vh-100 px-0 hide-mobile bg-dark">
          <div className="bg-vertical"></div>
        </div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <h1 className="text-center my-4">¡Regístrate!</h1>
              <SignUpForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
