import React from "react";
import { getValue } from "../utils";
import img from "../assets/images/sad.png";
import QuizFeedback from "./QuizFeedback";
import QuizCertificate from "./QuizCertificate";
import FinalExamFailed from "../components/attempts/FinalExamFailed";
import { Link } from "@reach/router";

const FailedExamResult = ({ attempt, questions, answers, test }) => {
  const renderVideosButton = () => {
    if (attempt.test.course_id !== null) {
      if (getValue(attempt, "requested_diploma", true)) {
        return (
          <div className="container text-center">
            <p>Elegiste recibir un diploma de participación</p>
            <QuizCertificate type="diploma" attempt={attempt} test={test} />
          </div>
        );
      }
      if (
        parseInt(attempt.test.attempts.length) <=
        parseInt(attempt.test.max_attempts)
      ) {
        if (
          !getValue(attempt, "requested_diploma", true) &&
          attempt.requested_diploma !== null
        ) {
          return (
            <div className="text-center">
              <p>
                Elegiste volver a intentar esta certificación. Haz click en el
                botón para ver los módulos.
              </p>
              <Link
                to={`/mytbm/course/${test.course_id}`}
                className="btn btn-primary"
              >
                Ver Módulos
              </Link>
            </div>
          );
        }
        return <FinalExamFailed attempt={attempt} />;
      }
      return (
        <div className="container text-center">
          <p>Lo sentimos, ya no puedes repetir tu examen final.</p>
          <p>
            Recibirás un diploma por partipar en la certificación de{" "}
            {test.course.name}
          </p>
          <QuizCertificate type="diploma" attempt={attempt} test={test} />
        </div>
      );
    }

    return (
      <div className="d-flex h-100 flex-column justify-content-center col-lg-5 col-12">
        <button
          onClick={() => window.history.back()}
          className="btn shadow bg-dark btn-quiz"
        >
          VER VIDEOS NUEVAMENTE
        </button>
      </div>
    );
  };

  const renderMessage = () => {
    if (attempt.test.course_id === null) {
      return (
        <span className="text-center  bold">
          Es una lastima no conseguiste los puntos necesarios para pasar el
          examen, pero no te desanimes, puedes volver a ver los videos del
          capitulo y presentar de nuevo el examen.
        </span>
      );
    }
  };

  return (
    <div className="col-12 ps-5 px-5 mt-5 mb-5 d-flex flex-column align-items-center">
      <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12">
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12">
          <img src={img} className="d-block m-auto" style={{ width: 100 }} />
        </div>
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12 mt-3 mb-3">
          <span className="bold text-center text-accent h1">
            No pasaste el examen
          </span>
        </div>
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12 mb-5 mt-3 ps-5 px-5">
          {renderMessage()}
        </div>
        {renderVideosButton()}
      </div>
      <QuizFeedback questions={questions} answers={answers} />
    </div>
  );
};

export default FailedExamResult;
