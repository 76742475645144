import { Link } from "@reach/router";
import React, { useState } from "react";

const SectionCard = ({ section }) => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleCardToggle = (index, courseId) => {
    if (expandedCard === index) {
      setExpandedCard(null);
    } else {
      setExpandedCard(index);
    }
  };

  const renderLessons = () => {
    if (Array.isArray(section.lessons)) {
      return section.lessons
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((lesson, index) => (
          <span key={index} className="ps-2">
            <i className="fa fa-check me-2"></i>
            {lesson.name}
          </span>
        ));
    }
  };

  const renderDescription = () => {
    const { description } = section;
    if (description && description !== null) {
      return <p className="ps-2 px-2">{description}</p>;
    }
  };

  const renderContent = () => {
    if (expandedCard) {
      return (
        <div className="row mt-3">
          <hr className="ps-5 px-3 text-center"></hr>
          {renderDescription()}
          <div className="d-flex flex-column mb-3">{renderLessons()}</div>
        </div>
      );
    }
  };

  return (
    <div className="col-lg-4 col-md-6 col-12 mb-0">
      <div
        className={`${
          section.completed.length > 0 ? "bg-primary text-white" : "bg-white"
        } p-3 p-lg-4 mb-0 shadow-lg ${expandedCard === 1 ? "expanded" : ""}`}
      >
        <div className="row align-items-center">
          <div className="col-12 col-lg-8 col-xl-8">
            <h3 className="h4">
              {section.completed.length > 0 && (
                <i className="fa fa-check-circle me-2"></i>
              )}
              {section.name}
            </h3>
            <button
              onClick={() => handleCardToggle(1)}
              className="btn px-1 py-0"
            >
              <i
                className={`fa fa-chevron-${
                  expandedCard === 1 ? "up" : "down"
                } me-2`}
                aria-hidden="true"
              ></i>
              <span style={{ fontWeight: 400 }} className="small">
                Contenido
              </span>
            </button>
          </div>
          <div className="col-12 col-lg-4 col-xl-4 ps-0 text-end">
            <Link
              to={`./sections/${section.section_id}`}
              className={`btn btn-${
                section.completed.length > 0 ? "dark" : "primary"
              }`}
            >
              IR
              {/*<button style={{ height: "80%", width: "110px" }}>IR</button>*/}
            </Link>
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default SectionCard;
