import React, { useContext } from "react";
import { S3_ENDPOINT } from "../../utils";
import { CoursesContext } from "../../context/CoursesContext";

const MaterialHelp = () => {
  const { course } = useContext(CoursesContext);

  const renderMaterials = () => {
    if (course && course !== null) {
      if (Array.isArray(course.resources)) {
        return course.resources.map((resource) => (
          <div key={resource.file_id} className="col-12 col-lg-4 col-md-12">
            <a
              href={
                resource.file && resource.file !== null
                  ? `${S3_ENDPOINT}/${resource.file.name}.${resource.file.type}`
                  : resource.src
              }
              target="_blank"
              className="btn no-decoration btn-outline-accent w-100"
            >
              <span className="text-center text-uppercase w-100">
                DESCARGAR {resource.type} {resource.name}
              </span>
              <i className="fa fa-arrow-circle-right ms-3"></i>
            </a>
          </div>
        ));
      }
    }
  };

  return (
    <div className="container-fluid px-5 my-4">
      <div className="d-flex flex-column align-items-center">
        <h3>Material de ayuda</h3>
        <div
          className="my-3 border-bottom border-dark"
          style={{ width: "50px" }}
        ></div>
      </div>
      <div className="row justify-content-center align-items-center">
        {renderMaterials()}
      </div>
    </div>
  );
};

export default MaterialHelp;
