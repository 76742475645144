import React, { useState, useContext } from "react";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { validateEmail, validatePhoneNumber } from "../../utils";

const SignUpForm = ({ setLogin }) => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [otro, setOtro] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [telefono, setTelefono] = useState("");

  const { spinner, signUp } = useContext(AuthContext);

  const { alert } = useContext(ModalContext);

  const renderOtro = () => {
    if (reason === "Otro") {
      return (
        <div>
          <label>Cuéntanos cómo te enteraste</label>
          <input
            type="text"
            className="form-control mb-3"
            value={otro}
            onChange={(e) => setOtro(e.target.value)}
          />
        </div>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nombre === "") {
      return alert("Debes ingresar tu nombre.");
    }
    if (apellidos === "") {
      return alert("Debes ingresar tus apellidos.");
    }
    if (!validateEmail(email)) {
      return alert("El correo electrónico no es válido.");
    }
    if (String(password).length < 6) {
      return alert("La contraseña debe tener al menos 6 caracteres.");
    }
    if (!validatePhoneNumber(telefono)) {
      return alert("Debes ingresar un número de teléfono válido.");
    }
    if (password !== passwordConfirm) {
      return alert("Las contraseñas no coinciden");
    }
    if (reason === "Otro" && otro === "") {
      return alert("Debes completar el campo de cómo te enteraste de TBM");
    }
    signUp(
      nombre,
      apellidos,
      email,
      password,
      telefono,
      reason !== "Otro" ? reason : otro
    );
  };
  return (
    <>
      <div id="login-card" className="card no-scale text-left shadow p-4">
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
          <label>Apellidos</label>
          <input
            type="text"
            className="form-control mb-3"
            value={apellidos}
            onChange={(e) => setApellidos(e.target.value)}
          />
          <label>Correo Electrónico</label>
          <input
            type="email"
            className="form-control mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Contraseña</label>
          <input
            type="password"
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Confirmar Contraseña</label>
          <input
            type="password"
            className="form-control mb-3"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <label>Teléfono (WhatsApp)</label>
          <input
            type="tel"
            className="form-control mb-3"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
          />
          <label>¿Cómo te enteraste de TBM?</label>
          <select
            value={reason}
            className="form-control"
            onChange={(e) => setReason(e.target.value)}
          >
            <option>Instagram</option>
            <option>Recomendación</option>
            <option>Ví el local</option>
            <option>Ex Alumna</option>
            <option>Otro</option>
          </select>
          <div className="mt-3">{renderOtro()}</div>
          <button type="submit" className="btn btn-primary" value="Entrar">
            {spinner ? <div className="spinner-border"></div> : "Regístrate"}
          </button>
        </form>
        <div className="container-fluid px-0 mt-4">
          ¿Ya tienes cuenta?{" "}
          {typeof setLogin === "function" ? (
            <button
              onClick={setLogin}
              className="btn text-accent px-0 py-0 d-inline-block"
            >
              Inicia Sesión
            </button>
          ) : (
            <Link to="/entrar" className="text-accent">
              Inicia Sesión
            </Link>
          )}
        </div>
      </div>
      <p className="mt-4 mw-500 d-block m-auto">
        <span>¿Necesitas recuperar tu cuenta?</span>{" "}
        <Link to="/recuperar" className="text-accent">
          Haz click aquí
        </Link>
      </p>
    </>
  );
};

export default SignUpForm;
