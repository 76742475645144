// CustomSwitch.js
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const styles = () => ({
  buttonGroup: {
    height: "50px",
    width: "300px",
    borderRadius: "30px",
    backgroundColor: "#d2b6e4",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  button: {
    minWidth: "145px",
    borderRadius: "12px",
    color: "black",
  },
  selectedButton: {
    marginLeft: "10px",
    backgroundColor: "white",
    color: "black",
  },
  unselectedButton: {
    backgroundColor: "transparent",
    color: "white",
  },
});

const CustomSwitch = ({ classes, value, onChange, cursos }) => {
  const handleChange = (newValue) => {
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  return (
    <Grid container alignItems="center" className={classes.buttonGroup}>
      <Grid item>
        <Button
          className={`${classes.button} ${
            value === "studio"
              ? classes.selectedButton
              : classes.unselectedButton
          }`}
          onClick={() => handleChange("studio")}
        >
          PRESENCIAL
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={`${classes.button} ${
            value === "remote"
              ? classes.selectedButton
              : classes.unselectedButton
          }`}
          onClick={() => handleChange("remote")}
        >
          REMOTO
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomSwitch);
