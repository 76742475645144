export const getLocationName = (location) => {
  if (location === "studio") {
    location = "Monterrey, México";
  } else if (location === "remote") {
    location = "Zoom";
  } else if (typeof location === "object") {
    if (location.show_location_name) {
      if (location.address && location.address !== null) {
        location = `${location.name} @ ${location.address.city}, ${location.address.state}`;
      } else {
        location = location.name;
      }
    } else {
      if (location.address && location.address !== null) {
        location = `${location.address.city}, ${location.address.state}`;
      } else {
        location = location.description;
      }
    }
  }
  return location;
};
