import React, { createContext, useReducer } from "react";
import CourseAccessesService from "../services/CourseAccessesService";
import CourseAccessesReducer from "../reducers/CourseAccessesReducer";
import { COURSE_ACCESSES_RECEIVED } from "../types/course_accesses";

const initialState = {
  course_accesses: null,
  course_access: null,
};

export const CourseAccessesContext = createContext(initialState);

export const CourseAccessesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CourseAccessesReducer, initialState);

  const getCourseAccesses = () => {
    CourseAccessesService.getCourseAccesses().then((res) => {
      const { course_accesses } = res.data;
      dispatch({ type: COURSE_ACCESSES_RECEIVED, payload: course_accesses });
    });
  };

  return (
    <CourseAccessesContext.Provider value={{ ...state, getCourseAccesses }}>
      {children}
    </CourseAccessesContext.Provider>
  );
};
