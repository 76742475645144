import React from "react";
import MobileFeatures from "./MobileFeatures";
import DesktopFeatures from "./DesktopFeatures";
import { Link } from "@reach/router";

const LandingFeatures = () => {
  return (
    <div id="features" className="container-fluid">
      <div className="row align-items-start mx-0">
        <div className="col-12 col-lg-4 mb-4">
          <h3 className="h1 mb-5">CUMPLE TUS METAS</h3>
          <p className="large">
            Conoce, aprende y practica todo lo que tienes que saber para
            alcanzar tus objetivos.{" "}
          </p>
          <p className="large mb-5">
            Cambia tu vida dentro y fuera del salón, haciendo lo que amas.
          </p>
          <Link to="/courses" className="btn btn-primary w-100">
            VER CERTIFICACIONES
          </Link>
        </div>
        <div className="col-12 col-lg-8 mb-3">
          <div className="d-lg-none d-md-none">
            {/* <!-- Carousel for mobile devices --> */}
            <MobileFeatures />
          </div>
          <div className="d-none d-md-block d-lg-block">
            <DesktopFeatures />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFeatures;
