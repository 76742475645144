import React, { createContext, useContext, useReducer } from "react";
import {
  CREATE_COURSE,
  COURSES_RECEIVED,
  SET_PROPERTY_COURSE,
  SINGLE_COURSE_RECEIVED,
  URL_DISPONIBLE,
  MISCOURSES_RECEIVED,
} from "../types/courses";
import { navigate } from "@reach/router";
import AdjuntosService from "../services/AdjuntosService";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import CoursesReducer from "../reducers/CoursesReducer";
import CoursesService from "../services/CoursesService";

const initialState = {
  mycourses: null,
  courses: null,
  course: null,
};

export const CoursesContext = createContext(initialState);

export const CoursesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoursesReducer, initialState);

  const { success } = useContext(ModalContext);

  const getCourse = (course_id) => {
    CoursesService.getSingleCourse(course_id).then((res) => {
      const { course } = res.data;
      dispatch({ type: SINGLE_COURSE_RECEIVED, payload: course });
    });
  };

  const getCourses = (filters) => {
    CoursesService.getCourses(filters).then((res) => {
      const { courses } = res.data;
      dispatch({ type: COURSES_RECEIVED, payload: courses });
    });
  };

  const getMyCourses = () => {
    CoursesService.getMyCourses().then((res) => {
      const { courses } = res.data;
      dispatch({ type: COURSES_RECEIVED, payload: courses });
    });
  };

  const createCourse = () => {
    dispatch({ type: CREATE_COURSE });
  };
  const setPropiedadCourse = (key, value) => {
    if (key === "precio" && value < 0) value = Math.abs(value);
    dispatch({ type: SET_PROPERTY_COURSE, payload: { key, value } });
  };

  const postCourse = (course) => {
    course.idCategoria = 1;
    const promises = [];
    if (course.file && course.file !== null) {
      const formData = new FormData();
      formData.append("adjunto", course.file);
      const promise = new Promise((resolve, reject) => {
        AdjuntosService.postAdjunto(formData).then((res) => {
          course.idAdjunto = res.data.idAdjunto;
          resolve();
        });
      });
      promises.push(promise);
    }
    if (course.filePortada && course.filePortada !== null) {
      const formData = new FormData();
      formData.append("adjunto", course.filePortada);
      const promise = new Promise((resolve, reject) => {
        AdjuntosService.postAdjunto(formData).then((res) => {
          course.idAdjunto_portada = res.data.idAdjunto;
          resolve();
        });
      });
      promises.push(promise);
    }
    Promise.all(promises).then(() => {
      let service;
      if (isNaN(course.course_id)) {
        service = CoursesService.postCourse;
      } else {
        service = CoursesService.putCourse;
      }
      service(course).then(() => {
        navigate(`/course/${course.course_id}`);
        success("¡Course guardado con éxito!");
      });
    });
  };

  const eliminarCourse = (course_id) => {
    CoursesService.deleteCourse(course_id).then(() => {
      navigate("../../");
      hideModal();
    });
  };

  const clearCourse = () => {
    dispatch({ type: SINGLE_COURSE_RECEIVED, payload: null });
  };

  const clearCourses = () => {
    dispatch({ type: COURSES_RECEIVED, payload: null });
  };

  const updatePalabraTest = (course_id, palabra) => {
    CoursesService.putPalabraTest(course_id, palabra).then(() => {
      success("¡Palabra actualizada!");
      getCourse(course_id);
    });
  };

  const validarUrl = (course_id, url) => {
    CoursesService.getCourseByUrl(url)
      .then((res) => {
        const { course } = res.data;
        dispatch({
          type: URL_DISPONIBLE,
          payload: course.course_id === course_id,
        });
      })
      .catch(() => {
        dispatch({ type: URL_DISPONIBLE, payload: true });
      });
  };

  return (
    <CoursesContext.Provider
      value={{
        ...state,
        getCourse,
        validarUrl,
        getCourses,
        postCourse,
        clearCourse,
        clearCourses,
        createCourse,
        getMyCourses,
        eliminarCourse,
        setPropiedadCourse,
        updatePalabraTest,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};
