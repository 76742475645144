import React, { useContext, useReducer, createContext } from "react";
import AdjuntosService from "../services/AdjuntosService";
import {
  SET_LESSON,
  CREATE_LESSON,
  ELIMINAR_MULTMEDIA,
  LESSONES_RECEIVED,
  AGREGAR_MULTIMEDIA,
  AGREGAR_DESCARGABLE,
  ELIMINAR_DESCARGABLE,
  SET_PROPERTY_LESSON,
  SET_PROPERTY_MULTIMEDIA,
  SET_PROPERTY_DESCARGABLE,
  SET_LESSON_MEDIA,
  SET_LESSON_TEST,
} from "../types/lessons";
import { hideModal } from "../utils";
import { navigate } from "@reach/router";
import { ModalContext } from "./ModalContext";
import { SHOW_SPINNER, HIDE_SPINNER } from "../types";
import LessonsReducer from "../reducers/LessonsReducer";
import LessonsService from "../services/LessonsService";
import PlaybackService from "../services/PlaybackService";

const initialState = {
  lesson_media: null,
  lessons: null,
  lesson: null,
};

export const LessonsContext = createContext(initialState);

export const LessonsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LessonsReducer, initialState);

  const { success } = useContext(ModalContext);

  const getSingleLesson = (lesson_id) => {
    LessonsService.getLeccion(lesson_id).then((res) => {
      const { lesson } = res.data;
      dispatch({ type: SET_LESSON, payload: lesson });
    });
  };

  const getSiguienteLeccion = (lesson_id, course_id) => {
    LessonsService.getSiguienteLeccion(lesson_id, course_id).then((res) => {
      const { lesson_id, course_id } = res.data;
      if (lesson_id) {
        navigate("../");
        navigate(`/course/${course_id}/lesson/${lesson_id}`);
      } else {
        navigate(`/course/${course_id}`);
      }
    });
  };

  const clearLecciones = () => {
    dispatch({ type: LESSONES_RECEIVED, payload: null });
  };

  const clearLeccion = () => {
    dispatch({ type: SET_LESSON, payload: null });
  };

  const getSectionLessons = (section_id) => {
    LessonsService.getSectionLessons(section_id).then((res) => {
      const { lessons } = res.data;
      dispatch({ type: LESSONES_RECEIVED, payload: lessons });
    });
  };

  const getLecciones = ({ course_id, section_id, topic_id }) => {
    LessonsService.getLecciones({
      course_id,
      section_id,
      topic_id,
    }).then((res) => {
      const { lessons } = res.data;
      dispatch({ type: LESSONES_RECEIVED, payload: lessons });
    });
  };

  const setLessonMedia = (lesson_media) => {
    dispatch({ type: SET_LESSON_MEDIA, payload: lesson_media });
  };

  const setLessonTest = (test) => {
    dispatch({ type: SET_LESSON_TEST, payload: test });
  };

  const selectLesson = (lesson) => {
    dispatch({ type: SET_LESSON, payload: lesson });
    getSingleLesson(lesson.lesson_id, lesson);
  };

  const sortLecciones = (lessons) => {
    lessons = lessons.map((lesson, index) => ({
      ...lesson,
      orden: index + 1,
    }));
    dispatch({ type: LESSONES_RECEIVED, payload: lessons });
  };

  const setPropiedadLeccion = (key, value) => {
    dispatch({ type: SET_PROPERTY_LESSON, payload: { key, value } });
  };

  const createLeccion = () => {
    dispatch({ type: CREATE_LESSON });
  };

  const postLeccion = (lesson) => {
    dispatch({ type: SHOW_SPINNER });
    if (isNaN(lesson.lesson_id)) {
      const promises = [];
      let multimedia = [];
      let descargables = [];
      lesson.multimedia.forEach(({ file }) => {
        if (file && file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              multimedia.push({ idAdjunto });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      lesson.descargables.forEach(({ file }) => {
        if (file && file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              descargables.push({ idAdjunto });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      if (lesson.idAdjunto !== null && typeof lesson.idAdjunto === "object") {
        const promiseAdjunto = new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("adjunto", lesson.idAdjunto);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { idAdjunto } = res.data;
            lesson.idAdjunto = idAdjunto;
            resolve();
          });
        });
        promises.push(promiseAdjunto);
      }
      Promise.all(promises).then(() => {
        lesson.multimedia = multimedia.filter(
          (mult) =>
            (mult.src && mult.src !== "") ||
            (mult.idAdjunto && mult.idAdjunto !== null)
        );
        lesson.descargables = descargables.filter(
          (descargable) =>
            (descargable.src && descargable.src !== "") ||
            (descargable.idAdjunto && descargable.idAdjunto !== null)
        );
        LessonsService.postLeccion(lesson).then(() => {
          dispatch({ type: HIDE_SPINNER });
          navigate("../../");
          success("¡Leccion guardada con éxito!");
        });
      });
    } else {
      const promises = [];
      let multimedia = [];
      let descargables = [];
      lesson.multimedia.forEach((media) => {
        if (media.file && media.file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", media.file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              multimedia.push({ idMultimedia: media.idMultimedia, idAdjunto });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      lesson.descargables.forEach((descargable) => {
        if (descargable.file && descargable.file !== null) {
          const promise = new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("adjunto", descargable.file);
            AdjuntosService.postAdjunto(formData).then((res) => {
              const { idAdjunto } = res.data;
              descargables.push({
                idDescargable: descargable.idDescargable,
                idAdjunto,
              });
              resolve();
            });
          });
          promises.push(promise);
        }
      });
      if (lesson.file && lesson.file !== null) {
        const adjuntoPromise = new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("adjunto", lesson.file);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { idAdjunto } = res.data;
            lesson.idAdjunto = idAdjunto;
            resolve();
          });
        });
        promises.push(adjuntoPromise);
      }
      Promise.all(promises).then(async () => {
        lesson.multimedia = lesson.multimedia
          .filter(
            (mult) =>
              (mult.src && mult.src !== "") ||
              (mult.idAdjunto && mult.idAdjunto !== null)
          )
          .concat(multimedia);
        lesson.descargables = lesson.descargables
          .filter(
            (descargable) =>
              (descargable.src && descargable.src !== "") ||
              (descargable.idAdjunto && descargable.idAdjunto !== null)
          )
          .concat(descargables);
        LessonsService.putLeccion(lesson).then(() => {
          navigate("../../");
          dispatch({ type: HIDE_SPINNER });
          success("¡Leccion guardada con éxito!");
        });
      });
    }
  };

  const agregarMultimediaLeccion = () => {
    dispatch({ type: AGREGAR_MULTIMEDIA });
  };

  const eliminarMultimedia = (multimedia) => {
    dispatch({ type: ELIMINAR_MULTMEDIA, payload: multimedia });
  };

  const eliminarDescargable = (descargable) => {
    dispatch({ type: ELIMINAR_DESCARGABLE, payload: descargable });
  };

  const agregarDescargableLeccion = () => {
    dispatch({ type: AGREGAR_DESCARGABLE });
  };

  const setPropiedadMultimedia = (idMultimedia, key, value) => {
    dispatch({
      type: SET_PROPERTY_MULTIMEDIA,
      payload: { idMultimedia, key, value },
    });
  };

  const setPropiedadDescargable = (idDescargable, key, value) => {
    dispatch({
      type: SET_PROPERTY_DESCARGABLE,
      payload: { idDescargable, key, value },
    });
  };

  const eliminarLeccion = (lesson_id) => {
    LessonsService.deleteLeccion(lesson_id).then(() => {
      success("¡Lección eliminada con éxito!");
      navigate("../../");
      hideModal();
    });
  };

  const completeLessonMedia = (lesson_media_id, callback) => {
    LessonsService.completeLessonMedia(lesson_media_id).then((res) => {
      success("Video completado");
      if (typeof callback === "function") {
        callback(lesson_media_id);
      }
    });
  };

  const completarLeccion = (lesson_id, section_id) => {
    LessonsService.completarLeccion(lesson_id).then(() => {
      getSectionLessons(section_id);
    });
  };

  const updateOrdenLecciones = (lessons) => {
    LessonsService.putOrdenLecciones(lessons);
  };

  const terminarReproduccion = (lesson_id) => {
    PlaybackService.terminarReproduccion(lesson_id);
  };

  const playLeccion = () => {
    dispatch({ type: SHOW_SPINNER });
    PlaybackService.playLeccion()
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        const { reproduccion } = res.data;
        const { course_id, section_id, idTema, lesson_id } = reproduccion;
        navigate(
          `/cursos/${course_id}/modulos/${section_id}/temas/${idTema}/lessons/${lesson_id}`
        );
      })
      .catch(() => {
        dispatch({ type: HIDE_SPINNER });
      });
  };

  return (
    <LessonsContext.Provider
      value={{
        ...state,
        postLeccion,
        playLeccion,
        selectLesson,
        clearLeccion,
        getLecciones,
        setLessonTest,
        createLeccion,
        sortLecciones,
        clearLecciones,
        setLessonMedia,
        getSingleLesson,
        eliminarLeccion,
        completarLeccion,
        getSectionLessons,
        eliminarMultimedia,
        completeLessonMedia,
        eliminarDescargable,
        setPropiedadLeccion,
        getSiguienteLeccion,
        updateOrdenLecciones,
        terminarReproduccion,
        setPropiedadMultimedia,
        setPropiedadDescargable,
        agregarMultimediaLeccion,
        agregarDescargableLeccion,
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};
