import React, { useState } from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT, formatMonto } from "../../utils";

const SingleCourseLanding = ({ course }) => {
  const [flipped, setFlipped] = useState(false);
  const { name, price, course_id, description } = course;

  const getSrc = () => {
    return course.file !== null
      ? `${course.file.name}.${course.file.type}`
      : "";
  };

  const src = getSrc();

  return (
    <div className={`card-container ${flipped ? "translated" : ""}`}>
      <div className="card-inner">
        <div className="card-front">
          <div
            className="text-center text-white text-shadow border home-course relative"
            style={{ backgroundImage: `url(${S3_ENDPOINT}/${src})` }}
          >
            <div style={{ zIndex: 5 }} className="overlay"></div>
            <div className="row p-5 align-items-center">
              <div className="container-fluid px-0">
                <h2
                  style={{ zIndex: 10 }}
                  className="text-shadow position-relative text-uppercase h1"
                >
                  {name}
                </h2>
                <button
                  onClick={() => setFlipped(true)}
                  className="btn btn-round btn-light show-mobile shadow my-4"
                >
                  <i className="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div
              className="bg-dark"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "20px 0",
              }}
            >
              <span
                style={{ zIndex: 10 }}
                className="h3 position-relative text-white bold"
              >
                ${formatMonto(price)} MXN
              </span>
            </div>
          </div>
        </div>
        <div className="card-back px-4 py-5 py-md-4 py-xl-5 bg-white shadow">
          <div className="container-fluid px-0">
            <h3 className="h2 text-center">{name}</h3>
            <p className="small text-muted mt-3 mb-3 px-4 px-lg-0">
              {description}
            </p>
            <p>
              <i className="fa fa-calendar"></i>
              <span className="bold ps-2">12 y 13 de Febrero de 2023</span>
            </p>
            <p style={{ color: "#bca3cc" }} className="bold px-4 px-lg-0">
              Cupo Limitado: 20 Personas
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <p className="h2">${formatMonto(price)}</p>
              <p className="h6 small mt-3 ps-2">MXN</p>
            </div>
            <Link to="/courses" className="btn btn-primary w-100 my-3">
              INSCRÍBETE
            </Link>
            <button
              className="btn show-mobile py-0"
              onClick={() => setFlipped(false)}
            >
              Regresar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCourseLanding;
