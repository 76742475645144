import React from "react";

const DoubleButton = ({
  elementId,
  leftButtonLink,
  leftButtonTitle,
  rightButtonLink,
  rightButtonTitle,
}) => {
  return (
    <div className="container-fluid" id={elementId}>
      <div className="row my-4 align-items-center">
        <div className="col-12 col-md-6">
          <a href={leftButtonLink} className="btn btn-primary w-100 p-4 my-2">
            {leftButtonTitle}
          </a>
        </div>
        <div className="col-12 col-md-6">
          <a href={rightButtonLink} className="btn btn-accent w-100 p-4 my-2">
            {rightButtonTitle}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DoubleButton;
