import React, { useState, useContext, useEffect } from "react";
import { CheckoutContext } from "../context/CheckoutContext";
import CheckoutItem from "../components/checkout/CheckoutItem";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutFooter from "../components/checkout/CheckoutFooter";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "@reach/router";
import { formatMonto } from "../utils";
import { parse } from "query-string";

const Checkout = ({ product_id }) => {
  const location = useLocation();
  //Checkout
  const {
    product,
    setPayPal,
    createOrder,
    setDescuento,
    discountCode,
    setPaymentSource,
    payment_source_id,
  } = useContext(CheckoutContext);
  const { user } = useContext(AuthContext);
  const [paymentPlan, setPaymentPlan] = useState(null);

  useEffect(() => {
    return () => {
      setPaymentPlan(null);
      setDescuento(null);
    };
  }, [product_id]);

  useEffect(() => {
    if (product !== null) {
      handlePaymentPlan();
    }
  }, [product]);

  useEffect(() => {
    if (payment_source_id === "paypal") {
      setPayPalCallback();
    }
  }, [payment_source_id, paymentPlan]);

  const setPayPalCallback = () => {
    const payload = {
      product_id,
      discountCode,
      payment_plan_id:
        paymentPlan !== null ? paymentPlan.payment_plan_id : null,
      payment_method_id: 3,
    };
    setPayPal(payload);
  };

  const renderDiscount = () => {
    if (user !== null) {
      return (
        <CheckoutDiscount payment_plan={paymentPlan} product_id={product_id} />
      );
    }
  };

  const handlePaymentPlan = () => {
    const searchParams = parse(location.search);
    if (searchParams.payment_plan) {
      if (
        parseInt(searchParams.payment_plan) === 3 &&
        product.separate_price !== null
      ) {
        let diff = product.price - product.separate_price;
        return setPaymentPlan({
          description: `Pagarás $${formatMonto(
            diff
          )} MXN el día de la certificación`,
          payment_plan_id: 3,
          name: "Separa tu Lugar",
        });
      }
      let payment_plan = product.payment_plans.find(
        ({ payment_plan_id }) =>
          parseInt(payment_plan_id) === parseInt(searchParams.payment_plan)
      );
      if (payment_plan) {
        return setPaymentPlan(payment_plan);
      }
    }
    setPaymentPlan({
      name: "Pago de Contado",
    });
  };

  const handleSubmit = (payment_source_id) => {
    const payload = {
      product_id,
      discountCode,
      payment_source_id,
      payment_method_id: 1,
    };
    if (paymentPlan && paymentPlan !== null) {
      payload.payment_plan_id = paymentPlan.payment_plan_id;
    }
    createOrder(payload);
  };

  return (
    <div id="checkout" className="container-fluid px-0">
      <CheckoutHeader />
      <div className="checkout-content container">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-4 my-2">
            <CheckoutItem product_id={product_id} payment_plan={paymentPlan} />
            {renderDiscount()}
          </div>
          <div className="col-12 col-md-8 my-2">
            <CheckoutAccount />
            {user !== null && (
              <CheckoutPaymentMethods
                product_id={product_id}
                payment_plan={paymentPlan}
                paymentMethod={payment_source_id}
                setPaymentMethod={setPaymentSource}
                handleSubmitCheckout={handleSubmit}
                setPayPalCallback={setPayPalCallback}
              />
            )}
          </div>
        </div>
      </div>
      <CheckoutFooter />
    </div>
  );
};

export default Checkout;
