import React from "react";
import "../css/landing.css";
import Footer from "../components/global/Footer";
import Anywhere from "../components/landing/Anywhere";
import home_video from "../assets/video/home_intro.mp4";
import VideoAbout from "../components/landing/VideoAbout";
import VideoBanner from "../components/landing/VideoBanner";
import CoursesLanding from "../components/landing/CoursesLanding";
import AboutAccordions from "../components/landing/AboutAccordions";
import LandingFeatures from "../components/landing/LandingFeatures";
import VideoTestimonials from "../components/landing/VideoTestimonials";
import PreguntasFrecuentes from "../components/global/PreguntasFrecuentes";

const Landing = () => {
  return (
    <div id="landing">
      <VideoBanner home_video="https://tbmcerts.s3.us-west-1.amazonaws.com/tbmcertified.mp4" />
      <VideoAbout
        home_video={
          window.innerWidth < 767
            ? "https://tbmcerts.s3.us-west-1.amazonaws.com/explicacion-vertical.mp4"
            : "https://tbmcerts.s3.us-west-1.amazonaws.com/explicacion-horizontal.mp4"
        }
      />
      <Anywhere />
      <LandingFeatures />
      <AboutAccordions />
      <CoursesLanding />
      <VideoTestimonials />
      <PreguntasFrecuentes short />
      <Footer />
    </div>
  );
};

export default Landing;
