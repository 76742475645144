import api from "./api";
import { getArgs } from "../utils";

const route = "/courses";

export default {
  getCourses: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getMyCourses: () => api.get(`${route}/mycourses`),
  getCourseByUrl: (url) => api.get(`${route}/url/${url}`),
  getSingleCourse: (course_id) => api.get(`${route}/${course_id}`),
  postCourse: (curso) => api.post(route, { ...curso }),
  putCourse: (curso) => api.put(route, { ...curso }),
  putPalabraTest: (course_id, palabra) =>
    api.put(`${route}/test`, { course_id, palabra }),
  deleteCourse: (course_id) => api.delete(`${route}/${course_id}`),
};
