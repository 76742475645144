import React, { useContext, useEffect } from "react";
import ProgressCourse from "../components/courses/ProgressCourse";
import SingleSection from "../components/courses/SingleSection";
import SingleLesson from "../components/lessons/SingleLesson";
import MaterialHelp from "../components/ayuda/MaterialHelp";
import { CoursesContext } from "../context/CoursesContext";
import CourseNotes from "../components/notes/CourseNotes";
import LineContact from "../components/line/LineContact";
import Sections from "../components/courses/Sections";
import { AuthContext } from "../context/AuthContext";
import { Router } from "@reach/router";

const SingleCourse = ({ course_id }) => {
  const { course, getCourse, clearCourse } = useContext(CoursesContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    clearCourse();
    getCourse(course_id);
  }, [course_id]);

  useEffect(() => {
    if (user !== null) {
      getCourse(course_id);
    }
  }, [user]);

  useEffect(() => {
    return clearCourse;
  }, []);

  const renderContent = () => {
    if (course && course !== null) {
      if (user !== null) {
        return (
          <div className="position-relative">
            <CourseNotes />
            <ProgressCourse course_id={course.course_id} course={course} />
            <div className="course-content">
              <Router>
                <Sections path="/" course_id={course_id} />
                <SingleSection
                  path="/sections/:section_id"
                  course_id={course_id}
                />
                <SingleLesson path="/sections/:section_id/lessons/:lesson_id" />
              </Router>
            </div>
            <MaterialHelp />
            <LineContact />
          </div>
        );
      }
    }
    return (
      <div className="container-fluid px-0 text-center my-5">
        <div className="spinner-border"></div>
      </div>
    );
  };

  return <div className="container-fluid px-0">{renderContent()}</div>;
};

export default SingleCourse;
