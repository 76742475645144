import React from "react";
import CustomIcon from "./CustomIcon";
import { Link } from "@reach/router";

const Footer = () => {
  return (
    <div className="container-fluid bg-dark d-flex flex-column py-5 px-5">
      <div className="row">
        <div className="col-lg-3 col-12">
          <h5 className="h2 text-white font-body text-sentence">
            Everything you want <br /> in a workout
          </h5>
        </div>
        <div className="col-lg-6 col-12 text-white">
          <div className="d-flex align-items-center mt-4 social-icons">
            <a
              href="https://facebook.com/thebodymethodmx"
              className="text-white me-3"
              target="_blank"
            >
              <i className="fab fa-2x fa-facebook"></i>
            </a>
            <a
              href="https://instagram.com/thebodymethodmx"
              className="text-white me-2"
              target="_blank"
            >
              <i className="fab fa-2x fa-instagram"></i>
            </a>
            <a href="https://tiktok.com/@thebodymethodmx" target="_blank">
              <CustomIcon icon="tiktok-icon" />
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-12 ps-xl-5 text-white">
          <div className="text-start">
            <div className="mt-4 mt-lg-0">
              <span>
                <i className="fab fa-whatsapp me-2"></i>811 321 2423
              </span>
            </div>
            <div className="mt-3">
              <span>
                <i className="fa fa-envelope me-2"></i>
                thebodymethodmty@gmail.com
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr
        className="w-100 mt-5 mb-5"
        style={{ borderBottom: "3px solid white" }}
      />
      <div className="d-flex text-white justify-content-between flex-column flex-lg-row">
        <div className="d-flex justify-content-between flex-column  flex-lg-row">
          <div className="col-12 col-lg-5 w-100">
            <Link to="/terminos" className="no-decoration text-white">
              Términos y condiciones
            </Link>
          </div>
          <div className="mt-md-0 col-12 col-lg-5 w-100 mt-4 mt-lg-0">
            <Link to="/privacidad" className="no-decoration text-white">
              Política de privacidad
            </Link>
          </div>
        </div>
        <div className="text-center text-lg-end mt-5 mt-lg-0">
          <div>The Body Method, 2023</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
