import api from "./api";

const route = "/attempts";

const AttemptsService = {
  getTestAttempts: (test_id) => api.get(`${route}/test/${test_id}`),
  getSingleAttempt: (attempt_id) => api.get(`${route}/${attempt_id}`),
  postUserAnswer: (user_answer) =>
    api.post(`${route}/answer`, { ...user_answer }),
  putUserAnswer: (user_answer) =>
    api.put(`${route}/answer`, { ...user_answer }),
  finishAttempt: (attempt_id) => api.put(`${route}/finish`, { attempt_id }),
  postAttempt: (test_id) => api.post(route, { test_id }),
  putAttempt: (attempt) => api.put(route, { ...attempt }),
};
export default AttemptsService;
