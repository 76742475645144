import { DiscountsProvider } from "./context/DiscountsContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { MetodosProvider } from "./context/MetodosContext";
import { CoursesProvider } from "./context/CoursesContext";
import { LessonsProvider } from "./context/LessonsContext";
import { SectionsProvider } from "./context/SectionsContext";
import { VideosProvider } from "./context/VideosContext";
import { ModalProvider } from "./context/ModalContext";
import { PagesProvider } from "./context/PagesContext";
import { TestsProvider } from "./context/TestsContext";
import { UserProvider } from "./context/AuthContext";
import { HomeProvider } from "./context/HomeContext";
import { combineComponents } from "./context";
import moment from "moment";
import Main from "./Main";
import "./index.css";
import { NotesProvider } from "./context/NotesContext";
import { AttemptsProvider } from "./context/AttemptsContext";
import { ProductsProvider } from "./context/ProductsContext";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";
import { CourseAccessesProvider } from "./context/CourseAccessesContext";
import { AddressProvider } from "./context/AddressContext";

moment.updateLocale("es", {
  weekdays: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
});

const AppContextProvider = combineComponents([
  HomeProvider,
  NotesProvider,
  PagesProvider,
  TestsProvider,
  VideosProvider,
  AddressProvider,
  MetodosProvider,
  LessonsProvider,
  CoursesProvider,
  ProductsProvider,
  SectionsProvider,
  CheckoutProvider,
  PurchaseProvider,
  AttemptsProvider,
  DiscountsProvider,
  PaymentSourcesProvider,
  CourseAccessesProvider,
]);

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <AppContextProvider>
          <Main />
        </AppContextProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
