import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { cardStyle } from "../../utils";
import CheckoutService from "../../services/CheckoutService";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";

const StripeCreditCard = ({ product_id, payment_plan, discountCode }) => {
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { user } = useContext(AuthContext);

  const { alert } = useContext(ModalContext);

  const handleSuccess = (data) => {
    navigate(`/thankyou/${data.purchase_id}`);
    setProcessing(false);
  };

  const handleError = (error) => {
    setProcessing(false);
    let message =
      "Hubo un error al procesar tu pago. Ponte en contacto con nosotros.";
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.message) {
          return alert(error.response.data.message);
        }
      }
      if (error.response.status === 412) {
        if (error.response.data) {
          message = error.response.data.message;
        } else {
          message = "Lo sentimos. Esta certificación se ha llenado.";
        }
      } else if (error.response.status === 409) {
        message =
          "Lo sentimos. Ya cuentas con acceso activo a alguna de las certificaciones incluidas en esta compra.";
      } else if (error.response.status === 402) {
        message =
          "Lo sentimos. Tu tarjeta fue rechazada por fondos insuficientes.";
      } else {
        message =
          "Lo sentimos. Hubo un error al procesar tu compra. Intenta más tarde.";
      }
    }
    return alert(message);
  };

  const getInstallmentsObject = (payment_plan) => {
    if (payment_plan && payment_plan !== null) {
      if (payment_plan.installments !== null && payment_plan.installments > 1) {
        return {
          payment_method_options: {
            card: {
              installments: {
                plan: {
                  count: payment_plan.installments,
                  interval: "month",
                  type: "fixed_count",
                },
              },
            },
          },
        };
      }
    }

    return {};
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });
    const payload = {
      product_id,
      discountCode,
      payment_method,
      payment_method_id: 1,
    };
    if (payment_plan && payment_plan !== null) {
      payload.payment_plan_id = payment_plan.payment_plan_id;
    }
    CheckoutService.postCheckout(payload)
      .then(async (res) => {
        const { data } = res;
        const { clientSecret } = res.data;
        if (clientSecret) {
          let installmentsObject = getInstallmentsObject(payment_plan);
          const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card,
            },
            ...installmentsObject,
          });
          if (payload.error) {
            return handleError(payload.error.message);
          }
        }
        handleSuccess(data);
      })
      .catch(handleError);
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          options={cardStyle}
          className="form-control pt-2 my-2"
          onChange={handleChange}
        />

        <button
          className="btn btn-primary bold mt-2"
          disabled={processing || disabled}
        >
          {processing ? <div className="spinner-border"></div> : "Pagar Ahora"}
        </button>
      </form>
    </div>
  );
};

export default StripeCreditCard;
