import React, { useContext } from "react";
import { SectionsContext } from "../../context/SectionsContext";
import { Link, navigate } from "@reach/router";

const SectionHeader = ({ course_id }) => {
  const { section, sections } = useContext(SectionsContext);

  const handleSectionOrder = (order) => {
    if (Array.isArray(sections)) {
      const currentSection = sections.find(
        (section) => section.order === order
      );
      if (currentSection) {
        navigate(
          `/mytbm/course/${course_id}/section/${currentSection.section_id}`
        );
      }
    }
  };

  const renderSection = () => {
    if (section && section !== null) {
      return (
        <div className="container-fluid px-2">
          <div className="h4">{section.name}</div>
          <div className="small">{section.description}</div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="row align-items-center mb-4">
      <div className="col-md-4 col-12">
        <Link to={`/mytbm/course/${course_id}`} className="btn btn-light">
          <i className="fa fa-chevron-left me-2"></i>Regresar
        </Link>
      </div>
      <div className="col-md-4 co-12">{renderSection()}</div>

      <div className="col-md-4 col-12">
        <div className="arrows-container d-none">
          <div className="row">
            <div className="col-6 text-center">
              <button
                onClick={() => handleSectionOrder(section.order + 1)}
                className="btn btn-sm btn-light"
              >
                <i className="fa fa-chevron-left"></i>
              </button>
              <span className="small d-block text-muted">Anterior</span>
            </div>
            <div className="col-6 text-center">
              <button
                onClick={() => handleSectionOrder(section.order + 1)}
                className="btn btn-sm btn-light"
              >
                <i className="fa fa-chevron-right"></i>
              </button>
              <span className="small d-block text-muted">Siguiente</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeader;
