import React, { useContext, useEffect } from "react";
import { S3_ENDPOINT } from "../../utils";
import { CourseAccessesContext } from "../../context/CourseAccessesContext";

const FinalExamTarget = ({ course_id, children, buttonTitle, handleClick }) => {
  const { course_accesses, getCourseAccesses } = useContext(
    CourseAccessesContext
  );

  useEffect(() => {
    getCourseAccesses();
  }, []);

  const getSrc = (final_exam) => {
    return `${S3_ENDPOINT}/final-${
      String(final_exam).toLowerCase().includes("upper")
        ? "upper-body"
        : "lower-body"
    }.JPG`;
  };

  const renderTarget = () => {
    if (Array.isArray(course_accesses)) {
      const currentAccess = course_accesses.find(
        (course_access) =>
          parseInt(course_access.course_id) === parseInt(course_id)
      );
      if (currentAccess) {
        return (
          <div className="row">
            <div className="col-6">
              <img
                src={getSrc(currentAccess.final_exam)}
                style={{ maxWidth: 300, height: "auto" }}
                className="w-100 m-auto d-block"
              />
            </div>
            <div className="col-6">
              {children}
              <p className="large">Tu examen deberá ser sobre</p>
              <h3 className="h2 mb-3">{currentAccess.final_exam}</h3>
              <button
                onClick={handleClick}
                className="btn btn-dark mb-3 text-primary"
              >
                {buttonTitle}
              </button>
              <p className="small bold text-dark">
                Las instrucciones de tu examen práctico están en tu Manual
              </p>
            </div>
          </div>
        );
      }
    }
  };

  return <div className="container-fluid px-0">{renderTarget()}</div>;
};

export default FinalExamTarget;
