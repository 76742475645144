import {
  CREATE_COURSE,
  COURSES_RECEIVED,
  MISCOURSES_RECEIVED,
  SET_PROPERTY_COURSE,
  SINGLE_COURSE_RECEIVED,
  URL_DISPONIBLE,
} from "../types/courses";

const schema = {
  course_id: "nuevo",
  nombre: "",
  descripcion: "",
  short_description: "",
  precio: "",
  activo: true,
  publico: true,
  url: "",
  idAdjunto: "",
  idAdjunto_portada: "",
  video: "",
  file: null,
  filePortada: null,
};

export default (state, { type, payload }) => {
  switch (type) {
    case COURSES_RECEIVED:
      return { ...state, courses: payload };
    case MISCOURSES_RECEIVED:
      return { ...state, mycourses: payload };
    case SINGLE_COURSE_RECEIVED:
      return { ...state, course: payload };
    case CREATE_COURSE:
      return { ...state, course: schema };
    case SET_PROPERTY_COURSE:
      const course = { ...state.course };
      const { key, value } = payload;
      course[key] = value;
      return { ...state, course };
    case URL_DISPONIBLE:
      return { ...state, available: payload };
    default:
      return { ...state };
  }
};
