import React, { useContext } from "react";
import MultipleChoiceAnswer from "../components/questions/MultipleChoiceAnswer";
import CheckboxAnswer from "../components/questions/CheckboxAnswer";
import ScaleAnswer from "../components/questions/ScaleAnswer";
import { SectionsContext } from "../context/SectionsContext";
import FileAnswer from "../components/questions/FileAnswer";
import { AttempsContext } from "../context/AttemptsContext";
import { CoursesContext } from "../context/CoursesContext";
import { ModalContext } from "../context/ModalContext";
import img from "../assets/images/bg-tbm-online.jpeg";
import { S3_ENDPOINT } from "../utils";
import "../css/SingleCurso.css";
import "../css/quiz.css";

const QuizQuestion = ({
  spinner,
  question,
  questions,
  setAnswer,
  attempt_id,
  user_answers,
  saveUserAnswer,
  handleCallback,
  handleQuestionOrder,
}) => {
  const { course } = useContext(CoursesContext);
  const { section } = useContext(SectionsContext);
  const { finishAttempt } = useContext(AttempsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const getSrc = () => {
    if (course && course !== null) {
      if (course.course_id === 1) {
        return `${S3_ENDPOINT}/quiz-sculpt.JPG`;
      } else if (course.course_id === 2) {
        return `${S3_ENDPOINT}/quiz-barre.JPG`;
      } else if (course.course_id === 3) {
        return `${S3_ENDPOINT}/jump-examen.JPG`;
      }
    }
    return img;
  };

  const handleSubmit = () => {
    modalComponent(
      "Terminar Quiz",
      <div>
        <p>
          ¿Estás segura que deseas terminar tu quiz? Esto calificará tus
          respuestas y recibirás un resultado que no se puede modificar.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn text-muted w-100">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => finishAttempt(attempt_id, user_answers)}
              className="btn btn-primary w-100"
            >
              Terminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleSave = () => {
    const currentAnswer = user_answers.find(
      (answer) => answer.question_id === question.question_id
    );
    if (currentAnswer) {
      saveUserAnswer({ ...currentAnswer, attempt_id }, handleCallback);
    }
  };

  const renderCourse = () => {
    if (course && course !== null) {
      return course.name;
    }
    return "Curso";
  };

  const renderSection = () => {
    if (section && section !== null) {
      return (
        <div>
          <div className="h2 text-white">{section.name}</div>
          <div className="small text-white">{section.description}</div>
        </div>
      );
    }
  };

  const renderAnswers = () => {
    switch (question.type) {
      case "scale":
        return (
          <ScaleAnswer
            question={question}
            setAnswer={setAnswer}
            user_answers={user_answers}
          />
        );
      case "multiple":
        return question.answers.map((answer) => (
          <MultipleChoiceAnswer
            user_answers={user_answers}
            key={answer.answer_id}
            setAnswer={setAnswer}
            question={question}
            answer={answer}
          />
        ));
      case "checkbox":
        return question.answers.map((answer) => (
          <CheckboxAnswer
            user_answers={user_answers}
            key={answer.answer_id}
            setAnswer={setAnswer}
            question={question}
            answer={answer}
          />
        ));
      case "file":
        return (
          <FileAnswer
            user_answers={user_answers}
            setAnswer={setAnswer}
            question={question}
          />
        );
      default:
        return (
          <div>
            <label>Escribe tu respuesta</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setAnswer(question.question_id, e.target.value);
              }}
            />
          </div>
        );
    }
  };
  return (
    <div className="container-fluid h-100 px-4 px-lg-5 ps-lg-5 fondoQuiz">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="mt-5 h2 text-white">{renderCourse()}</div>
        {renderSection()}
        <div className="shadow bg-white mt-3 mb-5">
          <div className="row">
            <div className="col-12 col-md-4 col-xl-6">
              <img
                src={getSrc()}
                alt="quiz"
                style={{ height: "100%", objectFit: "cover" }}
                className="mw-100 w-100 d-block m-auto quiz-img"
              />
            </div>
            <div className="col-12 col-md-8 col-xl-6">
              <div className="px-4 py-4 ps-md-3 pe-md-5 py-md-5">
                <div className="text-accent">
                  Pregunta {question.order} de {questions.length}
                </div>
                <div className="p-3 bg-light">{question.content}</div>
                <div className="mt-3 mb-5">{renderAnswers()}</div>
                <div className="row">
                  {question.order > 1 && (
                    <div
                      className="col-12 col-lg-4 mb-3"
                      onClick={() => handleQuestionOrder(question.order - 1)}
                    >
                      <button className="btn btn-light w-100">ANTERIOR</button>
                    </div>
                  )}
                  <div className="col-12 col-lg-4 mb-3">
                    {user_answers.find(
                      ({ question_id, user_answer_id }) =>
                        question_id === question.question_id && !user_answer_id
                    ) && (
                      <button
                        disabled={spinner}
                        onClick={handleSave}
                        className="btn btn-accent w-100"
                      >
                        {spinner ? (
                          <div className="spinner-border"></div>
                        ) : (
                          "GUARDAR"
                        )}
                      </button>
                    )}
                  </div>
                  <div className="col-12 col-lg-4 mb-3">
                    {question.order === questions.length ? (
                      <button
                        className="btn btn-primary shadow w-100"
                        onClick={handleSubmit}
                      >
                        TERMINAR QUIZ
                      </button>
                    ) : (
                      <button
                        className="btn shadow btn-quiz w-100"
                        onClick={() => handleQuestionOrder(question.order + 1)}
                      >
                        SIGUIENTE <i className="fa fa-chevron-right ms-2"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizQuestion;
