import { navigate } from "@reach/router";
import React from "react";

const FinalExamGraded = ({ attempt_id }) => {
  return (
    <div className="bg-accent text-white p-4">
      <h3>¡Tenemos tus Resultados!</h3>
      <p>Haz click en el botón para descubrirlos.</p>
      <button
        onClick={() => navigate(`/mytbm/attempt/${attempt_id}`)}
        className="btn btn-light"
      >
        Ver Resultados
      </button>
    </div>
  );
};

export default FinalExamGraded;
