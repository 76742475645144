import React, { useContext, useEffect } from "react";
import InvoiceCard from "../components/invoices/InvoiceCard";
import { PurchasesContext } from "../context/PurchasesContext";

const Invoices = () => {
  const { invoices, getInvoices } = useContext(PurchasesContext);

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOrdenes = () => {
    if (invoices && invoices !== null) {
      if (invoices.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return invoices.map((purchase) => (
        <InvoiceCard key={purchase.invoice_id} purchase={purchase} />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="mx-0 row align-items-center mb-3">
        <div className="container-fluid px-0">
          <h2>Mis Cargos</h2>
        </div>
      </div>
      <div className="row mb-3 bg-light border bold mx-0 py-2 hide-mobile">
        <div className="col col-md-1 bold">Folio</div>
        <div className="col col-md-2 bold">Paquete</div>
        <div className="col col-md-2 bold">Total</div>
        <div className="col col-md-2 bold">Fecha de Compra</div>
        <div className="col col-md-2 bold">Siguiente Cargo</div>
        <div className="col col-md-2 bold">Estado</div>
      </div>
      {renderOrdenes()}
    </div>
  );
};

export default Invoices;
