/**
 * User
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_CREATED = "USER_CREATED";
export const SET_PROPERTY_USER = "SET_PROPERTY_USER";
export const SET_PROPERTY_LOGIN = "SET_PROPERTY_LOGIN";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const GUARDAR_USUARIO = "GUARDAR_USUARIO";
export const CUENTA_RECEIVED = "CUENTA_RECEIVED";
/**
 * Modal
 */
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_RESPONSE = "SET_RESPONSE";
export const CLEAR = "CLEAR";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const MODAL_COMPONENT = "MODAL_COMPONENT";
export const SHOW_ALERT = "SHOW_ALERT";
export const SHOW_SUCCESS = "SHOW_SUCCESS";
/**
 * Spinners
 */
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SHOW_SPINNER = "SHOW_SPINNER";
/**
 * Updates
 */
export const SET_UPDATED = "SET_UPDATED";
/**
 * Videos
 */
export const VIDEOS_RECEIVED = "VIDEOS_RECEIVED";
export const SINGLE_VIDEO_RECEIVED = "SINGLE_VIDEO_RECEIVED";
export const SET_PROPERTY_VIDEO = "SET_PROPERTY_VIDEO";
export const CLEAR_VIDEOS = "CLEAR_VIDEOS";
export const APPEND_VIDEOS = "APPEND_VIDEOS";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const PURCHASE_NEEDED = "PURCHASE_NEEDED";
export const SET_COMPLETED = "SET_COMPLETED";
export const SET_NONCE = "SET_NONCE";
/**
 * Paquetes
 */
export const PAQUETES_RECEIVED = "PAQUETES_RECEIVED";
export const SET_PROPERTY_PAQUETE = "SET_PROPERTY_PAQUETE";
export const CREATE_PAQUETE = "CREATE_PAQUETE";
/**
 * Home
 */
export const ONLINE_HOME_RECEIVED = "ONLINE_HOME_RECEIVED";
export const PRESENCIALES_HOME_RECEIVED = "PRESENCIALES_HOME_RECEIVED";
export const ESPECIALES_HOME_RECEIVED = "ESPECIALES_HOME_RECEIVED";
/**
 * Schedule
 */
export const SET_MONTH = "SET_MONTH";
export const SET_WEEK = "SET_WEEK";
export const SEMANAS_RECEIVED = "SEMANAS_RECEIVED";
export const RESERVATIONS_RECEIVED = "RESERVATIONS_RECEIVED";
export const SET_CLASE = "SET_CLASE";
export const SET_PROPERTY_CLASE = "SET_PROPERTY_CLASE";
export const CREATE_CLASE = "CREATE_CLASE";
export const CLASES_RECEIVED = "CLASES_RECEIVED";
export const TOTAL_RECEIVED = "TOTAL_RECEIVED";
/**
 * Locations
 */
export const LOCATIONS_RECEIVED = "LOCATIONS_RECEIVED";
export const SET_LOCATION = "SET_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const SET_PROPERTY_LOCATION = "SET_PROPERTY_LOCATION";
/**
 * Checkout
 */
export const SET_PAQUETE = "SET_PAQUETE";
export const SET_PAYMENT_SOURCE = "SET_PAYMENT_SOURCE";
/**
 * Metodos Pago
 */
export const METODOS_RECEIVED = "METODOS_RECEIVED";
/**
 * Ordenes
 */
export const ORDENES_RECEIVED = "ORDENES_RECEIVED";
export const SET_ORDEN = "SET_ORDEN";
/**
 * Favoritos
 */
export const AGREGAR_FAVORITO = "AGREGAR_FAVORITO";
export const ELIMINAR_FAVORITO = "ELIMINAR_FAVORITO";
export const CLEAR_RELOAD = "CLEAR_RELOAD";
/**
 * Class Category
 */
export const CLASS_CATEGORIES_RECEIVED = "CLASS_CATEGORIES_RECEIVED";
export const CLASS_CATEGORY_RECEIVED = "CLASS_CATEGORY_RECEIVED";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const SET_PROPERTY_CATEGORY = "SET_PROPERTY_CATEGORY";
/**
 * Video Type
 */
export const VIDEO_TYPE_RECEIVED = "VIDEO_TYPE_RECEIVED";
/**
 * Customers
 */
export const CUSTOMERS_RECEIVED = "CUSTOMERS_RECEIVED";
export const LEGACY_CUSTOMER = "LEGACY_CUSTOMER";
export const SET_CUSTOMER = "SET_CUSTOMER";
/**
 * Instructors
 */
export const COACHES_RECEIVED = "COACHES_RECEIVED";
export const SET_PROPERTY_COACH = "SET_PROPERTY_COACH";
export const CREATE_COACH = "CREATE_COACH";
export const SET_COACH = "SET_COACH";
/**
 * Analiticas
 */
export const INSCRITOS_RECEIVED = "INSCRITOS_RECEIVED";
export const INGRESOS_RECBIDOS = "INGRESOS_RECBIDOS";
export const FAVORITOS_RECEIVED = "FAVORITOS_RECEIVED";
/**
 * Class Types
 */
export const CLASS_TYPES_RECEIVED = "CLASS_TYPES_RECEIVED";
export const SET_CLASS_TYPE = "SET_CLASS_TYPE";
export const SET_PROPERTY_CLASS_TYPE = "SET_PROPERTY_CLASS_TYPE";
export const CREATE_CLASS_TYPE = "CREATE_CLASS_TYPE";
/**
 * Descuentos
 */
export const SET_DESCUENTO = "SET_DESCUENTO";
export const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE";
export const HIDE_SPINNER_DESCUENTO = "HIDE_SPINNER_DESCUENTO";
export const SHOW_SPINNER_DESCUENTO = "SHOW_SPINNER_DESCUENTO";
export const CREATE_DESCUENTO = "CREATE_DESCUENTO";
export const DESCUENTOS_RECEIVED = "DESCUENTOS_RECEIVED";
export const SET_PROPERTY_DESCUENTO = "SET_PROPERTY_DESCUENTO";
/**
 * Extras
 */
export const SET_FILTER = "SET_FILTER";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const TAGS_RECEIVED = "TAGS_RECEIVED";
export const PROGRAMS_RECEIVED = "PROGRAMS_RECEIVED";
export const INVOICES_RECEIVED = "INVOICES_RECEIVED";
export const SINGLE_PROGRAM_RECEIVED = "SINGLE_PROGRAM_RECEIVED";
