import React, { useContext, useEffect } from "react";
import { PaymentSourcesContext } from "../../context/PaymentSourcesContext";
import PaymentMethodCard from "../paymentMethods/PaymentMethodCard";
import { CheckoutContext } from "../../context/CheckoutContext";
import PaymentSource from "../payment_sources/PaymentSource";
import { AuthContext } from "../../context/AuthContext";
import StripeCheckout from "../common/StripeCheckout";
import { parse } from "query-string";
import { useLocation } from "@reach/router";

const CheckoutPaymentMethods = ({
  product_id,
  payment_plan,
  paymentMethod,
  setPaymentMethod,
  handleSubmitCheckout,
}) => {
  const { spinner, product, discountCode } = useContext(CheckoutContext);
  const { user } = useContext(AuthContext);
  const location = useLocation();

  const { payment_sources, getPaymentSources } = useContext(
    PaymentSourcesContext
  );

  useEffect(() => {
    if (user !== null) {
      getPaymentSources();
    }
  }, [user]);

  const getCardLabel = () => {
    const searchParams = parse(location.search);
    if (searchParams.payment_plan && product && product !== null) {
      let payment_plan = product.payment_plans.find(
        ({ payment_plan_id }) =>
          parseInt(payment_plan_id) === parseInt(searchParams.payment_plan)
      );
      if (payment_plan) {
        if (payment_plan.installments > 1) {
          return "Tarjeta de Crédito";
        }
      }
    }
    return "Tarjeta de Crédito o Débito";
  };

  const renderPaymentSources = () => {
    if (payment_sources && payment_sources !== null) {
      return payment_sources.map((metodo) => (
        <PaymentSource
          key={metodo.payment_source_id}
          payment_source={metodo}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
        />
      ));
    }
  };

  const renderPayPal = () => {
    if (product !== null) {
      if (
        payment_plan === null ||
        isNaN(parseInt(payment_plan.payment_plan_id)) ||
        parseInt(payment_plan.payment_plan_id) === 3
      ) {
        return (
          <PaymentMethodCard
            name="paypal"
            label="PayPal"
            selected={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          >
            <div
              id="paypal-button"
              className="mt-2"
              style={{
                display: paymentMethod === "paypal" ? "block" : "none",
              }}
            ></div>
          </PaymentMethodCard>
        );
      }
    }
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <div className="oveflow-hidden">
          <h3>Forma de Pago</h3>
          {renderPaymentSources()}
          <PaymentMethodCard
            name="card"
            label={getCardLabel()}
            selected={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          >
            {paymentMethod === "card" && (
              <StripeCheckout
                product_id={product_id}
                discountCode={discountCode}
                payment_plan={payment_plan}
              />
            )}
          </PaymentMethodCard>
          {renderPayPal()}
          <div className="container-fluid px-0 text-right">
            {!["paypal", "card"].includes(paymentMethod) && (
              <button
                onClick={() => handleSubmitCheckout(paymentMethod)}
                className="btn btn-primary btn-lg"
                disabled={spinner}
              >
                {spinner ? (
                  <div className="spinner-border"></div>
                ) : (
                  "Pagar Ahora"
                )}
              </button>
            )}
          </div>
        </div>
      );
    }
  };

  return <div>{renderPago()}</div>;
};

export default CheckoutPaymentMethods;
