import React from "react";
import SingleLessonMedia from "./SingleLessonMedia";

const LessonMedia = ({ lesson }) => {
  const renderMultimedia = () => {
    if (Array.isArray(lesson.media)) {
      return lesson.media.map((multimedia, index) => (
        <SingleLessonMedia key={index} multimedia={multimedia} />
      ));
    }
  };
  return <div>{renderMultimedia()}</div>;
};

export default LessonMedia;
