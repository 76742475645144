import React, { createContext, useContext, useReducer } from "react";
import {
  SET_MODULO,
  CREATE_MODULO,
  MODULOS_RECEIVED,
  SET_PROPERTY_MODULO,
  SET_SECTION_TEST,
} from "../types/modules";
import { navigate } from "@reach/router";
import { ModalContext } from "./ModalContext";
import { hideModal } from "../utils";
import AdjuntosService from "../services/AdjuntosService";
import SectionsReducer from "../reducers/SectionsReducer";
import SectionsService from "../services/SectionsService";

const initialState = {
  sections: null,
  section: null,
  test: null,
};

export const SectionsContext = createContext(initialState);

export const SectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SectionsReducer, initialState);

  const { success } = useContext(ModalContext);

  const clearSections = () => {
    dispatch({ type: MODULOS_RECEIVED, payload: null });
  };

  const setSectionTest = (test) => {
    dispatch({ type: SET_SECTION_TEST, payload: test });
  };

  const getSingleSection = (section_id) => {
    SectionsService.getSection(section_id).then((res) => {
      const { section } = res.data;
      dispatch({ type: SET_MODULO, payload: section });
    });
  };

  const getSectionsCourse = (course_id) => {
    SectionsService.getSectionsCourse(course_id).then((res) => {
      const { sections } = res.data;
      dispatch({ type: MODULOS_RECEIVED, payload: sections });
    });
  };

  const postSection = (section) => {
    if (isNaN(section.section_id)) {
      if (section.file && section.file !== null) {
        let formData = new FormData();
        formData.append("adjunto", section.file);
        AdjuntosService.postAdjunto(formData).then((res) => {
          const { idAdjunto } = res.data;
          section.idAdjunto = idAdjunto;
          SectionsService.postSection(section).then(() => {
            navigate(`/cursos/${section.course_id}`);
            success("¡Section guardado con éxito!");
            getSectionsCourse(section.course_id);
          });
        });
      } else {
        SectionsService.postSection(section).then(() => {
          navigate(`/cursos/${section.course_id}`);
          success("¡Section guardado con éxito!");
          getSectionsCourse(section.course_id);
        });
      }
    } else {
      if (section.file && section.file !== null) {
        let formData = new FormData();
        formData.append("adjunto", section.file);
        AdjuntosService.postAdjunto(formData).then((res) => {
          const { idAdjunto } = res.data;
          section.idAdjunto = idAdjunto;
          SectionsService.putSection(section).then(() => {
            navigate(`/cursos/${section.course_id}`);
            success("¡Section guardado con éxito!");
            getSectionsCourse(section.course_id);
          });
        });
      } else {
        SectionsService.putSection(section).then(() => {
          navigate(`/cursos/${section.course_id}`);
          success("¡Section guardado con éxito!");
          getSectionsCourse(section.course_id);
        });
      }
    }
  };

  const createSection = (course_id) => {
    dispatch({ type: CREATE_MODULO, payload: course_id });
  };

  const setPropiedadSection = (key, value) => {
    dispatch({ type: SET_PROPERTY_MODULO, payload: { key, value } });
  };

  const deleteSection = (section_id, course_id) => {
    SectionsService.deleteSection(section_id).then(() => {
      success("¡Section eliminado con éxito!");
      getSectionsCourse(course_id);
      navigate(`/cursos/${course_id}`);
      hideModal();
    });
  };

  const updateOrdenSections = (sections) => {
    SectionsService.updateOrdenSections(sections);
  };

  return (
    <SectionsContext.Provider
      value={{
        ...state,
        postSection,
        createSection,
        clearSections,
        deleteSection,
        setSectionTest,
        getSingleSection,
        getSectionsCourse,
        updateOrdenSections,
        setPropiedadSection,
      }}
    >
      {children}
    </SectionsContext.Provider>
  );
};
