import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import { AuthContext } from "../context/AuthContext";
import { formatMonto } from "../utils";
import { Link } from "@reach/router";
import moment from "moment";
import { CheckoutContext } from "../context/CheckoutContext";
import StatusBadge from "../components/global/StatusBadge";

const Gracias = ({ purchase_id }) => {
  const { purchase, getPurchase } = useContext(PurchasesContext);

  const { descuento } = useContext(CheckoutContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null && purchase === null) {
      getPurchase(purchase_id);
    }
  }, [user]);

  useEffect(() => {
    if (purchase !== null) {
      setTimeout(() => {
        getPurchase(purchase_id);
      }, 1000);
    }
  }, [purchase]);

  const renderTotal = () => {
    if (purchase.free_trial_length !== null) {
      return (
        <div className="mt-2">
          <p>Total Pagado: $0 MXN</p>
          <p>
            Siguiente Cargo: ${formatMonto(purchase.course.price)} MXN el{" "}
            {moment()
              .add(purchase.course.free_trial_length, "days")
              .format("DD MMM YYYY")}
          </p>
        </div>
      );
    }
    let total = purchase.amount;
    if (descuento && descuento !== null) {
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = parseFloat(total) * porcentaje;
      } else {
        total = total - descuento.amount;
      }
    }
    total = parseFloat(total).toFixed(2);
    return <p className="mt-2">Total: ${formatMonto(total)} MXN</p>;
  };

  const renderOrden = () => {
    if (purchase && purchase !== null) {
      return (
        <div>
          <div className="mb-4">
            <h3 className="h5">Compra #{purchase.purchase_id}</h3>
            <p className="mb-1">
              Fecha: {moment(purchase.created_at).format("DD MMM YYYY HH:mm")}
            </p>
            <StatusBadge status={purchase.status} />
            {renderTotal()}
          </div>
          <h4 className="border-bottom mt-4 pb-2">Detalles</h4>
          <p>Método de Pago: {purchase.payment_method.name}</p>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid bg-light vh-100 pt-5">
      <div className="container py-5">
        <h1 className="text-center m-4">Resumen de tu Compra</h1>
        <div className="card shadow mw-500 m-auto d-block p-4">
          {renderOrden()}
          <Link to="/mytbm" className="btn btn-accent bold d-block">
            Ir a TBM
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Gracias;
