import React from "react";
import { Link } from "@reach/router";

const PanelNavbar = ({ user, signOut }) => {
  return (
    <nav
      id="panel-navbar"
      className="navbar px-3 bg-dark navbar-expand-lg navbar-dark shadow-sm hide-mobile col-12"
    >
      <div className="container-fluid">
        <Link to="/" className="navbar-brand py-2 ms-3">
          <img
            src="https://tbmcerts.s3.us-west-1.amazonaws.com/established.png"
            className="logo-navbar"
            alt="logo"
          />
          <span className="ms-3 francois">The Body Method</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav nav mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/mytbm">
                Certificaciones
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://thebodymethod.mx"
                target="_blank"
              >
                TBM Online
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                style={{ color: "white" }}
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user.name} <i className="ms-2 fa fa-user-circle"></i>
              </a>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link to="./informacion" className="dropdown-item">
                    Mi Información
                  </Link>
                </li>
                <li>
                  <Link to="./membresias" className="dropdown-item">
                    Mis Compras
                  </Link>
                </li>
                <li>
                  <Link to="./accesos" className="dropdown-item">
                    Mis Accesos
                  </Link>
                </li>
                <li className="mt-3">
                  <button
                    className="dropdown-item text-danger"
                    onClick={signOut}
                  >
                    Salir{" "}
                    <i className="ms-2 fa fa-sign-out-alt text-danger"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
