import React from "react";

const MobileFeatures = () => {
  return (
    <div>
      <div
        id="carouselExampleControls"
        className="carousel slide d-lg-none w-100"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="https://tbmcerts.s3.us-west-1.amazonaws.com/negocio.JPG"
              className="d-block w-100 mw-100"
              alt="Imagen 1"
            />
            <div className="carousel-caption text-start">
              <h5 className="h3 display-3">EMPRENDE TU PROPIO NEGOCIO</h5>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="https://tbmcerts.s3.us-west-1.amazonaws.com/conocimientos.JPG"
              className="d-block w-100 mw-100"
              alt="Imagen 2"
            />
            <div className="carousel-caption text-start">
              <h5 className="h3 display-3">AMPLIA TUS CONOCIMIENTOS</h5>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="https://tbmcerts.s3.us-west-1.amazonaws.com/tecnica.JPG"
              className="d-block w-100 mw-100"
              alt="Imagen 3"
            />
            <div className="carousel-caption text-start">
              <h5 className="h3 display-3">MEJORA TU TÉCNICA</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-3">
        <button
          className="btn btn-light btn-arrow border me-2"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <i className="fa fa-chevron-left" />
        </button>
        <button
          className="btn btn-light btn-arrow border"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default MobileFeatures;
