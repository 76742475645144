import React from "react";
import AttemptQuestion from "../components/tests/AttemptQuestion";

const QuizFeedback = ({ questions, answers }) => {
  const renderQuestions = () => {
    if (Array.isArray(questions)) {
      return questions.map((question) => (
        <AttemptQuestion
          key={question.question_id}
          question={question}
          answers={answers}
        />
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <h3 className="mt-5 mb-3">Feedback</h3>
      {renderQuestions()}
    </div>
  );
};

export default QuizFeedback;
