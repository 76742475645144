import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { ANSWERS_RECEIVED, SET_ANSWER } from "../types/answers";
import {
  ATTEMPTS_RECEIVED,
  SET_SINGLE_ATTEMPT,
  SET_UPDATE,
} from "../types/attemps";
import { QUESTIONS_RECEIVED, SET_QUESTION } from "../types/questions";
import { SINGLE_TEST_RECEIVED } from "../types/tests";

const AttemptsReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case ATTEMPTS_RECEIVED:
      return { ...state, attempts: payload };
    case SET_UPDATE:
      return { ...state, update: payload };
    case SET_ANSWER: {
      let answers = [...state.answers];
      const index = answers.findIndex(
        (answer) => answer.question_id === payload.question_id
      );
      if (index === -1) answers.push(payload);
      else answers[index] = payload;
      return { ...state, answers: [...answers] };
    }
    case SET_SINGLE_ATTEMPT:
      return { ...state, attempt: payload };
    case QUESTIONS_RECEIVED:
      return { ...state, questions: payload };
    case ANSWERS_RECEIVED:
      return { ...state, answers: payload };
    case SINGLE_TEST_RECEIVED:
      return { ...state, test: payload };
    case SET_QUESTION:
      return { ...state, question: payload };
    case SET_ANSWER:
      const { question_id, answer } = payload;
      const answers = [...state.answers];
      let index = answers.findIndex(
        (answer) => answer.question_id === question_id
      );
      if (index === -1) {
        answers.push({ question_id, answer });
      } else {
        answers[index] = { question_id, answer };
      }
      return { ...state, answers };
    default:
      return { ...state };
  }
};
export default AttemptsReducer;
