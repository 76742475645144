import React, { useContext, useEffect } from "react";
import CancelForm from "../components/purchases/CancelForm";
import PurchaseCard from "../components/purchases/PurchaseCard";
import { ModalContext } from "../context/ModalContext";
import { PurchasesContext } from "../context/PurchasesContext";

const Purchases = () => {
  const { purchases, getPurchases, cancelSubscription } =
    useContext(PurchasesContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm purchase={purchase} cancelSubscription={cancelSubscription} />
    );
  };

  const renderOrdenes = () => {
    if (purchases && purchases !== null) {
      if (purchases.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return purchases.map((purchase) => (
        <PurchaseCard
          key={purchase.purchase_id}
          purchase={purchase}
          cancelSubscription={confirmCancel}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mx-0 align-items-center mb-3">
        <div className="container-fluid">
          <h2>Mis Compras</h2>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className="bg-light border bold mx-0 py-2">
                <tr>
                  <td className="bold">Folio</td>
                  <td className="bold">Producto</td>
                  <td className="bold">Total</td>
                  <td className="bold">Fecha</td>
                  <td className="bold">Estado</td>
                  <td className="bold">Acciones</td>
                </tr>
              </thead>
              <tbody>{renderOrdenes()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchases;
