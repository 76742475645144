import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { LessonsContext } from "../../context/LessonsContext";
import SingleLessonMedia from "../lessons/SingleLessonMedia";

const MediaSection = ({ test }) => {
  const {
    lesson,
    lesson_media,
    setLessonTest,
    setLessonMedia,
    getSingleLesson,
    completarLeccion,
  } = useContext(LessonsContext);

  useEffect(() => {
    handleFirstLessonMedia();
  }, [lesson]);

  const handleCallback = (lesson_media_id) => {
    if (Array.isArray(lesson.media)) {
      const index = lesson.media.findIndex(
        (media) => parseInt(media.lesson_media_id) === parseInt(lesson_media_id)
      );
      if (index === lesson.media.length - 1 && lesson.completed.length === 0) {
        completarLeccion(lesson.lesson_id, lesson.section_id);
      }
    }
    getSingleLesson(lesson.lesson_id);
  };

  const handleLessonTests = () => {
    if (lesson.tests.length > 0) {
      //find next unattempted test or open attempt
      let nextTest = lesson.tests.find((test) => {
        const { attempts } = test;
        if (attempts.length === 0) return test;
        let nextAttempt = attempts.find(
          (attempt) => attempt.finishedAt === null
        );
        if (nextAttempt) return test;
      });
      if (nextTest) {
        return setLessonTest(nextTest);
      }
    }
    if (lesson.completed.length === 0) {
      completarLeccion(lesson.lesson_id, lesson.section_id);
    }
  };

  const handleFirstLessonMedia = () => {
    if (lesson && lesson !== null) {
      if (Array.isArray(lesson.media)) {
        let nextVideo = lesson.media.find(
          (media) => media.completed.length === 0
        );
        if (!nextVideo && lesson.media.length > 0) {
          nextVideo = lesson.media[lesson.media.length - 1];
          handleLessonTests();
        }
        if (!nextVideo) {
          nextVideo = {
            name: "Lo sentimos, este capítulo aún no tiene videos.",
          };
          handleLessonTests();
        }
        setLessonMedia(nextVideo);
      }
    }
  };

  const renderButton = (nextVideo) => {
    if (Array.isArray(nextVideo.completed)) {
      if (nextVideo.completed.length === 0) {
        return (
          <button
            onClick={() =>
              completarLeccion(nextVideo.lesson_id, lesson.section_id)
            }
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-check me-2"></i> Completar Video
          </button>
        );
      }
    }
  };

  const renderLessonMedia = () => {
    if (test && test !== null) {
      return (
        <div className="ps-4">
          <div className="h4 bold">QUIZ MODULO 5 </div>
          <div className="small mt-3">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac
            lorem non nisl interdum tempus id id felis. Maecenas finibus, lacus
            sed eleifend varius, augue eros ultricies justo, et molestie odio
            nisl vitae mauris. Cras ut mauris sed tortor auctor tristique. Proin
            rhoncus condimentum mauris, vitae faucibus risus imperdiet vel.
            Fusce placerat, turpis a mattis tristique, libero metus commodo
            felis, eu convallis risus erat id lacus."
          </div>
          <div className="small mt-3 text-accent">
            30 Preguntas de opción multiple
          </div>
          <div className="d-flex col-12 justify-content-lg-end justify-content-center">
            <Link to="/" className="btn btn-primary col-12 col-lg-4 mt-3">
              PRESENTAR QUIZ
            </Link>
          </div>
        </div>
      );
    }
    if (lesson_media && lesson_media !== null) {
      return (
        <div className="card px-0 py-0 pb-2 shadow">
          <div className="row">
            <div className="col-12 col-md-6 videoSection">
              <SingleLessonMedia multimedia={lesson_media} />
            </div>
            <div className="col-12 col-md-6">
              <div className="row h-100 align-items-center">
                <div className="container-fluid">
                  <h4>{lesson_media.name}</h4>
                  <p className="small mt-3">{lesson_media.description}</p>
                  <p className="small text-accent">
                    Duración: {lesson_media.duration} minutos
                  </p>
                  {renderButton(lesson_media)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <div className="col-12 col-md-8">{renderLessonMedia()}</div>;
};

export default MediaSection;
