import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "../../utils";
import StripeCreditCard from "./StripeCreditCard";

const promise = loadStripe(STRIPE_KEY);

const StripeCheckout = ({ product_id, payment_plan, discountCode }) => {
  return (
    <div className="container-fluid position-relative h-100 px-0">
      <Elements stripe={promise}>
        <StripeCreditCard
          product_id={product_id}
          payment_plan={payment_plan}
          discountCode={discountCode}
        />
      </Elements>
    </div>
  );
};

export default StripeCheckout;
