import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import UserForm from "../components/user/UserForm";
import UserData from "../components/user/UserData";
import { navigate } from "@reach/router";
import PanelHeader from "../components/panel/PanelHeader";
import UserInformation from "./UserInformation";
import UserAddress from "./UserAddress";

const MiInformacion = () => {
  const [editMode, setEditMode] = useState(false);

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
    updateUsuario(user);
  };

  const handleCancel = () => {
    setEditMode(false);
    getUsuario();
  };

  const handleEdit = () => {
    setEditMode(true);
    //navigate("/mylegion/account/edit");
  };

  const renderInformacion = () => {
    if (user && user !== null) {
      if (editMode) {
        return (
          <UserForm
            user={user}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            setPropiedadUser={setPropiedadUser}
          />
        );
      }
      return <UserData user={user} handleEdit={handleEdit} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container px-0 py-4">
      <PanelHeader title="Mi Informacion" />
      <UserInformation />
      <UserAddress />
    </div>
  );
};

export default MiInformacion;
