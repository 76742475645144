import React, { useContext } from "react";
import { SectionsContext } from "../context/SectionsContext";
import { AttempsContext } from "../context/AttemptsContext";
import { CoursesContext } from "../context/CoursesContext";
import QuizFailed from "./QuizFailed";
import QuizApproved from "./QuizApproved";
import QuizGrading from "./QuizGrading";
import "../css/SingleCurso.css";
import "../css/quiz.css";

const QuizResult = () => {
  const { course } = useContext(CoursesContext);
  const { section } = useContext(SectionsContext);
  const { questions, answers, attempt, test } = useContext(AttempsContext);

  const isAttemptValid = () => attempt && attempt !== null;

  const isTestValid = () => test && test !== null;

  const isCourseValid = () => course && course !== null;

  const isApproved = () => {
    if (isAttemptValid() && isTestValid()) {
      if (parseFloat(attempt.score) >= parseFloat(test.min_score)) {
        return (
          <QuizApproved
            test={test}
            course={course}
            attempt={attempt}
            answers={answers}
            questions={questions}
          />
        );
      } else if (attempt.score === null) {
        return <QuizGrading />;
      } else {
        return (
          <QuizFailed
            attempt={attempt}
            questions={questions}
            answers={answers}
            course={course}
            test={test}
          />
        );
      }
    }
  };

  const renderSection = () => {
    if (section && section !== null) {
      return (
        <div>
          <div className="h2 text-white">{section.name}</div>
          <div className="small text-white">{section.description}</div>
        </div>
      );
    }
  };

  const renderCourse = () => {
    if (isCourseValid()) {
      return course.name;
    }
    if (isAttemptValid()) {
      if (attempt.test && attempt.test !== null) {
        if (attempt.test.course && attempt.test.course !== null) {
          return attempt.test.course.name;
        }
      }
    }
    return "Certificación";
  };

  return (
    <div className="col-12 h-100 bg-accent px-lg-5 ps-lg-5">
      <div className="ps-5 px-5 d-flex flex-column justify-content-center align-items-center">
        <div className="mt-5 h2 text-white">{renderCourse()}</div>
        <div className="h2 text-white">{renderSection()}</div>
        <div className="shadow bg-white text-dark col-12 d-flex justify-content-center align-items-center mt-4 mb-5">
          {isApproved()}
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
