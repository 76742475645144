import React from "react";

const Anywhere = () => {
  return (
    <div className="franjaBody col-12 pb-5 mt-5">
      <div className="row flex-xl-row-reverse align-items-center">
        <div style={{ zIndex: 1 }} className="col-12 col-sm-12 col-xl-8">
          <div className="text-container text-start container-fluid bg-dark py-5">
            <h1 className="text-accent">APRENDE DESDE CUALQUIER LUGAR</h1>
            <div className="text-white">
              <p>
                Te ofrecemos dos opciones diferentes para que aprendas de la
                forma que prefieras:
              </p>
              <b>ON DEMAND</b>
              <p>
                Aprende en línea de una forma práctica y divertida, te llevamos
                paso a paso. Vive la experiencia a tu propio ritmo y ten la
                facilidad de elegir tus propios horarios de estudio, incluye
                soporte en línea con tus Master Coaches y retroalimentación
                personalizada.
              </p>
              <b>EN VIVO</b>
              <p>
                Vive un fin de semana de preparación intensivo, te acompañamos
                en tu proceso de aprendizaje guiado de la mano de nuestras
                Master Coaches, disfruta de una experiencia increíble.
              </p>
              <div className="row">
                <div className="col-6">
                  <p className="italic mb-0">PRESENCIAL</p>
                  <p>Directo en nuestro estudio en Mty, Mx</p>
                </div>
                <div className="col-6">
                  <p className="italic mb-0">REMOTA</p>
                  <p>Via Zoom</p>
                </div>
              </div>
              <p className="small">
                **Incluyen también 1 mes de acceso a la certificación on demand
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ zIndex: 2 }}
          className="col-12 col-sm-12 col-xl-4 text-center"
        >
          <img
            src="https://tbmcerts.s3.us-west-1.amazonaws.com/anywhere.png"
            className="aprende m-auto"
            style={{ zIndex: 10 }}
          />
        </div>
      </div>
    </div>
  );
};
export default Anywhere;
