import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import CoursePurchasesTable from "../components/memberships/CoursePurchasesTable";
import { CourseAccessesContext } from "../context/CourseAccessesContext";

const CourseAccesses = () => {
  const { course_accesses, getCourseAccesses } = useContext(
    CourseAccessesContext
  );

  useEffect(() => {
    getCourseAccesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCourses = () => {
    if (Array.isArray(course_accesses)) {
      return <CoursePurchasesTable courses={course_accesses} />;
    }
  };
  return (
    <div className="container-fluid py-3">
      <div className="mx-0 row align-items-center">
        <div className="container-fluid">
          <h4>Mis Accesos</h4>
          {renderCourses()}
        </div>
      </div>
    </div>
  );
};

export default CourseAccesses;
