import api from "./api";
import { getArgs } from "../utils";

const route = "/lessons";

export default {
  getSiguienteLeccion: (lesson_id, course_id) =>
    api.get(`${route}/siguiente/${lesson_id}?course_id=${course_id}`),
  getLeccion: (lesson_id) => api.get(`${route}/${lesson_id}`),
  getSectionLessons: (section_id) => api.get(`${route}/section/${section_id}`),
  getLecciones: ({ course_id, section_id, topic_id }) =>
    api.get(`${route}?${getArgs({ course_id, section_id, topic_id })}`),
  completarLeccion: (lesson_id) => api.post(`${route}/complete`, { lesson_id }),
  completeLessonMedia: (lesson_media_id) =>
    api.post(`${route}/complete/media`, { lesson_media_id }),
};
