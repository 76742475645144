import {
  AGREGAR_DESCARGABLE,
  AGREGAR_MULTIMEDIA,
  CREATE_LESSON,
  ELIMINAR_DESCARGABLE,
  ELIMINAR_MULTMEDIA,
  LESSONES_RECEIVED,
  SET_LESSON,
  SET_PROPERTY_LESSON,
  SET_PROPERTY_MULTIMEDIA,
  SET_PROPERTY_DESCARGABLE,
  APPEND_LESSONS,
  SET_LESSON_MEDIA,
  SET_LESSON_TEST,
} from "../types/lessons";
import { SHOW_SPINNER, HIDE_SPINNER } from "../types";

const schema = {
  idLeccion: "nueva",
  nombre: "",
  contenido: "",
  orden: 1,
  idAdjunto: null,
  multimedia: [],
  descargables: [],
};

export default (state, { type, payload }) => {
  switch (type) {
    case SET_LESSON_TEST:
      return { ...state, test: payload };
    case SET_LESSON_MEDIA:
      return { ...state, lesson_media: payload };
    case LESSONES_RECEIVED:
      return { ...state, lessons: payload };
    case APPEND_LESSONS: {
      let lessons = state.lessons;
      if (Array.isArray(lessons)) {
        lessons = [...state.lessons];
      } else {
        lessons = [];
      }
      lessons = [...lessons, ...payload];
      return { ...state, lessons };
    }
    case SET_LESSON:
      return { ...state, lesson: payload };
    case SET_PROPERTY_LESSON:
      const { key, value } = payload;
      const lesson = { ...state.lesson };
      lesson[key] = value;
      return { ...state, lesson };
    case CREATE_LESSON:
      return { ...state, lesson: schema };
    case AGREGAR_MULTIMEDIA:
      return {
        ...state,
        lesson: {
          ...state.lesson,
          multimedia: [
            ...state.lesson.multimedia,
            {
              idMultimedia: `nueva-${state.lesson.multimedia.length}`,
              file: null,
            },
          ],
        },
      };
    case AGREGAR_DESCARGABLE:
      return {
        ...state,
        lesson: {
          ...state.lesson,
          descargables: [
            ...state.lesson.descargables,
            {
              idDescargable: `nuevo-${state.lesson.descargables.length}`,
              file: null,
            },
          ],
        },
      };
    case SET_PROPERTY_MULTIMEDIA: {
      const { idMultimedia, key, value } = payload;
      const { multimedia } = { ...state.lesson };
      const index = multimedia.findIndex(
        (mult) => mult.idMultimedia === idMultimedia
      );
      if (index !== -1) {
        multimedia[index][key] = value;
      }
      return { ...state, lesson: { ...state.lesson, multimedia } };
    }
    case SET_PROPERTY_DESCARGABLE: {
      const { idDescargable, key, value } = payload;
      const { descargables } = { ...state.lesson };
      const index = descargables.findIndex(
        (mult) => mult.idDescargable === idDescargable
      );
      if (index !== -1) {
        descargables[index][key] = value;
      }
      return { ...state, lesson: { ...state.lesson, descargables } };
    }
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case ELIMINAR_MULTMEDIA: {
      const lesson = { ...state.lesson };
      const multimedia = [...lesson.multimedia];
      if (typeof payload === "object") {
        let index = multimedia.findIndex(
          (mult) => mult.idMultimedia === payload.idMultimedia
        );
        if (index !== -1) {
          multimedia[index].idMultimedia = `d${multimedia[index].idMultimedia}`;
        }
      }
      return { ...state, lesson: { ...lesson, multimedia } };
    }
    case ELIMINAR_DESCARGABLE: {
      const lesson = { ...state.lesson };
      const descargables = [...lesson.descargables];
      if (typeof payload === "object") {
        let index = descargables.findIndex(
          (mult) => mult.idDescargable === payload.idDescargable
        );
        if (index !== -1) {
          descargables[
            index
          ].idDescargable = `d${descargables[index].idDescargable}`;
        }
      }
      return { ...state, lesson: { ...lesson, descargables } };
    }
    default:
      return { ...state };
  }
};
