import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import TestsReducer from "../reducers/TestsReducer";
import TestsService from "../services/TestsService";
import {
  CREATE_TEST,
  TESTS_RECEIVED,
  SET_PROPERTY_TEST,
  SINGLE_TEST_RECEIVED,
} from "../types/tests";
import { ModalContext } from "./ModalContext";

const initialState = {
  tests: null,
  test: null,
};

export const TestsContext = createContext(initialState);

export const TestsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TestsReducer, initialState);

  const { success } = useContext(ModalContext);

  const getTest = (test_id) => {
    TestsService.getTest(test_id).then((res) => {
      const { test } = res.data;
      dispatch({ type: SINGLE_TEST_RECEIVED, payload: test });
    });
  };

  const getTestsCourse = (course_id) => {
    TestsService.getTestsCourse(course_id).then((res) => {
      const { tests } = res.data;
      dispatch({ type: TESTS_RECEIVED, payload: tests });
    });
  };
  const getTestsSection = (section_id) => {
    dispatch({ type: TESTS_RECEIVED, payload: initialState.tests });
    TestsService.getTestsSection(section_id).then((res) => {
      const { tests } = res.data;
      dispatch({ type: TESTS_RECEIVED, payload: tests });
    });
  };
  const getTestsTema = (idTema) => {
    TestsService.getTestsTema(idTema).then((res) => {
      const { tests } = res.data;
      dispatch({ type: TESTS_RECEIVED, payload: tests });
    });
  };

  const getTestsLeccion = (idLeccion) => {
    TestsService.getTestsLeccion(idLeccion).then((res) => {
      const { tests } = res.data;
      dispatch({ type: TESTS_RECEIVED, payload: tests });
    });
  };

  const setPropiedadTest = (key, value) => {
    dispatch({ type: SET_PROPERTY_TEST, payload: { key, value } });
  };

  const createTest = () => {
    dispatch({ type: CREATE_TEST });
  };

  const postTest = (test) => {
    let service = "";
    if (isNaN(test.test_id)) {
      if (test.idTema) {
        service = TestsService.postTestTema(test);
      } else if (test.section_id) {
        service = TestsService.postTestSection(test);
      } else {
        service = TestsService.postTestCourse(test);
      }
    } else {
      service = TestsService.putTest(test);
    }
    service.then(() => {
      success("¡Test guardado!");
      navigate("../../");
    });
  };

  const deleteTest = (test_id) => {
    TestsService.deleteTest(test_id).then(() => {
      success("¡Test eliminado!");
      navigate("../../");
    });
  };

  return (
    <TestsContext.Provider
      value={{
        ...state,
        setPropiedadTest,
        getTestsSection,
        getTestsLeccion,
        getTestsCourse,
        getTestsTema,
        createTest,
        deleteTest,
        postTest,
        getTest,
      }}
    >
      {children}
    </TestsContext.Provider>
  );
};
