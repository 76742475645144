import React, { useContext, useEffect } from "react";
import { ProductsContext } from "../../../context/ProductsContext";
import { formatMonto } from "../../../utils";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "@reach/router";
import "../../../css/ModalWeb.css";
import moment from "moment";
import {
  getPrice,
  getProductLocation,
  hasSalePrice,
} from "../../../utils/products";

const InscriptionWeb = ({ mode, show, location, product_id, handleClose }) => {
  const { product, clearProduct, getSingleProduct } =
    useContext(ProductsContext);

  useEffect(() => {
    getSingleProduct(product_id);
    return clearProduct;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  const onSale = hasSalePrice(product);
  const product_price = getPrice(product);

  const renderPaymentPlans = () => {
    if (product && product !== null) {
      let components = [
        <Link
          key="contado"
          to={`/checkout/${product_id}`}
          style={{ backgroundColor: "#3F7FA5" }}
          className="btn w-100 mb-3  py-2 shadow text-white"
        >
          <span className="mx-auto">
            Pago de Contado - ${formatMonto(product_price)} MXN
          </span>
          <i className="ms-2 fa fa-chevron-right"></i>
        </Link>,
      ];
      if (product.separate_price !== null) {
        components.push(
          <Link
            key="contado"
            to={`/checkout/${product_id}?payment_plan=3`}
            style={{ backgroundColor: "#c9e881" }}
            className="btn w-100 mb-3 py-2 shadow text-white"
          >
            <span className="mx-auto">
              Separa tu Lugar - ${formatMonto(product.separate_price)} MXN
            </span>
            <i className="ms-2 fa fa-chevron-right"></i>
          </Link>
        );
      }
      const { payment_plans } = product;
      if (Array.isArray(payment_plans)) {
        payment_plans
          .filter(({ payment_plan_id }) => payment_plan_id !== 3)
          .forEach((plan) => {
            components.push(
              <Link
                to={`/checkout/${product.product_id}?payment_plan=${plan.payment_plan_id}`}
                style={{ backgroundColor: plan.color }}
                className="btn w-100 mb-3 py-2 shadow text-white"
              >
                <span className="mx-auto">
                  {plan.name} - ${formatMonto(product_price)} MXN
                </span>
                <i className="ms-2 fa fa-chevron-right"></i>
              </Link>
            );
          });
      }
      return components;
    }
    return <div className="spinner-border"></div>;
  };

  const renderFormat = () => {
    if (mode === "live") {
      if (location === "studio") {
        return "Presencial - ESTUDIO";
      }
      return "En Zoom - REMOTO";
    }
    return "On Demand - REMOTO";
  };

  const renderDates = () => {
    let { start_date, end_date } = product;
    if (start_date !== null && end_date !== null) {
      return (
        <tr className="border-bottom">
          <td className="w-70 text-start bold">Fechas</td>
          <td className="w-30 text-end py-3">
            <i className="fa fa-calendar me-2"></i>
            {moment(start_date).utc().format("DD MMM YYYY")} -{" "}
            {moment(end_date).utc().format("DD MMM YYYY")}
          </td>
        </tr>
      );
    }
  };

  const renderLocation = () => {
    const current_location = getProductLocation(product);
    if (mode === "live" && current_location && current_location !== null) {
      return (
        <tr className="border-bottom">
          <td className="text-start bold py-3">Ubicación</td>
          <td className="text-end py-3">
            {current_location} <i className="fa fa-globe ps-3"></i>
          </td>
        </tr>
      );
    }
  };

  const renderProduct = () => {
    if (product && product !== null) {
      return (
        <div className="col-12 col-md-6 px-4">
          <span className="h2">{product.name}</span>
          <div className="small mt-3 d-block">
            {parse(product.short_description)}
            {parse(product.description)}
          </div>
          <table className="w-100 mb-4">
            <tbody>
              <tr className="border-bottom">
                <td className="w-70 text-start bold">Formato</td>
                <td className="w-30 text-end py-3 ">{renderFormat()}</td>
              </tr>
              {renderDates()}
              {renderLocation()}
              {onSale && (
                <tr>
                  <td className="text-start h6 py-3">Antes</td>
                  <td className="text-end py-3 h6 text-danger">
                    <del>
                      ${formatMonto(product.price)}
                      <span className="h6 small"> MXN</span>
                    </del>
                  </td>
                </tr>
              )}
              <tr>
                <td className="text-start h5 py-3">Total</td>
                <td className="text-end py-3 h4" style={{ color: "pink" }}>
                  ${formatMonto(product_price)}
                  <span className="h6 small"> MXN</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <Modal
      centered
      size="xl"
      show={show}
      onHide={handleClose}
      className="custom-modal"
    >
      <Modal.Body>
        <button type="button" className="close-btn" onClick={handleClose}>
          &times;
        </button>
        <div className="row py-4">
          {renderProduct()}
          <div className="col-12 col-md-6 px-4">
            <span className="h5 d-block">FORMAS DE PAGO</span>
            {renderPaymentPlans()}
            <p className="small mt-5">
              *No se realiza devoluciones del apartado. Consulta nuestros{" "}
              <a href="#" className="text-primary">
                términos y condiciones
              </a>
              .
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InscriptionWeb;
