import React from "react";

const DesktopFeatures = () => {
  return (
    <div id="desktop-features" className="row">
      <div className="col-4 position-relative mb-3">
        <img
          src="https://tbmcerts.s3.us-west-1.amazonaws.com/negocio.JPG"
          alt="Imagen 1"
          className="custom-height mw-100 offset-top"
        />
        <h5 className="h1 px-4 offset-top font-header text-white">
          EMPRENDE TU PROPIO NEGOCIO
        </h5>
      </div>
      <div className="col-4 position-relative mt-3">
        <img
          src="https://tbmcerts.s3.us-west-1.amazonaws.com/conocimientos.JPG"
          alt="Imagen 2"
          className="mw-100 custom-height"
        />
        <h5 className="h1 px-4 offset-top font-header text-white">
          AMPLIA TUS CONOCIMIENTOS
        </h5>
      </div>
      <div className="col-4 mb-3">
        <img
          src="https://tbmcerts.s3.us-west-1.amazonaws.com/tecnica.JPG"
          alt="Imagen 3"
          className="mw-100 custom-height offset-top"
        />
        <h5 className="h1 px-4 offset-top font-header text-white">
          MEJORA TU TÉCNICA
        </h5>
      </div>
    </div>
  );
};

export default DesktopFeatures;
