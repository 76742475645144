import React, { useContext } from "react";
import { SectionsContext } from "../../context/SectionsContext";
import { ModalContext } from "../../context/ModalContext";
import { AttempsContext } from "../../context/AttemptsContext";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";

const TestSection = () => {
  const { user } = useContext(AuthContext);
  const { test } = useContext(SectionsContext);
  const { createAttempt } = useContext(AttempsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const getIncompleteAttemp = () => {
    const { attempts } = test;
    if (attempts.length > 0) {
      let nextAttempt = attempts.find((attempt) => attempt.finishedAt === null);
      return nextAttempt;
    }
  };

  const handleConfirmAttempt = () => {
    modalComponent(
      "Presentar Quiz",
      <div>
        <p>Confirma que quieres comenzar este quiz</p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn text-muted w-100">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => createAttempt(test.test_id)}
              className="btn btn-primary w-100"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderLastAttempt = () => {
    if (test.attempts.length > 0) {
      const lastAttempt = test.attempts[test.attempts.length - 1];
      return (
        <Link
          to={`/mytbm/attempt/${lastAttempt.attempt_id}`}
          className="btn btn-light me-3"
        >
          VER RESULTADO ANTERIOR
        </Link>
      );
    }
  };

  const renderButton = () => {
    const nextAttempt = getIncompleteAttemp();
    if (nextAttempt) {
      return (
        <Link
          to={`/mytbm/attempt/${nextAttempt.attempt_id}`}
          className={`btn text-white bg-${
            nextAttempt.finishedAt === null ? "blue" : "accent"
          }`}
        >
          {nextAttempt.finishedAt === null ? "Continuar Quiz" : "Ver Resultado"}
        </Link>
      );
    }
    return (
      <div>
        {renderLastAttempt()}
        <button onClick={handleConfirmAttempt} className="btn btn-primary">
          PRESENTAR QUIZ
        </button>
      </div>
    );
  };

  const renderLessonTest = () => {
    if (test && test !== null) {
      const { attempts } = test;
      const sortedAttempts = attempts.sort((a, b) =>
        parseFloat(a.score) > parseFloat(b.score) ? -1 : 1
      );
      const highestAttempt = sortedAttempts[0];
      if (highestAttempt) {
        if (parseFloat(highestAttempt.score) > parseFloat(test.min_score)) {
          return (
            <div className="card text-center p-4 shadow">
              <div className="h2 italic text-uppercase bold">
                ¡Felicidades {user.name}!
              </div>
              <div className="my-3">
                Has completado todos los videos y quizes de este módulo
              </div>
              <div>
                <Link
                  to={`/mytbm/attempt/${highestAttempt.attempt_id}`}
                  className="btn btn-light text-dark"
                >
                  Ver Resultado Anterior
                </Link>
              </div>
            </div>
          );
        }
      }
      return (
        <div className="card p-4 shadow">
          <div className="h4 bold">{test.name}</div>
          <div className="small mt-3">{test.description}</div>
          <div className="small mt-3 text-accent">
            {test.questions.length} Preguntas de opción multiple
          </div>
          <div className="text-end mt-3">{renderButton()}</div>
        </div>
      );
    }
  };

  return <div className="col-12 col-md-8">{renderLessonTest()}</div>;
};

export default TestSection;
