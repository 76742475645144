import React from "react";
import { BASE_URL } from "../../utils";

const SingleLessonMedia = ({ multimedia }) => {
  const renderSingleMultimedia = () => {
    if (multimedia.src && multimedia.src !== null && multimedia.src !== "") {
      const video_source = multimedia.src;
      if (multimedia.type === "vimeo") {
        return (
          <iframe
            className="video-iframe"
            src={`https://player.vimeo.com/video/${video_source}`}
            allowFullScreen
          ></iframe>
        );
      }
      if (video_source.includes("youtube")) {
        const source =
          "https://www.youtube.com/embed/" + video_source.split("v=")[1];
        return (
          <div className="my-3">
            <iframe
              src={source}
              title="YouTube video player"
              className="mw-100 w-100 shadow"
              height="500px"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        );
      }
      if (video_source.includes("drive")) {
        return (
          <div className="p-3">
            <iframe
              src={video_source}
              className="mw-100 w-100"
              height="auto"
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        );
      }
      return (
        <video className="mw-100 w-100" controls>
          <source src={video_source} type="video/mp4" />
        </video>
      );
    }
    if (String(multimedia.tipo).includes("mp4")) {
      return (
        <div className="p-3">
          <video className="mw-100 w-100 mb-3" controls>
            <source
              src={`${BASE_URL}/files/${multimedia.file_id}`}
              type="video/mp4"
            />
          </video>
        </div>
      );
    } else if (["jpg", "jpeg", "png", "gif"].includes(multimedia.tipo)) {
      return <img src={`${BASE_URL}/files/${multimedia.file_id}`} />;
    }
  };
  return (
    <div
      className="container-fluid px-0"
      style={{ height: "100%" }}
      data-aos="fade-in"
    >
      {renderSingleMultimedia()}
    </div>
  );
};

export default SingleLessonMedia;
