import React from "react";

const QuizGrading = () => {
  return (
    <div className="col-12 ps-5 px-5 mt-5 mb-5 d-flex flex-column justify-content-end align-items-center">
      <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12">
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12">
          <i className="fa fa-clock fa-3x text-dark"></i>
        </div>
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12 mt-3 mb-3">
          <span className="bold text-center text-accent h1">
            Tu examen será calificado
          </span>
        </div>
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12 mb-5 mt-3 ps-5 px-5">
          <span className="text-center  bold">
            Recibirás un correo electrónico cuando los resultados estén listos.
          </span>
        </div>
        <div className="d-flex h-100 flex-column justify-content-center col-lg-5 col-12">
          <button
            onClick={() => window.history.back()}
            className="btn shadow bg-dark btn-quiz"
          >
            REGRESAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizGrading;
