import React from "react";
import CoursesLanding from "../components/landing/CoursesLanding";
import Navbar from "../components/global/Navbar";
import Footer from "../components/global/Footer";
import "../css/SingleCurso.css";
import CoursesHeader from "../components/courses/CoursesHeader";

const Courses = () => {
  return (
    <div>
      <Navbar />
      <CoursesHeader />
      <div className="h-100 my-5">
        <CoursesLanding hideTitle />
      </div>
      <Footer />
    </div>
  );
};

export default Courses;
