import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";

const InvoiceCard = ({ purchase }) => {
  return (
    <div className="card p-3 no-scale my-2">
      <div className="row">
        <div className="col-5 bold show-mobile">Folio:</div>
        <div className="col-7 col-md-1">#{purchase.purchase_id}</div>
        <div className="col-5 bold show-mobile">Paquete:</div>
        <div className="col-7 col-md-2">{purchase.course.title}</div>
        <div className="col-5 bold show-mobile">Total:</div>
        <div className="col-7 col-md-2">
          {"$"}
          {formatMonto(purchase.total_payment)}
          {" MXN"}
        </div>
        <div className="col-5 bold show-mobile">Fecha de Compra:</div>
        <div className="col-7 col-md-2">
          {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
        </div>
        <div className="col-5 bold show-mobile">Siguiente Cargo:</div>
        <div className="col-7 col-md-2">
          {moment(purchase.createdAt)
            .add(1, purchase.course.subscription_period)
            .format("DD MMM YYYY HH:mm")}
        </div>
        <div className="col-5 bold show-mobile">Estado:</div>
        <div className="col-7 col-md-2">
          {purchase.status === "wc-processing" ||
          purchase.status === "completed" ? (
            <span className="badge badge-pill bg-success">Completado</span>
          ) : purchase.status === "wc-pending" ? (
            <span className="badge badge-pill bg-warning">Pendiente</span>
          ) : purchase.status === "wc-refunded" ? (
            <span className="badge badge-pill bg-dark">Reembolsado</span>
          ) : (
            <span className="badge badge-pill bg-danger">Fallido</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
