import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import {
  formatMonto,
  renderFirstInvoicePeriod,
  renderSubscriptionPeriod,
} from "../../utils";
import moment from "moment";
import { parse } from "query-string";
import { useLocation } from "@reach/router";
import { getPrice } from "../../utils/products";

const CheckoutItem = ({ product_id, payment_plan }) => {
  const location = useLocation();
  const { product, descuento, getSingleProduct } = useContext(CheckoutContext);

  useEffect(() => {
    getSingleProduct(product_id);
  }, [product_id]);

  const renderDiscountPeriod = (descuento, product) => {
    if (product.is_subscription) {
      if (
        !descuento.first_invoice_only ||
        descuento.first_invoice_only === null
      ) {
        return renderSubscriptionPeriod(product);
      }
      return `${renderFirstInvoicePeriod(product)}`;
    }
  };

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let product_price = getPrice(product);
      let total = product_price;
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = total * porcentaje;
      } else {
        total = total - descuento.amount;
      }
      total = parseFloat(total).toFixed(2);
      return (
        <div className="container-fluid px-0">
          <h5>
            Total con Descuento: {"$"}
            {total} MXN {renderDiscountPeriod(descuento, product)}
          </h5>
          {descuento.first_invoice_only && (
            <p>
              Después ${formatMonto(product_price)}{" "}
              {renderSubscriptionPeriod(product)}
            </p>
          )}
        </div>
      );
    }
  };

  const renderProduct = () => {
    if (product && product !== null) {
      let price =
        product.sale_price !== null && product.sale_price !== ""
          ? product.sale_price
          : product.price;
      const searchParams = parse(location.search);
      if (searchParams.payment_plan) {
        if (
          parseInt(searchParams.payment_plan) === 3 &&
          product.separate_price !== null
        ) {
          price = product.separate_price;
        }
      }
      return (
        <div>
          <h3>{product.name}</h3>
          <p>
            <span
              className={`badge badge-pill text-uppercase text-dark me-2 ${
                product.mode === "on-demand" ? "bg-primary" : "bg-accent"
              }`}
            >
              {product.mode === "live" ? "En Vivo" : "On Demand"}
            </span>
            {product.location === "remote" ? (
              <span>
                <i className="fa fa-laptop me-2"></i> Remoto
              </span>
            ) : product.location === "studio" ? (
              <span>
                <i className="fa fa-map-pin me-2"></i> Presencial (MTY)
              </span>
            ) : (
              <></>
            )}
          </p>
          {renderDates()}
          <h5>
            Total: {"$"}
            {formatMonto(price)}
            {" MXN"} {renderSubscriptionPeriod(product)}
          </h5>
          {renderResultadoDescuento()}
        </div>
      );
    }
  };

  const renderDates = () => {
    if (product.start_date !== null && product.end_date !== null) {
      return (
        <div className="row mb-3 px-1">
          <p className="ps-2">
            <i className="fa fa-calendar me-2"></i>{" "}
            {moment(product.start_date).utc().format("DD MMM YYYY")} -{" "}
            {moment(product.end_date).utc().format("DD MMM YYYY")}
          </p>
          <p className="mb-0 bold ps-2">Tu Acceso On Demand comienza en</p>
          <p className="mb-0 bold ps-2">
            {moment(product.start_date).utc().format("DD MMM YYYY")}
          </p>
        </div>
      );
    }
  };

  const renderPaymentPlan = () => {
    if (payment_plan && payment_plan !== null) {
      return (
        <div>
          <h4>Plan de Pagos</h4>
          <p className="mb-0">{payment_plan.name}</p>
          <p className="mb-0">{payment_plan.description}</p>
        </div>
      );
    }
    return (
      <div>
        <h4>Plan de Pagos</h4>
        <p className="mb-0">Pago de Contado</p>
      </div>
    );
  };

  return (
    <div>
      <h3>Estás Pagando</h3>
      <div className="mb-3 card shadow p-3">{renderProduct()}</div>
      <div className="mb-3 card shadow p-3">{renderPaymentPlan()}</div>
    </div>
  );
};

export default CheckoutItem;
