import React, { useContext, useState } from "react";
import moment from "moment";
import { Link } from "@reach/router";
import parse from "html-react-parser";
import { S3_ENDPOINT, formatMonto } from "../../utils";
import { ProductsContext } from "../../context/ProductsContext";

const FlipCard = ({
  src,
  mode,
  link,
  title,
  description,
  short_description,
}) => {
  const { products } = useContext(ProductsContext);
  const [flipped, setFlipped] = useState(false);

  const getPrice = () => {
    let minPrice;
    if (Array.isArray(products)) {
      const productsRender = [...products].filter(
        (product) => product.mode === mode
      );
      productsRender.forEach((product) => {
        if (!minPrice) {
          minPrice = product.sale_price && product.sale_price !== null ? product.sale_price : product.price;
        } else if (product.price < minPrice) {
          minPrice = product.price;
        } else if((product.sale_price && product.sale_price !== null) && product.sale_price < minPrice) {
          minPrice = product.sale_price;
        }
      });
    }
    return minPrice;
  };

  const renderPriceInner = () => {
    const price = getPrice();
    if (price) {
      return (
        <div className="my-3">
          <span className="m-auto d-block small">desde</span>
          <h4 className="futura h3 bold">${formatMonto(price)} MXN</h4>
        </div>
      );
    }
  };

  const renderPrice = () => {
    const price = getPrice();
    if (price) {
      return (
        <div
          className="text-white"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            backgroundColor: "#27272b",
            padding: "20px 0",
          }}
        >
          <span className="h3" style={{ fontWeight: "700" }}>
            ${formatMonto(price)} MXN
          </span>
          {mode === "live" && (
            <span className="d-block small m-auto">
              planes de pago disponibles
            </span>
          )}
        </div>
      );
    }
  };

  const renderDates = () => {
    if (Array.isArray(products)) {
      let productsRender = [...products].filter(
        (product) => product.mode === mode
      );
      let minDate;
      let endDate;
      productsRender.forEach((product) => {
        if (product.start_date !== null) {
          if (!minDate) {
            minDate = product.start_date;
            endDate = product.end_date;
          } else if (moment(product.start_date).isBefore(minDate)) {
            minDate = product.start_date;
            endDate = product.end_date;
          }
        }
      });
      if (minDate && endDate) {
        return (
          <div>
            <p>Próximas Fechas</p>
            <p className="mb-0 bold text-primary ps-2">
              <i className="fa fa-calendar me-2"></i>{" "}
              {moment(minDate).utc().format("DD MMM YYYY")} -{" "}
              {moment(endDate).utc().format("DD MMM YYYY")}
            </p>
          </div>
        );
      }
    }
  };

  return (
    <div className={`card-container ${flipped ? "translated" : ""}`}>
      <div className="card-inner">
        <div className="card-front">
          <div
            className="text-center text-white text-shadow border home-course relative"
            style={{ backgroundImage: `url(${S3_ENDPOINT}/${src})` }}
          >
            <div style={{ zIndex: 5 }} className="overlay"></div>
            <div className="row pb-5 px-0 align-items-center">
              <div style={{ zIndex: 10 }} className="container-fluid py-5 px-0">
                <h2 className="text-shadow position-relative text-uppercase h1">
                  {title}
                </h2>
                <div className="w-75 m-auto">
                  <p className="francois fw-normal text-uppercase">
                    {parse(short_description)}
                  </p>
                </div>
                <button
                  onClick={() => setFlipped(true)}
                  className="btn btn-round btn-light show-mobile shadow my-4"
                >
                  <i className="fa fa-chevron-right"></i>
                </button>
              </div>
              {renderPrice()}
            </div>
          </div>
        </div>
        <div className="card-back px-4 py-5 py-md-4 py-xl-5 bg-white shadow">
          <div className="container-fluid px-0">
            <h3 className="h1 francois text-center">{title}</h3>
            <p className="francois fw-normal text-uppercase">
              {parse(short_description)}
            </p>
            <p className="small text-muted mt-3 mb-3 px-4 px-lg-0">
              {parse(description)}
            </p>
            {renderDates()}
            {renderPriceInner()}
            <Link to={link} className="btn btn-primary w-100 my-3">
              VER CERTIFICACIONES
            </Link>
            <button
              className="btn show-mobile py-0"
              onClick={() => setFlipped(false)}
            >
              Regresar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
