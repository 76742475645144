import {
  SET_NOTE,
  CREATE_NOTE,
  RECEIVED_NOTES,
  SET_PROPERTY_NOTE,
} from "../types/Notes";

const schema = {
  note_id: "",
  content: "",
};

const NotesReducer = (state, { type, payload }) => {
  switch (type) {
    case RECEIVED_NOTES:
      return { ...state, notes: payload };
    case SET_NOTE:
      return { ...state, notes: payload };
    case CREATE_NOTE:
      return { ...state, note: schema };
    case SET_PROPERTY_NOTE:
      const { key, value } = payload;
      const note = { ...state.note };
      note[key] = value;
      return {
        ...state,
        note,
      };
    default:
      return state;
  }
};

export default NotesReducer;
