import React from "react";

const ConfirmDiploma = ({ handleCancel, handleDiploma }) => {
  return (
    <div>
      <p>
        ¿Estás segura que deseas solicitar un{" "}
        <span className="bold">Diploma de Participación</span>?
      </p>
      <p>
        Esto significa que <b>NO</b> estarás certificada por TBM Certifications.
      </p>
      <p>
        Esta acción <b>NO</b> puede deshacerse y <b>NO</b> puedes cambiar de
        opinión después.
      </p>
      <div className="row">
        <div className="col-12 col-md-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-12 col-md-6">
          <button onClick={handleDiploma} className="btn btn-primary w-100">
            Solicitar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDiploma;
