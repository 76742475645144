import React, { useState, useContext, useEffect } from "react";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";

const LoginForm = ({ prevEmail, setLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { spinner, signIn } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  useEffect(() => {
    if (prevEmail && prevEmail !== null) {
      setEmail(prevEmail);
    }
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div id="login-card" className="card no-scale text-left shadow p-4">
      <form onSubmit={handleSubmit}>
        <label>Correo Electrónico</label>
        <input
          type="email"
          className="form-control mb-3"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>
          Contraseña{" "}
          <button
            type="button"
            className="btn btn-link text-muted text-left text-small py-0 mb-1"
            onClick={(e) => {
              e.preventDefault();
              togglePassword();
            }}
          >
            <span className="text-montserrat text-small text-auto">
              {showPassword ? "Ocultar" : "Mostrar"}
            </span>
          </button>
        </label>
        <input
          type={showPassword ? "text" : "password"}
          className="form-control mb-3"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit" className="btn btn-primary" value="Entrar">
          {spinner ? <div className="spinner-border"></div> : "Entrar"}
        </button>
      </form>
      <div className="container-fluid px-0 mt-4">
        ¿Aun no tienes cuenta?{" "}
        {typeof setLogin === "function" ? (
          <button
            onClick={setLogin}
            className="btn text-accent px-0 py-0 d-inline-block"
          >
            Crea tu cuenta
          </button>
        ) : (
          <Link to="/registro" className="text-accent">
            Crea tu cuenta
          </Link>
        )}
      </div>
      <div className="container-fluid px-0 mt-4">
        <Link to="/recuperar" className="text-muted">
          ¿Olvidaste tu contraseña?
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
