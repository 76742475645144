import React, { useState } from "react";
import parse from "html-react-parser";
import CustomIcon from "../global/CustomIcon";

const AccordionItem = ({
  id,
  title,
  icon,
  content,
  parentId,
  collapsed,
  setCollapsed,
}) => {
  const headerId = `head-${id}`;

  const renderContent = () => {
    if (content && content !== null && content !== "") {
      return parse(content);
    }
  };

  return (
    <div className="accordion-item text-start bg-white border shadow-sm">
      <h2 className="accordion-header align-items-center" id={headerId}>
        <button
          className={`accordion-button text-uppercase fw-normal text-start ${
            collapsed !== id ? "collapsed" : ""
          }`}
          type="button"
          onClick={() => {
            if (collapsed === id) return setCollapsed(null);
            setCollapsed(id);
          }}
        >
          {icon && icon !== null && icon !== "" && (
            <div className="d-inline-block me-3">
              <CustomIcon icon={icon} />
            </div>
          )}
          {title}
        </button>
      </h2>
      <div
        id={id}
        className={`accordion-collapse ${collapsed !== id ? "collapse" : ""}`}
        aria-labelledby={headerId}
        data-bs-parent={parentId}
      >
        <div className="accordion-body large text-dark text-left">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ id, icon, items }) => {
  const [collapsed, setCollapsed] = useState(null);
  const renderItems = () => {
    return items.map(({ title, htmlContent }, index) => (
      <AccordionItem
        key={index}
        icon={icon}
        title={title}
        parentId={id}
        content={htmlContent}
        collapsed={collapsed}
        id={`faq-${index + 1}`}
        setCollapsed={setCollapsed}
      />
    ));
  };

  return (
    <div className="accordion accordion-flush" id={id}>
      {renderItems()}
    </div>
  );
};

export default Accordion;
